import { useState } from "react";

import PropTypes from "prop-types";

const Image = ({ src, alt, ...rest }) => {
  const [isBroken, setIsBroken] = useState(false);

  const handleError = () => {
    setIsBroken(true);
  };

  if (isBroken) {
    return <img src="/images/placeholder-image.png" alt="" />;
  }

  return <img {...rest} src={src} alt={alt} onError={handleError} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  ...{ other: PropTypes.any },
};

export default Image;
