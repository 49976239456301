import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Form, Select, Button, Alert } from "antd";
import PropTypes from "prop-types";

import {
  selectCollections,
  selectFeedCollections,
} from "../../redux/common/commonSlice";
import validation from "../../utilities/validation";
import { LoadingSpinner } from "../common";

export default function CollectionManagementForm({
  forForm,
  onHide,
  isSpin,
  onFinish,
  onFinishFailed,
  initialValues,
  submitButtonText,
  errorMsg,
}) {
  const { t } = useTranslation();
  const collections = useSelector(
    forForm === "video" ? selectFeedCollections : selectCollections
  );

  return (
    <>
      {errorMsg && <Alert message={errorMsg} className="mb-4" type="error" />}
      <Form
        name="collectionAddEdit"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        <div className="form-group">
          <label>
            {forForm === "video"
              ? t("collectionVideo.title")
              : t("collection.title")}{" "}
          </label>
          <Form.Item
            name="collectionIds"
            rules={validation.collection.collectionId}
          >
            <Select
              className="form-control"
              mode="multiple"
              placeholder={
                forForm === "video"
                  ? t("collectionVideo.title")
                  : t("collection.title")
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {collections.map((data) => {
                return (
                  <Select.Option
                    key={`${JSON.stringify(data)}`}
                    className="text-capitalize"
                    disabled={data.status === "inactive"}
                    value={data.id}
                  >
                    {data.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div className="form-group btn-row text-center mb-0">
          <Form.Item>
            <Button
              disabled={isSpin}
              htmlType="submit"
              className="btn btn-primary width-120 ripple-effect text-uppercase"
            >
              {isSpin ? <LoadingSpinner /> : submitButtonText}
            </Button>
            <Button
              htmlType="button"
              onClick={onHide}
              className="btn btn-outline-dark width-120 ripple-effect text-uppercase"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

CollectionManagementForm.propTypes = {
  onHide: PropTypes.func.isRequired,
  isSpin: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  errorMsg: PropTypes.string.isRequired,
  forForm: PropTypes.string,
};
