import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import RemoteDataTable from "../../components/dataTable";
import DeleteConfirmation from "../../components/modals/deleteConfirmation";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  getPageSizeFromURL,
  addPageSizeInURL,
  dateFormatter,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import MetaTags from "../../utilities/metaTags";
import { getSortField } from "../../utilities/table";

class Notifications extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFirstTimeFetching: true,
      isLoading: true,
      showUserListId: "",
      showUserListModal: false,
      deleteFAQModal: false,
      deleteFAQId: "",
      rowData: {},
      data: [],
      editData: {},
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      defaultSorted: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      columns: [
        {
          dataField: "id",
          text: props.t("common.id"),
          sort: true,
          hidden: true,
        },
        {
          dataField: "message",
          text: props.t("notifications.name"),
          headerAlign: "left",
          align: "left",
          sort: true,
          style: {
            textTransform: "capitalize",
          },
        },
        {
          dataField: "createdAt",
          text: "Created At",
          headerAlign: "right",
          align: "right",
          sort: true,
          formatter: dateFormatter,
        },
        {
          dataField: "isDummyField",
          text: props.t("common.action"),
          headerAlign: "right",
          align: "right",
          formatter: this.actionFormatter,
        },
      ],
    };
  }

  actionFormatter = (cell, row) => {
    return (
      <ul className="list-inline right-cnt mb-0">
        <li className="list-inline-item">
          <Link
            to="/"
            onClick={(e) => {
              e.preventDefault();
              this.deleteFAQ(row.id);
            }}
          >
            <i className="icon-bin fontw-bd"></i>
          </Link>
        </li>
      </ul>
    );
  };

  deleteFAQ = async (id) => {
    this.showHideDeleteFAQ(id);
  };

  showHideDeleteFAQ = (id = "") => {
    this.setState(
      {
        deleteFAQId: id,
      },
      () => {
        this.handleToggle("deleteFAQModal");
      }
    );
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.notificationList(queryParams);
        }
      );
    }
  };

  notificationList = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
    };
    try {
      const payload = {
        ...ApiEndPoints.getNotification,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        sortField = getSortField(sortField);
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };

        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history, {
            tab: "user",
          });
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.notificationList(queryParams);
        }
      }
    } else {
      this.notificationList(queryParams);
    }
  };

  render() {
    const {
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      deleteFAQId,
      deleteFAQModal,
    } = this.state;
    const { t } = this.props;
    const bredcrumbs = [
      {
        name: t("dashboard.title"),
        path: "/",
      },
      {
        name: t("notifications.title"),
        path: "/reported-content",
      },
    ];
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("notifications.title")}`}
          description={`${t("notifications.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            {/* <!-- page title section start --> */}
            <div className="page-title-row page-title-btn d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title text-capitalize mb-lg-0">
                  {t("notifications.title")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div className="btnBox d-flex w-100"></div>
              </div>
            </div>
            {/* <!-- page title section end --> */}
            <div className="custom-tabs">
              <div className="detail-wrap mt-2 mt-md-3">
                <div className="custom-tabs detail-ta bs">
                  <div className="tab-content mt-0">
                    <div className="tab-pane fade show active">
                      <div className="common-table">
                        <div className="table-responsive">
                          <RemoteDataTable
                            data={data}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange}
                            isFilter={false}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            loading={isLoading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <DeleteConfirmation
          show={deleteFAQModal}
          id={Number(deleteFAQId)}
          type="notification"
          onSubmitSuccess={this.notificationList}
          onHide={() => this.showHideDeleteFAQ()}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // languagesList: state.common.languages,
    // videoCategories: state.common.videoCategories,
    userData: state.auth.userData || "",
  };
};
Notifications.propTypes = {
  // bredcrumbs: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(Notifications))
);
