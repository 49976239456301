import React, { PureComponent } from "react";
import { confirm } from "react-confirm-box";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Form, Input, Button, Select, DatePicker, Badge } from "antd";
import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import { GoToVideoDetailPage } from "../../components/common";
import RemoteDataTable from "../../components/dataTable";
import DeactivateReason from "../../components/modals/deactivateReason";
import ShowMoreText from "../../components/modals/showMoreText";
import config from "../../config";
import { updateFilters } from "../../redux/manageVideo/manageVideoSlice";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  videoFormatter,
  statusFormatter,
  dateFormatter,
  dateFormatDMY,
  serialNumberFormatter,
  filterDataObj,
  readMoreText,
  nameWithImageFormatter,
  ListCategories,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

const options = {
  render: (message, onConfirm, onCancel) => {
    return (
      <div className="confirm-box__content">
        <span> {message} </span>
        <div className="confirm-box__actions">
          <button
            className="ant-btn btn btn-primary ripple-effect"
            onClick={onConfirm}
          >
            {" "}
            Yes{" "}
          </button>
          <button
            className="ant-btn btn btn-outline-dark ripple-effect"
            onClick={onCancel}
          >
            {" "}
            No{" "}
          </button>
        </div>
      </div>
    );
  },
};

class ManageVideo extends PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    const filters = props.filters ? filterDataObj(props.filters) : undefined;
    const filtered =
      props.history.action === "POP" && filters && filters?.filterCount;

    this.state = {
      openFilter: false,
      isLoading: true,
      data: [],
      filterData: filters?.filterData ?? {},
      filterCount: filters?.filterCount ?? 0,
      filtered,
      dataOfMessageReply: {},
      openMessageReplyModal: false,
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      videoCategory: [],
      categoryOffset: 0,
      totalCategorySize: 10,
      categoryLimit: 10,
      videoId: 0,
      status: "",
      showConfirm: false,
      showReason: false,
      isReasonLoading: false,
      defaultSorted: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      columns: [
        {
          dataField: "id",
          text: this.props.t("common.id"),
          sort: true,
          hidden: true,
        },
        {
          dataField: "isDummySno",
          text: this.props.t("common.sNo"),
          // sort: true,
          formatter: (cell, row, rowIndex) =>
            serialNumberFormatter(
              rowIndex,
              this.state.page,
              this.state.sizePerPage
            ),
        },
        {
          dataField: "mediaFileUrl",
          text: this.props.t("common.video"),
          headerAlign: "left",
          align: "left",
          formatter: (cell, row) => videoFormatter(cell, row),
        },
        {
          dataField: "title",
          text: this.props.t("video.description"),
          headerAlign: "left",
          align: "left",
          // sort: true,
          style: {
            textTransform: "capitalize",
          },
          // formatter: (cell, row) => goToVideoDetail(cell, row, row.id)
          formatter: (cell, row) =>
            readMoreText(cell, row, "title", this.showHideMoreText, props.t),
        },
        {
          dataField: "restrictedAccess",
          text: this.props.t("video.creator"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: (cell, row) => nameWithImageFormatter(cell, row.User),
        },
        {
          dataField: "category",
          text: this.props.t("video.category"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: (cell, row) => ListCategories(cell, row),
        },
        {
          dataField: "status",
          text: this.props.t("common.status"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: (cell, row) =>
            statusFormatter(cell, row, this.onchangeStatus),
        },
        {
          dataField: "createdAt",
          text: this.props.t("common.createdAt"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: dateFormatter,
        },
        {
          dataField: "updatedAt",
          text: this.props.t("common.updatedAt"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: dateFormatter,
        },
        {
          dataField: "isDummyField",
          text: this.props.t("common.action"),
          headerAlign: "left",
          align: "left",
          formatter: this.actionFormatter,
        },
      ],
      bredcrumbs: [],
    };
  }

  componentDidMount() {
    this.updateBredcrums();
  }

  componentWillUnmount() {
    if (!this.props.history.location.pathname.match(/video-detail/)) {
      if (this.props.updateFilters !== null) {
        this.props.updateFilters(undefined);
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.userid !== this.props.match.params.userid) {
      this.setState(
        {
          isLoading: true,
          page: 1,
          sizePerPage: 10,
        },
        () => {
          this.updateBredcrums();
          this.fetchVideoUsers(
            {
              offset: (this.state.page - 1) * this.state.sizePerPage,
              limit: this.state.sizePerPage,
            },
            this.props.match.params.userid
          );
        }
      );
    }
  }

  showHideMoreText = (dataOfMessageReply = {}) => {
    this.setState(
      {
        dataOfMessageReply,
      },
      () => {
        this.handleToggle("openMessageReplyModal");
      }
    );
  };
  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };
  updateBredcrums = () => {
    let bredcrumbs = [
      {
        name: this.props.t("dashboard.title"),
        path: "/",
      },
      {
        name: this.props.t("common.video"),
        path: "/manage-video",
      },
    ];

    if (
      this.props.history.location.state &&
      Object.keys(this.props.history.location.state).length > 0 &&
      "bredcrumbs" in this.props.history.location.state
    ) {
      bredcrumbs = this.props.history.location.state.bredcrumbs;
    }

    this.setState({
      bredcrumbs,
    });
  };

  fetchVideoUsers = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    },
    id = this.props.match.params.userid
  ) => {
    queryParams = {
      ...queryParams,
      ...this.state.filterData,
    };
    try {
      const payload = {
        ...ApiEndPoints.getFeed,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  handleFilter = () => {
    this.setState(
      (state) => {
        return {
          openFilter: !state.openFilter,
        };
      },
      () => {
        !this.state.openFilter || this.fetchVideoCategory();
      }
    );
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (_type, { page, sizePerPage, sortField, sortOrder }) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
      },
      () => {
        if (sortField === "isDummySno") {
          sortField = "id";
        }
        if (sortField === "restrictedAccess") {
          sortField = "creator";
        }
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };
        this.fetchVideoUsers(queryParams);
      }
    );
  };

  /**
   * Local formatters
   */
  actionFormatter = (cell, row) => {
    return (
      <div className="dropdown">
        <a
          href="/"
          onClick={(e) => e.preventDefault()}
          className="dropdown-toggle"
          id={`dropdownMenuButton_${row.id}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="ni ni-more-h"></span>
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <GoToVideoDetailPage
            classProps={"dropdown-item"}
            data={"View"}
            id={row.id}
          />
        </div>
      </div>
    );
  };

  onchangeStatus = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }

      const result = await confirm(
        `Are you sure want to ${status} this video?`,
        options
      );
      if (result) {
        const payload = {
          ...ApiEndPoints.updateStatusFeed(row.id),
          bodyData: {
            status,
          },
        };
        const res = await APIrequest(payload);
        modalNotification({
          type: "success",
          message: "Success",
          description: res.message || textMessages.statusUpdate,
        });
        const dataTemp = this.state.data;
        const indexData = dataTemp.findIndex((d) => d.id === row.id);
        if (indexData > -1) {
          dataTemp[indexData].status = status;
        }
        this.setState({
          ...this.state,
          status: status,
          videoId: row.id,
          showConfirm: true,
        });
        resHandleChange(status);
      }

      resHandleChange(row.status);
    } catch (error) {
      resHandleChange(row.status);
      logger({ "error:": error });
    }
  };

  onFinish = async (values) => {
    const { filterData, filterCount } = filterDataObj(values);
    this.props.updateFilters(values);

    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData,
        filterCount,
        filtered: filterCount > 0,
      },
      () => {
        this.fetchVideoUsers();
        this.handleFilter();
      }
    );
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  onReset = () => {
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData: {},
        filterCount: 0,
      },
      () => {
        this.formRef.current.resetFields();
        this.fetchVideoUsers();
      }
    );
  };

  fetchVideoCategory = async (
    queryParams = {
      offset: this.state.categoryOffset,
      limit: this.state.categoryLimit,
    }
  ) => {
    try {
      const payload = {
        ...ApiEndPoints.getCategory,
        queryParams: {
          ...queryParams,
        },
      };
      if (this.state.totalCategorySize > this.state.videoCategory.length) {
        const res = await APIrequest(payload);
        let concateVideoCategory = this.state.videoCategory.length
          ? [...this.state.videoCategory, ...res.data.rows]
          : res.data.rows;
        this.setState({
          categoryOffset: queryParams.offset,
          videoCategory: concateVideoCategory,
          totalCategorySize: res.data.total,
        });
      }
    } catch (error) {
      logger({ "error:": error });
    }
  };

  loadMoreCategory = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let queryParams = {
        limit: this.state.categoryLimit,
        offset: this.state.categoryOffset + 10,
      };
      this.fetchVideoCategory(queryParams);
    }
  };
  hideReasonModal = () => {
    this.setState(
      {
        ...this.state,
        showReason: false,
        data: [],
        videoId: 0,
        status: "",
        showConfirm: false,
      },
      () => {
        this.fetchVideoUsers();
      }
    );
  };
  ReasonModalController = async (selectedReason) => {
    try {
      if (selectedReason) {
        this.setState({
          ...this.state,
          isReasonLoading: true,
        });
      }
      const status = this.state.status;
      const payload = {
        ...ApiEndPoints.updateStatusFeed(this.state.videoId),
        bodyData: {
          status,
          selectedReason,
        },
      };
      const res = await APIrequest(payload);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || textMessages.statusUpdate,
      });
      this.setState(
        {
          ...this.state,
          isReasonLoading: false,
          showReason: false,
          showConfirm: false,
        },
        () => {
          this.fetchVideoUsers();
        }
      );
    } catch (error) {
      logger(error);
    }
  };

  render() {
    const {
      openFilter,
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      bredcrumbs,
      filterData,
      filterCount,
      dataOfMessageReply,
      openMessageReplyModal,
      videoCategory,
    } = this.state;
    const { t } = this.props;

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("video.title")}`}
          description={`${t("video.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            <div className="page-title-row page-title-btn d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                  {t("video.title")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div
                  className={`filterForm ${
                    openFilter ? "filterForm--open" : ""
                  }`}
                >
                  <div className="filterForm__head">
                    <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleFilter();
                      }}
                      id="filterClose"
                    >
                      <i className="icon-cross"></i>
                    </a>
                  </div>
                  <div className="filterForm__body">
                    <Form
                      name="videoFilter"
                      className="form-inline"
                      ref={this.formRef}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      initialValues={filterData}
                      layout="vertical"
                    >
                      <div className="form-group">
                        <Form.Item name="name" label={t("common.creatorName")}>
                          <Input
                            className="form-control"
                            placeholder={t("videoList.filter.creatorName")}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <Form.Item
                          name="username"
                          label={t("common.creatorUserName")}
                        >
                          <Input
                            className="form-control"
                            placeholder={t("videoList.filter.creatorUserName")}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("video.description")}</label>
                        <Form.Item name="title">
                          <Input
                            className="form-control"
                            placeholder={t("video.description")}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <Form.Item
                          name="videoCatId"
                          label={t("video.category")}
                        >
                          <Select
                            className="form-control"
                            placeholder={t("video.category")}
                            onScroll={this.loadMoreCategory}
                          >
                            {videoCategory.map((categories) => {
                              return (
                                <>
                                  <Select.Option
                                    key={categories.id}
                                    value={categories.id}
                                  >
                                    {categories.name}
                                  </Select.Option>
                                </>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("common.status")}</label>
                        <Form.Item name="status">
                          <Select className="form-control" placeholder="Status">
                            <Select.Option value="">
                              {t("common.allStatus")}
                            </Select.Option>
                            <Select.Option value="active">
                              {t("common.active")}
                            </Select.Option>
                            <Select.Option value="inactive">
                              {t("common.inactive")}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("common.createdAt")}</label>
                        <Form.Item name="createdAt">
                          <DatePicker.RangePicker
                            placeholder={[
                              t("common.startDate"),
                              t("common.endDate"),
                            ]}
                            //getPopupContainer={node => node.parentNode} // https://github.com/ant-design/ant-design/issues/22987
                            format={dateFormatDMY}
                          />
                        </Form.Item>
                      </div>
                      <div className="btn_clumn d-flex justify-content-between">
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="btn btn-primary ripple-effect"
                          >
                            {t("common.search")}
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="button"
                            onClick={this.onReset}
                            className="btn btn-outline-dark ripple-effect"
                          >
                            {t("common.reset")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="btnBox">
                  <Badge count={filterCount}>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleFilter();
                      }}
                      id="filter"
                      className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-0"
                    >
                      <i className="icon-filter-line"></i>
                    </a>
                  </Badge>
                </div>
              </div>
            </div>
            <RemoteDataTable
              data={data}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
              isFilter={false}
              columns={columns}
              // selectRow={{}}
              defaultSorted={defaultSorted}
              loading={isLoading}
            />
          </div>
          <ShowMoreText
            title={t("video.description")}
            data={
              Object.keys(dataOfMessageReply).length
                ? dataOfMessageReply.data
                : ""
            }
            show={openMessageReplyModal}
            onHide={this.showHideMoreText}
          />
          <DeactivateReason
            show={this.state.showReason}
            onHide={this.hideReasonModal}
            controller={this.ReasonModalController}
            loading={this.state.isReasonLoading}
          />
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.manageVideo.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilters: (res) => dispatch(updateFilters(res)),
  };
};

ManageVideo.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ManageVideo))
);
