import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Alert } from "antd";
import PropTypes from "prop-types";

import ResetPasswordForm from "../../components/forms/resetPassword";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import { tagClassToggle } from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

class ResetPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSpin: false,
      isAlert: false,
      errorMsg: "",
    };
  }

  componentDidMount() {
    tagClassToggle("body", "auth");
  }

  componentWillUnmount() {
    tagClassToggle("body", "auth");
  }

  onFinish = async (values) => {
    try {
      const payload = {
        ...ApiEndPoints.accountResetPassword,
        bodyData: {
          token: this.props.match.params.token,
          newPassword: values.password,
          confirmPassword: values.confirm,
        },
      };
      this.setState({ isSpin: true, isAlert: false });
      const res = await APIrequest(payload);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || textMessages.passwordSuccess,
      });
      this.props.history.push({
        pathname: "/",
      });
    } catch (error) {
      this.setState({ isAlert: true, isSpin: false, errorMsg: error.message });
      logger({ "error:": error });
    }
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  render() {
    const { isSpin, isAlert, errorMsg } = this.state;
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | Reset Password`}
          description={`Reset Password page of ${config.NAME_TITLE}`}
        />
        <main className="mainContent pl-0 pr-0 pb-0">
          <section className="auth__section d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="auth-area mx-auto ">
                <div className="auth-area__head text-center">
                  <h1 className="font-bold">Reset Password</h1>
                </div>
                <div className="auth-area__form shadow">
                  {isAlert && (
                    <Alert
                      message={errorMsg || textMessages.enterPassword}
                      className="mb-4"
                      type="error"
                    />
                  )}
                  <ResetPasswordForm
                    isSpin={isSpin}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    submitButtonText={"RESET PASSWORD"}
                  />
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

ResetPassword.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(ResetPassword);
