import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import ClosedRequest from "../../components/closedRequest";
import PendingRequest from "../../components/pendingRequest";
import config from "../../config";
import { getPageSizeFromURL, updateQueryInURL } from "../../utilities/common";
import MetaTags from "../../utilities/metaTags";
class ContactSupport extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tabName: "pending",
    };
  }

  componentDidMount() {
    if (this.props.location && this.props.location.search) {
      const query = this.props.location.search;
      this.reFetchOnUrlBasis(query);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { tab },
      } = res;
      if (tab) {
        this.handleTabChange(tab);
      }
    }
  };

  handleTabChange = (value) => {
    this.setState({
      tabName: value,
    });
  };

  render() {
    const { tabName } = this.state;
    const { t } = this.props;

    const bredcrumbs = [
      {
        name: t("dashboard.title"),
        path: "/",
      },
      {
        name: t("support.title"),
        path: "/contact-support",
      },
    ];
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("support.title")}`}
          description={`${t("support.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            {/* <!-- page title section start --> */}
            <div className="page-title-row page-title-btn d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title text-capitalize mb-lg-0">
                  {t("support.title")}
                </h1>
              </div>
            </div>
            {/* <!-- page title section end --> */}
            <div className="custom-tabs">
              <ul
                className="nav nav-tabs nav-justified border-top-0"
                role="tablist"
              >
                <li className="nav-item w-auto">
                  <a
                    className={`nav-link ${
                      tabName === "pending" ? "active" : ""
                    }`}
                    id="pendingRequests-tab"
                    data-toggle="tab"
                    href="#pendingRequests"
                    role="tab"
                    aria-controls="pendingRequestsTab"
                    aria-selected="false"
                    onClick={(e) => {
                      e.preventDefault();
                      updateQueryInURL(this.props.history, { tab: "pending" });
                      setTimeout(() => {
                        this.handleTabChange("pending");
                      }, 1000 / 2);
                    }}
                  >
                    {t("support.pendingRequest")}
                  </a>
                </li>
                <li className="nav-item w-auto">
                  <a
                    className={`nav-link ${
                      tabName === "closed" ? "active" : ""
                    }`}
                    id="closedRequest-tab"
                    data-toggle="tab"
                    href="#closedRequest"
                    role="tab"
                    aria-controls="closedRequestTab"
                    aria-selected="false"
                    onClick={(e) => {
                      e.preventDefault();
                      updateQueryInURL(this.props.history, { tab: "closed" });
                      setTimeout(() => {
                        this.handleTabChange("closed");
                      }, 1000 / 2);
                    }}
                  >
                    {t("support.closedRequest")}
                  </a>
                </li>
              </ul>
              <div className="detail-wrap mt-2 mt-md-3">
                <div className="custom-tabs detail-ta bs">
                  <div className="tab-content mt-0">
                    <div className="tab-pane fade show active">
                      <div className="common-table">
                        <div className="table-responsive">
                          {tabName === "pending" && (
                            <PendingRequest bredcrumbs={bredcrumbs} />
                          )}
                          {tabName === "closed" && (
                            <ClosedRequest bredcrumbs={bredcrumbs} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

ContactSupport.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ContactSupport);
