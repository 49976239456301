import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import APIrequest from "../../../services";
import ApiEndPoints from "../../../utilities/apiEndPoints";
import logger from "../../../utilities/logger";
import textMessages from "../../../utilities/messages";
// import CollectionForm from '../../forms/collection'
import modalNotification from "../../../utilities/notifications";
import ReportedReasonsAddEditForm from "../../forms/reportedReasons/reportedReasonsAddEditForm";

export default function ReportedReasonAddEdit(props) {
  const { t } = useTranslation();
  const [isSpin, setIsSpin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isEditFormType, setIsEditFormType] = useState(
    Object.keys(props.data).length > 0
  );

  useEffect(() => {
    const isEditFormType = Object.keys(props.data).length > 0;
    setIsEditFormType(isEditFormType);
    setErrorMsg("");
    setIsSpin(false);
  }, [props.show, props.data]);

  const onFinish = async (values) => {
    setIsSpin(true);
    setErrorMsg("");
    let isVideoReportReason = props.isVideoReportReason ?? false;
    try {
      /**
       * To Handle Reason for both report reason and report video reason
       */
      let api = isVideoReportReason
        ? ApiEndPoints.addReportVideoReason
        : ApiEndPoints.addReportedReason;
      let payload = {
        ...api,
        bodyData: {
          message: values.message,
        },
      };

      if (isEditFormType) {
        /**
         * Handle update api for both report video reason and report reason
         */
        let api = isVideoReportReason
          ? ApiEndPoints.editReportVideoReason
          : ApiEndPoints.editReportedReason;
        payload = {
          ...payload,
          ...api(props.data.id),
        };
      }

      const res = await APIrequest(payload);
      setIsSpin(false);
      modalNotification({
        type: "success",
        message: "Success",
        description:
          res.message ||
          (isEditFormType
            ? textMessages.editReportedReason
            : textMessages.addReportedReason),
      });
      props.onSubmitSuccess();
      props.onHide();
    } catch (error) {
      setIsSpin(false);
      setErrorMsg(error.message);
      logger({ "error:": error });
    }
  };

  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>
            {isEditFormType
              ? t("reportedReasons.edit")
              : t("reportedReasons.add")}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReportedReasonsAddEditForm
          //   formFor={props.for}
          onHide={props.onHide}
          isSpin={isSpin}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={
            isEditFormType
              ? {
                  message: props.data.message,
                }
              : ""
          }
          submitButtonText={
            isEditFormType ? t("common.update") : t("common.save")
          }
          errorMsg={errorMsg}
        />
      </Modal.Body>
    </Modal>
  );
}

ReportedReasonAddEdit.propTypes = {
  for: PropTypes.string,
  data: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};
