import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

export default function CollectionList(props) {
  const { t } = useTranslation();
  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      id="collectionListModal"
      className="artistModal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.for === "video" ? (
            <h2>{t("collectionVideo.list")}</h2>
          ) : (
            <h2>{t("collection.list")}</h2>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="comment-list">
        <ul className="list-inline mCustomScrollbar">
          {props.data.map((data) => {
            let collectionKey = "Collection";
            if (props.for === "video") {
              collectionKey = "FeedCollection";
            }
            return (
              <li
                key={`${data.id}_${data[collectionKey].id}`}
                className="list-inline-items"
              >
                <div className="media align-items-center">
                  <div className="media__body">
                    <h5 className="mt-0 text-capitalize">
                      {data[collectionKey].title}
                    </h5>
                    <p className="text-capitalize">{props.title}</p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </Modal.Body>
    </Modal>
  );
}

CollectionList.propTypes = {
  data: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  for: PropTypes.string,
};
