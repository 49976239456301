import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Form, Input, Button, Badge } from "antd";
import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import RemoteDataTable from "../../components/dataTable";
import ShowMoreText from "../../components/modals/showMoreText";
import config from "../../config";
import { addArtistsAction } from "../../redux/common/commonSlice";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  dateFormatter,
  serialNumberFormatter,
  getPageSizeFromURL,
  addPageSizeInURL,
  filterDataObj,
  goToUserDetail,
  readMoreText,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";

class Feedback extends PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isFirstTimeFetching: true,
      openFilter: false,
      isLoading: true,
      openFeedbackModal: false,
      data: [],
      filterData: {},
      filterCount: 0,
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      dataOfFeedback: "",
      bredcrumbs: [
        {
          name: props.t("dashboard.title"),
          path: "/",
        },
        {
          name: props.t("feedback.title"),
          path: "/feedback",
        },
      ],
      defaultSorted: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      columns: [
        {
          dataField: "id",
          text: props.t("common.id"),
          sort: true,
          hidden: true,
        },
        {
          dataField: "isDummySno",
          text: props.t("common.sNo"),
          // sort: true,
          formatter: (cell, row, rowIndex) =>
            serialNumberFormatter(
              rowIndex,
              this.state.page,
              this.state.sizePerPage
            ),
        },
        {
          dataField: "feedback",
          text: props.t("feedback.title"),
          headerAlign: "left",
          align: "left",
          sort: true,
          classes: "w_460 text-wrap",
          style: {
            textTransform: "capitalize",
          },
          formatter: (cell, row) =>
            readMoreText(cell, row, "feedback", this.showHideMoreText, props.t),
        },
        {
          dataField: "User.name",
          text: props.t("feedback.by"),
          headerAlign: "left",
          align: "left",
          sort: true,
          style: {
            textTransform: "capitalize",
          },
          formatter: (cell, row) =>
            goToUserDetail(cell, row.User, row.User.id, {
              bredcrumbs: this.state.bredcrumbs,
            }),
        },
        {
          dataField: "createdAt",
          text: props.t("common.createdAt"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: dateFormatter,
        },
        {
          dataField: "updatedAt",
          text: props.t("common.updatedAt"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: dateFormatter,
        },
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchFeedback(queryParams);
        }
      );
    }
  };

  fetchFeedback = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
      ...this.state.filterData,
    };
    try {
      const payload = {
        ...ApiEndPoints.getFeedback,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  handleFilter = () => {
    this.setState((state) => {
      document.body.classList.toggle("overflow-hidden", !state.openFilter);
      return {
        openFilter: !state.openFilter,
      };
    });
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        if (sortField === "User.name") {
          sortField = "name";
        }

        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };

        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history);
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchFeedback(queryParams);
        }
      }
    } else {
      this.fetchFeedback(queryParams);
    }
  };

  onFinish = async (values) => {
    const { filterData, filterCount } = filterDataObj(values);
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData,
        filterCount,
      },
      () => {
        this.fetchFeedback();
        this.handleFilter();
      }
    );
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  onReset = () => {
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData: {},
        filterCount: 0,
      },
      () => {
        this.formRef.current.resetFields();
        this.fetchFeedback();
      }
    );
  };

  showHideMoreText = (dataOfFeedback = {}) => {
    this.setState(
      {
        dataOfFeedback,
      },
      () => {
        this.handleToggle("openFeedbackModal");
      }
    );
  };

  render() {
    const {
      openFilter,
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      filterCount,
      dataOfFeedback,
      openFeedbackModal,
      bredcrumbs,
    } = this.state;

    const { t } = this.props;

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("feedback.title")}`}
          description={`${t("feedback.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            <div className="page-title-row d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                  {t("feedback.title")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div
                  className={`filterForm ${
                    openFilter ? "filterForm--open" : ""
                  }`}
                >
                  <div className="filterForm__head">
                    <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleFilter();
                      }}
                      id="filterClose"
                    >
                      <i className="icon-cross"></i>
                    </a>
                  </div>
                  <div className="filterForm__body">
                    <Form
                      name="feedbackFilter"
                      className="form-inline"
                      ref={this.formRef}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      initialValues={{}}
                    >
                      <div className="form-group">
                        <label>{t("feedback.by")} </label>
                        <Form.Item name="name">
                          <Input
                            className="form-control"
                            placeholder={t("feedback.by")}
                          />
                        </Form.Item>
                      </div>
                      <div className="btn_clumn d-flex justify-content-between">
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="btn btn-primary ripple-effect"
                          >
                            {t("common.search")}
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="button"
                            onClick={this.onReset}
                            className="btn btn-outline-dark ripple-effect"
                          >
                            {t("common.reset")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="btnBox">
                  <Badge count={filterCount}>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleFilter();
                      }}
                      id="filter"
                      className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-0"
                    >
                      <i className="icon-filter-line"></i>
                    </a>
                  </Badge>
                </div>
              </div>
            </div>
            <RemoteDataTable
              data={data}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
              isFilter={false}
              columns={columns}
              // selectRow={{}}
              defaultSorted={defaultSorted}
              loading={isLoading}
              noDataMessage={textMessages.noDataFound}
            />
          </div>
        </main>
        {/* Message  */}
        <ShowMoreText
          title={t("feedback.title")}
          data={Object.keys(dataOfFeedback).length ? dataOfFeedback.data : ""}
          show={openFeedbackModal}
          onHide={this.showHideMoreText}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    artists: state.common.artists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addArtistsRedux: (res) => dispatch(addArtistsAction(res)),
  };
};

Feedback.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Feedback)
);
