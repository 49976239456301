/* eslint-disable import-helpers/order-imports */
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import { Form, Select, Input, Button, Badge } from "antd";
import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import RemoteDataTable from "../../components/dataTable";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  serialNumberFormatter,
  getPageSizeFromURL,
  addPageSizeInURL,
  filterDataObj,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import "./event.css";
import { GoToViewTodayEventDetail } from "../../components/common";
import { getInitialStateEvents } from "./initialStateEvents";

class PastEvent extends PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    const dataFieldColumns = [
      "id",
      "isDummySno",
      "title",
      "eventType",
      "User.username",
      "eventEndDateTime",
      "isDummyField",
    ];

    const defaultSorted = [
      {
        dataField: "eventEndDateTime",
        order: "desc",
      },
    ];

    const customSerialNumberFormatter = (_cell, _row, rowIndex) =>
      serialNumberFormatter(rowIndex, this.state.page, this.state.sizePerPage);

    const prevState = getInitialStateEvents({
      props,
      customSerialNumberFormatter,
      dataFieldColumns,
      defaultSorted,
    });

    this.state = {
      listType: "pastEvent",
      ...prevState,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchPastEvent(queryParams);
        }
      );
    }
  };

  fetchPastEvent = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
      ...this.state.filterData,
      listType: "pastEvent",
    };
    try {
      const payload = {
        ...ApiEndPoints.getEvent,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  handleFilter = () => {
    this.setState((state) => {
      document.body.classList.toggle("overflow-hidden", !state.openFilter);
      return {
        openFilter: !state.openFilter,
      };
    });
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        if (sortField === "isDummySno") {
          sortField = "id";
        }
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };

        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history);
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchPastEvent(queryParams);
        }
      }
    } else {
      this.fetchPastEvent(queryParams);
    }
  };

  onFinish = async (values) => {
    const { filterData, filterCount } = filterDataObj(values);
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData,
        filterCount,
      },
      () => {
        this.fetchPastEvent();
        this.handleFilter();
      }
    );
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  onReset = () => {
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData: {},
        filterCount: 0,
      },
      () => {
        this.formRef.current.resetFields();
        this.fetchPastEvent();
      }
    );
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  render() {
    const {
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      openFilter,
      filterCount,
    } = this.state;

    const { t } = this.props;
    const bredcrumbs = [
      {
        name: t("dashboard.title"),
        path: "/",
      },
      {
        name: t("event.pastEvent"),
        path: "/event/past-event",
      },
    ];
    const noDataMessage = textMessages.noEventfound;
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("event.pastEvent")}`}
          description={`${t("event.pastEvent")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            {/* <!-- page title section start --> */}
            <div className="page-title-row">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title text-capitalize mb-lg-0">
                  {t("event.pastEvent")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div
                  className={`filterForm ${
                    openFilter ? "filterForm--open" : ""
                  }`}
                >
                  <div className="filterForm__head">
                    <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleToggle("openFilter");
                      }}
                      id="filterClose"
                    >
                      <i className="icon-cross"></i>
                    </a>
                  </div>
                  <div className="filterForm__body">
                    <Form
                      name="categoryFilter"
                      className="form-inline"
                      ref={this.formRef}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      initialValues={{ status: "" }}
                    >
                      <div className="form-group">
                        <label>{t("event.event")}</label>
                        <Form.Item name="title">
                          <Input
                            className="form-control"
                            placeholder={t("event.event")}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("event.eventType")} </label>
                        <Form.Item name="eventType">
                          <Select
                            className="form-control"
                            placeholder={t("event.eventType")}
                          >
                            <Select.Option value="online">
                              {t("event.online")}
                            </Select.Option>
                            <Select.Option value="offline">
                              {t("event.offline")}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="btn_clumn d-flex justify-content-between">
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="btn btn-primary ripple-effect"
                          >
                            {t("common.search")}
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="button"
                            onClick={this.onReset}
                            className="btn btn-outline-dark ripple-effect"
                          >
                            {t("common.reset")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="btnBox d-block d-flex w-100">
                  <Badge count={filterCount}>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleToggle("openFilter");
                      }}
                      id="filter"
                      className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-3"
                    >
                      <i className="icon-filter-line"></i>
                    </a>
                  </Badge>
                </div>
              </div>
            </div>
            {/* <!-- page title section end --> */}

            <RemoteDataTable
              data={data}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
              isFilter={false}
              columns={columns}
              // selectRow={{}}
              defaultSorted={defaultSorted}
              loading={isLoading}
              noDataMessage={noDataMessage}
            />
          </div>
        </main>
      </>
    );
  }
}

PastEvent.propTypes = {
  // bredcrumbs: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter(PastEvent));
