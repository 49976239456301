import React, { PureComponent } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Form, Select, Input, Button, Badge } from "antd";
import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import RemoteDataTable from "../../components/dataTable";
import ReserveUsernameAddEdit from "../../components/modals/reserveUsernameAddEdit";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  statusFormatter,
  serialNumberFormatter,
  getPageSizeFromURL,
  addPageSizeInURL,
  filterDataObj,
  checkUserPermission,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

class ReserveUsername extends PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isFirstTimeFetching: true,
      openFilter: false,
      tagAddEditModal: false,
      editData: {},
      filterData: {},
      filterCount: 0,
      isLoading: true,
      data: [],
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      showModal: false,
      reasonId: 0,
      defaultSorted: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      columns: [
        {
          dataField: "id",
          text: props.t("common.id"),
          sort: true,
          hidden: true,
        },
        {
          dataField: "isDummySno",
          text: props.t("common.sNo"),
          // sort: true,
          formatter: (cell, row, rowIndex) =>
            serialNumberFormatter(
              rowIndex,
              this.state.page,
              this.state.sizePerPage
            ),
        },
        {
          dataField: "name",
          text: props.t("username.name"),
          headerAlign: "left",
          align: "left",
          sort: false,
        },
        {
          dataField: "status",
          text: props.t("common.status"),
          headerAlign: "left",
          align: "left",
          formatter: (cell, row) =>
            statusFormatter(cell, row, this.onchangeStatus, "", "hashtag"),
        },
        {
          dataField: "isDummyField",
          text: props.t("common.action"),
          headerAlign: "left",
          align: "left",
          formatter: this.actionFormatter,
        },
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchTag(queryParams);
        }
      );
    }
  };

  fetchTag = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
      ...this.state.filterData,
    };
    try {
      const payload = {
        ...ApiEndPoints.getReserveUsername,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        if (sortField === "isDummySno") {
          sortField = "id";
        }
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };

        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history);
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchTag(queryParams);
        }
      }
    } else {
      this.fetchTag(queryParams);
    }
  };

  showConfirm = (id) => {
    this.setState({
      ...this.state,
      showModal: true,
      reasonId: id,
    });
  };

  reserveUsernameDelete = async (row) => {
    try {
      let payload = ApiEndPoints.deleteReserveUsername(this.state.reasonId);
      let res = await APIrequest(payload);
      if (res.success) {
        modalNotification({
          type: "success",
          message: "Deleted",
          description: textMessages.reserveUsernameDelete,
        });
        this.setState(
          {
            ...this.state,
            reasonsId: 0,
            showModal: false,
          },
          () => {
            this.fetchTag();
          }
        );
      }
    } catch (error) {
      logger(error);
    }
  };

  onHideConfirmation = () => {
    this.setState({
      ...this.state,
      showModal: false,
      reasonId: 0,
    });
  };

  actionFormatter = (cell, row) => {
    const { t } = this.props;
    return (
      <>
        {" "}
        {checkUserPermission(this.props.userData, "reserve_username") ? (
          <div className="dropdown">
            <a
              href="/"
              onClick={(e) => e.preventDefault()}
              className="dropdown-toggle"
              id={`dropdownMenuButton_${row.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="ni ni-more-h"></span>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.tagAddEdit(row);
                }}
              >
                {t("common.edit")}
              </a>
              <a
                className="dropdown-item"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.showConfirm(row.id);
                }}
              >
                {t("common.delete")}
              </a>
            </div>
          </div>
        ) : (
          "-"
        )}
      </>
    );
  };

  onchangeStatus = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }

      const payload = {
        ...ApiEndPoints.updateStatusReserveUsername(row.id),
        bodyData: {
          status,
        },
      };
      const res = await APIrequest(payload);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || textMessages.statusUpdate,
      });
      const dataTemp = this.state.data;
      const indexData = dataTemp.findIndex((d) => d.id === row.id);
      if (indexData > -1) {
        dataTemp[indexData].status = status;
      }
      this.setState({
        data: dataTemp,
      });
      resHandleChange(status);
    } catch (error) {
      resHandleChange(row.status);
      logger({ "error:": error });
    }
  };

  tagAddEdit = (row = {}) => {
    this.setState(
      {
        editData: row,
      },
      () => {
        this.handleToggle("tagAddEditModal");
      }
    );
  };

  onFinish = async (values) => {
    const { filterData, filterCount } = filterDataObj(values);

    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData,
        filterCount,
      },
      () => {
        this.fetchTag();
        this.handleToggle("openFilter");
      }
    );
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  onReset = () => {
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData: {},
        filterCount: 0,
      },
      () => {
        this.formRef.current.resetFields();
        this.fetchTag();
      }
    );
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      document.body.classList.toggle("overflow-hidden", !state.openFilter);
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  render() {
    const {
      openFilter,
      tagAddEditModal,
      editData,
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      filterCount,
    } = this.state;
    const { t } = this.props;

    const noDataMessage =
      filterCount > 0
        ? textMessages.noTagDataFound
        : textMessages.noDataTagList;

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("username.title")}`}
          description={`${t("username.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            <div className="page-title-row page-title-btn d-flex">
              <div className="page-title-row__left">
                <BreadCrumb
                  bredcrumbs={[
                    {
                      name: t("dashboard.title"),
                      path: "/",
                    },
                    {
                      name: t("username.title"),
                    },
                  ]}
                />
                <h1 className="page-title-row__left__title text-capitalize mb-lg-0">
                  {t("username.title")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div
                  className={`filterForm ${
                    openFilter ? "filterForm--open" : ""
                  }`}
                >
                  <div className="filterForm__head">
                    <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleToggle("openFilter");
                      }}
                      id="filterClose"
                    >
                      <i className="icon-cross"></i>
                    </a>
                  </div>
                  <div className="filterForm__body">
                    <Form
                      name="reserveUsernameFilter"
                      className="form-inline"
                      ref={this.formRef}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      initialValues={{ status: "" }}
                    >
                      <div className="form-group">
                        <label> {t("username.name")} </label>
                        <Form.Item name="name">
                          <Input
                            className="form-control"
                            placeholder={t("username.name")}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("common.status")} </label>
                        <Form.Item name="status">
                          <Select
                            className="form-control"
                            placeholder={t("common.status")}
                          >
                            <Select.Option value="">
                              {t("common.allStatus")}
                            </Select.Option>
                            <Select.Option value="active">
                              {t("common.active")}
                            </Select.Option>
                            <Select.Option value="inactive">
                              {t("common.inactive")}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="btn_clumn d-flex justify-content-between">
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="btn btn-primary ripple-effect"
                          >
                            {t("common.search")}
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="button"
                            onClick={this.onReset}
                            className="btn btn-outline-dark ripple-effect"
                          >
                            {t("common.reset")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="btnBox d-block d-flex w-100">
                  {checkUserPermission(
                    this.props.userData,
                    "reserve_username"
                  ) ? (
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.tagAddEdit();
                      }}
                      className="btn btn-sm btn-primary ripple-effect text-uppercase"
                    >
                      {" "}
                      {t("username.add")}
                    </a>
                  ) : (
                    ""
                  )}
                  <Badge count={filterCount}>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleToggle("openFilter");
                      }}
                      id="filter"
                      className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-3"
                    >
                      <i className="icon-filter-line"></i>
                      {/* <span>Filter</span> */}
                    </a>
                  </Badge>
                </div>
              </div>
            </div>
            <RemoteDataTable
              data={data}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
              isFilter={false}
              columns={columns}
              defaultSorted={defaultSorted}
              loading={isLoading}
              noDataMessage={noDataMessage}
            />
          </div>

          {/* Add/Edit Tag */}
          <ReserveUsernameAddEdit
            show={tagAddEditModal}
            data={editData}
            onSubmitSuccess={this.fetchTag}
            onHide={() => this.tagAddEdit()}
          />
        </main>
        <SweetAlert
          warning
          showCancel
          show={this.state.showModal}
          confirmBtnText={t("common.confirm")}
          confirmBtnBsStyle="danger"
          title={t("common.confirmWarning")}
          onConfirm={this.reserveUsernameDelete}
          onCancel={this.onHideConfirmation}
          focusCancelBtn
        >
          {t("reportedReasons.warning")}
        </SweetAlert>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData || "",
  };
};

ReserveUsername.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(connect(mapStateToProps)(ReserveUsername));
