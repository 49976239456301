import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Form, Select, Input, Button, DatePicker, Badge, Tooltip } from "antd";
import PropTypes from "prop-types";

import RemoteDataTable from "../../components/dataTable";
import { addCollectionFeedAction } from "../../redux/common/commonSlice";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  statusFormatter,
  dateFormatter,
  dateFormatDMY,
  serialNumberFormatter,
  getPageSizeFromURL,
  addPageSizeInURL,
  filterDataObj,
  videoFormatter,
  goToVideoDetail,
  goToUserDetail,
  checkUserPermission,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";
import CollectionList from "../modals/collectionList";
import CollectionManagement from "../modals/collectionManagement";
import Confirmation from "../modals/confirmation";
import MakeVideoAsTop from "../modals/makeVideoAsTop";

class FeedSelection extends PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isFirstTimeFetching: true,
      showConfirmationModal: false,
      addVideoAsTop: false,
      resetSelected: false,
      showCollectionModal: false,
      showMakeVideoAsTopModal: false,
      openFilter: false,
      isLoading: true,
      showCollectionListData: [],
      currentVideoName: "",
      showCollectionListModal: false,
      data: [],
      feedIds: [],
      filterData: {},
      videoData: {},
      filterCount: 0,
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      defaultSorted: [
        {
          dataField: "isDummyField1",
          order: "desc",
        },
        {
          dataField: "id",
          order: "desc",
        },
      ],
      columns: [
        {
          dataField: "id",
          text: props.t("common.id"),
          sort: true,
          hidden: true,
        },
        {
          dataField: "isDummySno",
          text: props.t("common.sNo"),
          // sort: true,
          formatter: (cell, row, rowIndex) =>
            serialNumberFormatter(
              rowIndex,
              this.state.page,
              this.state.sizePerPage
            ),
        },
        {
          dataField: "mediaFileUrl",
          text: props.t("common.video"),
          headerAlign: "left",
          align: "left",
          formatter: (cell, row) => videoFormatter(cell, row),
        },
        {
          dataField: "title",
          text: props.t("video.description"),
          headerAlign: "left",
          align: "left",
          sort: true,
          style: {
            textTransform: "capitalize",
          },
          formatter: (cell, row) => goToVideoDetail(cell, row, row.id),
        },
        {
          dataField: "CollectionFeeds",
          text: props.t("collectionVideo.names"),
          headerAlign: "left",
          align: "left",
          style: {
            textTransform: "capitalize",
          },
          formatter: this.collectionListFormatter,
        },
        {
          dataField: "likeCount",
          text: props.t("video.likeCount"),
          headerAlign: "left",
          align: "center",
        },
        {
          dataField: "viewsCount",
          text: props.t("video.viewCount"),
          headerAlign: "left",
          align: "center",
        },
        {
          dataField: "status",
          text: props.t("common.status"),
          headerAlign: "left",
          align: "left",
          formatter: (cell, row) =>
            statusFormatter(cell, row, this.onchangeStatus, "video_collection"),
        },
        {
          dataField: "createdAt",
          text: props.t("common.uploadedAt"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: dateFormatter,
        },
        {
          dataField: "User.username",
          text: props.t("common.username"),
          headerAlign: "left",
          align: "left",
          formatter: (cell, row) =>
            goToUserDetail(cell, row.User, row.User.id, {
              bredcrumbs: props.bredcrumbs,
            }),
        },
        {
          dataField: "User.name",
          text: props.t("common.name"),
          headerAlign: "left",
          align: "left",
          style: {
            textTransform: "capitalize",
          },
          // formatter: (cell, row) => goToUserDetail(cell, row.User, row.User.id, { bredcrumbs: this.state.bredcrumbs })
        },
        {
          dataField: "isDummyField1",
          text: props.t("common.topPosition"),
          headerAlign: "left",
          align: "center",
          formatter: this.topPositionFormatter,
        },
        {
          dataField: "isDummyField",
          text: props.t("common.action"),
          headerAlign: "left",
          align: "left",
          formatter: this.actionFormatter,
        },
      ],
    };
  }

  componentDidMount() {
    this.fetchInfiniteCollectionList();
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  fetchInfiniteCollectionList = async () => {
    try {
      const payload = {
        ...ApiEndPoints.getCollectionFeed,
        queryParams: {
          sortBy: "title",
          sortType: "asc",
          // status: 'active',
          listType: "all",
        },
      };
      const res = await APIrequest(payload);
      this.props.addCollectionRedux(res.data.rows);
    } catch (error) {
      logger({ "error:": error });
    }
  };

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchFeed(queryParams);
        }
      );
    }
  };

  /**
   * Local formatters
   */
  actionFormatter = (cell, row) => {
    const { t } = this.props;
    return (
      <>
        {checkUserPermission(this.props.userData, "video_collection") ? (
          <div className="dropdown">
            <a
              href="/"
              onClick={(e) => e.preventDefault()}
              className="dropdown-toggle"
              id={`dropdownMenuButton_${row.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="ni ni-more-h"></span>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.manageVideoCollections(row);
                }}
              >
                {t("collectionVideo.title")}
              </a>
              <a
                className="dropdown-item"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.makeVideoAsTop(row);
                }}
              >
                {t("collectionVideo.makeVideoAsTop")}
              </a>
            </div>
          </div>
        ) : (
          "-"
        )}
      </>
    );
  };

  topPositionFormatter = (cell, row, index) => {
    let coll = row.CollectionFeeds.filter(
      (d) => Number(d.collectionId) === Number(this.props.collectionId)
    );

    if (coll[0].listOrder < 1) {
      return "-";
    }

    return <img height="30px" src="/images/top.svg" alt={"top-icon"} />;
  };

  fetchFeed = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
      ...this.state.filterData,
      collectionId: this.props.collectionId,
      collectionListType:
        this.props.tabName === "collection" ? "collection_feed" : "all_feed",
    };
    try {
      const payload = {
        ...ApiEndPoints.getFeed,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        resetSelected: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
      if (this.state.page > 1 && res.data.rows.length < 1) {
        this.setState(
          (prevState) => {
            return {
              page: prevState.page - 1,
              isLoading: true,
              data: [],
              totalSize: 0,
            };
          },
          () => {
            addPageSizeInURL(
              this.state.page,
              this.state.sizePerPage,
              this.props.history,
              { tab: this.props.tabName }
            );
            this.fetchFeed();
          }
        );
      }
    } catch (error) {
      logger({ "error:": error });
    }
  };

  handleFilter = () => {
    this.setState((state) => {
      document.body.classList.toggle("overflow-hidden", !state.openFilter);
      return {
        openFilter: !state.openFilter,
      };
    });
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        if (sortField === "isDummySno") {
          sortField = "id";
        }
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };

        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history, {
            tab: this.props.tabName,
          });
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  handleSelectedRows = (feedIds) => {
    this.setState({
      feedIds,
    });
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchFeed(queryParams);
        }
      }
    } else {
      this.fetchFeed(queryParams);
    }
  };

  manageVideoCollections = (videoData = {}) => {
    let showCollectionModal = false;

    if (Object.keys(videoData).length > 0) {
      showCollectionModal = true;
    }

    this.setState({
      showCollectionModal,
      videoData,
    });
  };

  makeVideoAsTop = (videoData = {}) => {
    let showMakeVideoAsTopModal = false;

    if (Object.keys(videoData).length > 0) {
      showMakeVideoAsTopModal = true;
    }

    this.setState({
      showMakeVideoAsTopModal,
      videoData,
    });
  };

  collectionListFormatter = (cell, row) => {
    const { t } = this.props;
    let collectionList = row.CollectionFeeds;
    if (collectionList.length === 0) {
      return <>{textMessages.notAvailable}</>;
    }
    collectionList = collectionList.slice(0, 2);
    const names = [];
    for (const element of collectionList) {
      names.push(element.FeedCollection.title);
    }

    return (
      <>
        {names.join(", ")}{" "}
        <a
          href="/"
          className="theme-color"
          onClick={(e) => {
            e.preventDefault();
            this.collectionListModal(row);
          }}
        >
          {t("common.viewAll")}
        </a>
      </>
    );
  };

  collectionListModal = (data) => {
    this.setState(
      {
        showCollectionListData: data ? data.CollectionFeeds : [],
        currentVideoName: data ? data.title : "",
      },
      () => {
        this.handleToggle("showCollectionListModal");
      }
    );
  };

  onchangeStatus = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }

      const payload = {
        ...ApiEndPoints.updateStatusFeed(row.id),
        bodyData: {
          status,
        },
      };
      const res = await APIrequest(payload);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || textMessages.statusUpdate,
      });
      const dataTemp = this.state.data;
      const indexData = dataTemp.findIndex((d) => d.id === row.id);
      if (indexData > -1) {
        dataTemp[indexData].status = status;
      }
      this.setState({
        data: dataTemp,
      });
      resHandleChange(status);
    } catch (error) {
      resHandleChange(row.status);
      logger({ "error:": error });
    }
  };

  onFinish = async (values) => {
    values.languageId = values.languageId.join(",");
    values.videoCatId = values.videoCatId.join(",");

    const { filterData, filterCount } = filterDataObj(values);
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData,
        filterCount,
      },
      () => {
        this.fetchFeed();
        this.handleFilter();
      }
    );
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  onReset = () => {
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData: {},
        filterCount: 0,
      },
      () => {
        this.formRef.current.resetFields();
        this.fetchFeed();
      }
    );
  };

  handleToggle = (stateName, flag1) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
        addVideoAsTop: flag1 === "addVideoAsTop",
      };
    });
  };

  onConfirmation = async () => {
    const { tabName, collectionId } = this.props;
    const { feedIds, addVideoAsTop } = this.state;
    try {
      if (tabName === "collection") {
        if (addVideoAsTop) {
          let feedData = [];
          let index = 0;
          for (const element of feedIds) {
            feedData.push({
              feedId: element,
              topPosition: index + 1,
            });
            index++;
          }

          const payload = {
            ...ApiEndPoints.updateOrderCollectionMultiFeed,
            bodyData: {
              feedData,
              collectionId,
            },
          };
          await APIrequest(payload);
        } else {
          const payload = {
            ...ApiEndPoints.deleteFeedFromCollection,
            bodyData: {
              feedIds,
              collectionId,
            },
          };
          await APIrequest(payload);
        }
      } else {
        const payload = {
          ...ApiEndPoints.addFeedFromCollection,
          bodyData: {
            feedIds,
            collectionId,
          },
        };
        await APIrequest(payload);
      }
      this.setState(
        {
          feedIds: [],
          resetSelected: true,
        },
        () => {
          this.fetchFeed();
        }
      );
      return true;
    } catch (error) {
      return error;
    }
  };

  getTitleModalConfirmation = (t, tabName, addVideoAsTop) => {
    if (tabName === "collection") {
      if (addVideoAsTop) {
        return t("common.addVideoAsTopConfirmation");
      }

      return t("common.removeCollectionConfirmation");
    }
    return t("common.addCollectionConfirmation");
  };

  render() {
    const {
      openFilter,
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      filterCount,
      showConfirmationModal,
      feedIds,
      showCollectionListData,
      currentVideoName,
      showCollectionListModal,
      showCollectionModal,
      showMakeVideoAsTopModal,
      videoData,
      resetSelected,
      addVideoAsTop,
    } = this.state;

    const {
      t,
      tabName,
      languagesList,
      videoCategories: videoCategoriesFS,
    } = this.props;

    const noDataMessage = textMessages.noDataFound;

    const titleModalConfirmation = this.getTitleModalConfirmation(
      t,
      tabName,
      addVideoAsTop
    );

    return (
      <>
        <div className="page-title-row d-flex detail-tabs pb-3 mb-0">
          <div className="page-title-row__left"></div>
          <div className="page-title-row__right">
            <div
              className={`filterForm ${openFilter ? "filterForm--open" : ""}`}
            >
              <div className="filterForm__head">
                <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleFilter();
                  }}
                  id="filterClose"
                >
                  <i className="icon-cross"></i>
                </a>
              </div>
              <div className="filterForm__body">
                <Form
                  name="videoFilter"
                  className="form-inline"
                  ref={this.formRef}
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                  initialValues={{
                    status: "",
                    languageId: [],
                    videoCatId: [],
                  }}
                >
                  <div className="form-group">
                    <label>{t("common.username")}</label>
                    <Form.Item name="username">
                      <Input
                        className="form-control"
                        placeholder={t("common.username")}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>{t("common.name")} </label>
                    <Form.Item name="name">
                      <Input
                        className="form-control"
                        placeholder={t("common.name")}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>{t("hashtag.name")} </label>
                    <Form.Item name="tagTitle">
                      <Input
                        className="form-control"
                        placeholder={t("hashtag.name")}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>{t("video.description")}</label>
                    <Form.Item name="title">
                      <Input
                        className="form-control"
                        placeholder={t("video.description")}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>{t("video.likeCount")}</label>
                    <Form.Item name="likeCount">
                      <Input
                        className="form-control"
                        placeholder={t("video.likeCount")}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>{t("video.viewCount")}</label>
                    <Form.Item name="viewCount">
                      <Input
                        className="form-control"
                        placeholder={t("video.viewCount")}
                      />
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>{t("approver.languages")} </label>
                    <Form.Item name="languageId">
                      <Select
                        showSearch
                        className="form-control"
                        mode="multiple"
                        placeholder={t("approver.languages")}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {languagesList.map((data) => {
                          return (
                            <Select.Option
                              key={`languageId_${JSON.stringify(data)}`}
                              disabled={data.status === "inactive"}
                              className="text-capitalize"
                              value={data.id}
                            >
                              {data.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>{t("approver.videoCategories")} </label>
                    <Form.Item name="videoCatId">
                      <Select
                        showSearch
                        className="form-control"
                        mode="multiple"
                        placeholder={t("approver.videoCategories")}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {videoCategoriesFS.map((data) => {
                          return (
                            <Select.Option
                              key={`videoCatId_${JSON.stringify(data)}`}
                              disabled={data.status === "inactive"}
                              className="text-capitalize"
                              value={data.id}
                            >
                              {data.title}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>{t("common.status")} </label>
                    <Form.Item name="status">
                      <Select
                        className="form-control"
                        placeholder={t("common.status")}
                      >
                        <Select.Option value="">
                          {t("common.allStatus")}
                        </Select.Option>
                        <Select.Option value="active">
                          {t("common.active")}
                        </Select.Option>
                        <Select.Option value="inactive">
                          {t("common.inactive")}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="form-group">
                    <label>{t("common.uploadedAt")}</label>
                    <Form.Item name="createdAt">
                      <DatePicker.RangePicker
                        placeholder={[
                          t("common.startDate"),
                          t("common.endDate"),
                        ]}
                        // getPopupContainer={node => node.parentNode} // https://github.com/ant-design/ant-design/issues/22987
                        format={dateFormatDMY}
                      />
                    </Form.Item>
                  </div>
                  <div className="btn_clumn d-flex justify-content-between">
                    <Form.Item>
                      <Button
                        htmlType="submit"
                        className="btn btn-primary ripple-effect"
                      >
                        {t("common.search")}
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        htmlType="button"
                        onClick={this.onReset}
                        className="btn btn-outline-dark ripple-effect"
                      >
                        {t("common.reset")}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </div>
            <div className="btnBox">
              {checkUserPermission(this.props.userData, "video_collection")
                ? tabName === "collection" && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleToggle(
                          "showConfirmationModal",
                          "addVideoAsTop"
                        );
                      }}
                      id="addToCollection"
                      className="btn btn-sm btn-primary ripple-effect text-uppercase"
                      disabled={feedIds.length === 0 || feedIds.length > 5}
                    >
                      <Tooltip title="Upto 5 selection">
                        {t("collectionVideo.makeVideoAsTop")}
                      </Tooltip>
                    </button>
                  )
                : ""}
              {checkUserPermission(this.props.userData, "video_collection") ? (
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleToggle("showConfirmationModal");
                  }}
                  id="addToCollection"
                  className="btn btn-sm btn-primary ripple-effect text-uppercase"
                  disabled={feedIds.length === 0}
                >
                  {tabName === "collection"
                    ? t("collectionVideo.removeFrom")
                    : t("collectionVideo.addTo")}
                </button>
              ) : (
                ""
              )}
              <Badge count={filterCount}>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleFilter();
                  }}
                  id="filter"
                  className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-3"
                >
                  <i className="icon-filter-line"></i>
                </a>
              </Badge>
            </div>
          </div>
        </div>
        <RemoteDataTable
          data={data}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          onTableChange={this.handleTableChange}
          isFilter={false}
          columns={
            tabName === "collection"
              ? columns
              : columns.slice(0, columns.length - 2)
          }
          selectRow={true}
          resetSelected={resetSelected}
          defaultSorted={
            tabName === "collection"
              ? defaultSorted.slice(0, defaultSorted.length - 1)
              : defaultSorted.slice(1)
          }
          loading={isLoading}
          noDataMessage={noDataMessage}
          handleSelectedRows={this.handleSelectedRows}
        />
        <Confirmation
          show={showConfirmationModal}
          onConfirmation={this.onConfirmation}
          onHide={() => this.handleToggle("showConfirmationModal")}
          title={titleModalConfirmation}
        />
        <CollectionList
          data={showCollectionListData}
          title={currentVideoName}
          show={showCollectionListModal}
          onHide={() => this.collectionListModal()}
          for={"video"}
        />
        <CollectionManagement
          show={showCollectionModal}
          data={videoData}
          onSubmitSuccess={this.fetchFeed}
          onHide={() => this.manageVideoCollections()}
          for={"video"}
        />
        <MakeVideoAsTop
          totalRecords={totalSize}
          show={showMakeVideoAsTopModal}
          data={videoData}
          onSubmitSuccess={this.fetchFeed}
          onHide={() => this.makeVideoAsTop()}
          collectionId={this.props.collectionId}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languagesList: state.common.languages,
    videoCategories: state.common.videoCategories,
    userData: state.auth.userData || "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCollectionRedux: (res) => dispatch(addCollectionFeedAction(res)),
  };
};

FeedSelection.propTypes = {
  history: PropTypes.object.isRequired,
  languagesList: PropTypes.array.isRequired,
  videoCategories: PropTypes.array.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
  addCollectionRedux: PropTypes.func.isRequired,
  tabName: PropTypes.string.isRequired,
  collectionId: PropTypes.any.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(FeedSelection))
);
