import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Badge, Button, Form, Input } from "antd";
import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import RemoteDataTable from "../../components/dataTable";
import ReplyMessage from "../../components/modals/replyMessage";
import ShowMoreText from "../../components/modals/showMoreText";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  readMoreText,
  serialNumberFormatter,
  getPageSizeFromURL,
  addPageSizeInURL,
  checkUserPermission,
  filterDataObj,
  emailFormatter,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import MetaTags from "../../utilities/metaTags";
import { getSortField } from "../../utilities/table";

class PendingQueries extends PureComponent {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isFirstTimeFetching: true,
      replyMessageModal: false,
      openMessageReplyModal: false,
      isLoading: true,
      rowData: {},
      dataOfMessageReply: {},
      openFilter: false,
      filterCount: 0,
      data: [],
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      defaultSorted: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      columns: [
        {
          dataField: "id",
          text: props.t("common.id"),
          sort: true,
          hidden: true,
        },
        {
          dataField: "isDummySno",
          text: props.t("common.sNo"),
          // sort: true,
          formatter: (cell, row, rowIndex) =>
            serialNumberFormatter(
              rowIndex,
              this.state.page,
              this.state.sizePerPage
            ),
        },
        {
          dataField: "User.username",
          text: props.t("common.username"),
          headerAlign: "left",
          align: "left",
          sort: true,
        },

        {
          dataField: "User.email",
          text: props.t("common.email"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: emailFormatter,
        },
        {
          dataField: "message",
          text: props.t("common.query"),
          headerAlign: "left",
          align: "left",
          classes: "w_460 text-wrap",
          sort: true,
          formatter: (cell, row) =>
            readMoreText(cell, row, "message", this.showHideMoreText, props.t),
        },
        {
          dataField: "isDummyField",
          text: props.t("common.reply"),
          headerAlign: "left",
          align: "left",
          formatter: this.actionFormatter,
        },
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchPendingRequest(queryParams);
        }
      );
    }
  };

  fetchPendingRequest = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
      status: "open",
    };
    try {
      const payload = {
        ...ApiEndPoints.getRequest,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  actionFormatter = (cell, row) => {
    const { t } = this.props;
    return (
      <>
        {" "}
        {checkUserPermission(this.props.userData, "support") ? (
          <div className="dropdown">
            <a
              href="/"
              onClick={(e) => e.preventDefault()}
              className="dropdown-toggle"
              id={`dropdownMenuButton_${row.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="ni ni-more-h"></span>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(
                    {
                      rowData: row,
                    },
                    () => {
                      this.handleToggle("replyMessageModal");
                    }
                  );
                }}
              >
                {t("common.reply")}
              </a>
            </div>
          </div>
        ) : (
          "-"
        )}
      </>
    );
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        sortField = getSortField(sortField);
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };
        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history, {
            tab: "pending",
          });
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchPendingRequest(queryParams);
        }
      }
    } else {
      this.fetchPendingRequest(queryParams);
    }
  };

  showHideMoreText = (dataOfMessageReply = {}) => {
    this.setState(
      {
        dataOfMessageReply,
      },
      () => {
        this.handleToggle("openMessageReplyModal");
      }
    );
  };
  onFinish = async (values) => {
    const { filterData, filterCount } = filterDataObj(values);

    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData,
        filterCount,
      },
      () => {
        this.fetchPendingRequest();
        this.handleToggle("openFilter");
      }
    );
  };
  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };
  onReset = () => {
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData: {},
        filterCount: 0,
      },
      () => {
        this.formRef.current.resetFields();
        this.fetchPendingRequest();
      }
    );
  };
  render() {
    const {
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      replyMessageModal,
      rowData,
      dataOfMessageReply,
      openMessageReplyModal,
      filterCount,
      openFilter,
    } = this.state;

    const { t } = this.props;
    const bredcrumbs = [
      {
        name: t("dashboard.title"),
        path: "/",
      },
      {
        name: t("support.pendingQueries"),
        path: "/support/pending-queries",
      },
    ];
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("support.pendingQueries")}`}
          description={`${t("support.pendingQueries")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            {/* <!-- page title section start --> */}
            <div className="page-title-row page-title-btn d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title text-capitalize mb-lg-0">
                  {t("support.pendingQueries")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div
                  className={`filterForm ${
                    openFilter ? "filterForm--open" : ""
                  }`}
                >
                  <div className="filterForm__head">
                    <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleToggle("openFilter");
                      }}
                      id="filterClose"
                    >
                      <i className="icon-cross"></i>
                    </a>
                  </div>
                  <div className="filterForm__body">
                    <Form
                      name="categoryFilter"
                      className="form-inline"
                      ref={this.formRef}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      initialValues={{ status: "" }}
                    >
                      <div className="form-group">
                        <label>{t("common.username")}</label>
                        <Form.Item name="userName">
                          <Input
                            className="form-control"
                            placeholder={t("common.username")}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("common.email")}</label>
                        <Form.Item name="email">
                          <Input
                            className="form-control"
                            placeholder={t("common.email")}
                          />
                        </Form.Item>
                      </div>

                      <div className="btn_clumn d-flex justify-content-between">
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="btn btn-primary ripple-effect"
                          >
                            {t("common.search")}
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="button"
                            onClick={this.onReset}
                            className="btn btn-outline-dark ripple-effect"
                          >
                            {t("common.reset")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="btnBox d-block d-flex w-100">
                  <Badge count={filterCount}>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleToggle("openFilter");
                      }}
                      id="filter"
                      className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-3"
                    >
                      <i className="icon-filter-line"></i>
                      {/* <span>Filter</span> */}
                    </a>
                  </Badge>
                </div>
              </div>
            </div>
            {/* <!-- page title section end --> */}
            <div className="custom-tabs">
              <div className="detail-wrap mt-2 mt-md-3">
                <div className="custom-tabs detail-ta bs">
                  <div className="tab-content mt-0">
                    <div className="tab-pane fade show active">
                      <div className="common-table">
                        <div className="table-responsive">
                          <RemoteDataTable
                            data={data}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange}
                            isFilter={false}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            loading={isLoading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        {/* Reply Message */}
        <ReplyMessage
          data={rowData}
          show={replyMessageModal}
          onHide={() => this.handleToggle("replyMessageModal")}
          onSubmitSuccess={this.fetchPendingRequest}
        />
        {/* Message  */}
        <ShowMoreText
          title={t("common.message")}
          data={
            Object.keys(dataOfMessageReply).length
              ? dataOfMessageReply.data
              : ""
          }
          show={openMessageReplyModal}
          onHide={this.showHideMoreText}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData || "",
  };
};

PendingQueries.propTypes = {
  // bredcrumbs: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(PendingQueries))
);
