import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import SettingsForm from "../../components/forms/settings";
import LoadingView from "../../components/loadingView";
import NotFoundView from "../../components/notFoundView";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

class Settings extends PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSpin: false,
      error: false,
      data: [],
      totalSize: 0,
    };
  }

  componentDidMount() {
    this.fetchSettings();
  }

  fetchSettings = async () => {
    try {
      const payload = {
        ...ApiEndPoints.getSettings,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        error: false,
        data: res.data,
        totalSize: res.data.length > 0 ? res.data.length : 0,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        error: true,
      });
      logger({ "error:": error });
    }
  };

  onFinish = (values) => {
    this.setState(
      {
        isSpin: true,
      },
      async () => {
        try {
          const prevStateData = this.state.data;
          const data = [];

          for (const key in values) {
            if (Object.hasOwnProperty.call(values, key)) {
              data.push({
                id: key,
                value: values[key],
              });
            }
          }

          for (const element of prevStateData) {
            element.value = values[element.id];
          }

          const payload = {
            ...ApiEndPoints.updateSettings,
            bodyData: {
              data,
            },
          };

          const res = await APIrequest(payload);

          modalNotification({
            type: "success",
            message: "Success",
            description: res.message || textMessages.updateSettingsMessage,
          });
          this.setState({
            isSpin: false,
            data: prevStateData,
          });
        } catch (error) {
          this.setState({
            isSpin: false,
          });
          logger({ "error:": error });
        }
      }
    );
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  onReset = () => {
    this.setState(
      {
        filterData: {},
      },
      () => {
        this.formRef.current.resetFields();
      }
    );
  };

  render() {
    const { isLoading, data, error, isSpin } = this.state;
    const { t } = this.props;

    const initialValues = {};

    if (isLoading) {
      return <LoadingView />;
    }

    if (error) {
      return <NotFoundView />;
    }

    for (const element of data) {
      initialValues[element.id] = element.value;
    }

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("settings.title")}`}
          description={`${t("settings.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent instructorsEdit">
          <div className="container-fluid">
            <div className="page-title-row  d-flex">
              <div className="page-title-row__left">
                <BreadCrumb
                  bredcrumbs={[
                    {
                      name: t("dashboard.title"),
                      path: "/",
                    },
                    {
                      name: t("settings.title"),
                    },
                  ]}
                />
                <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                  {t("settings.title")}
                </h1>
              </div>
            </div>

            <div className="commonBox instructorsEdit__form bg-white p-30">
              <SettingsForm
                isSpin={isSpin}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                submitButtonText={t("common.save")}
                initialValues={initialValues}
                formRef={this.formRef}
                onReset={this.onReset}
                data={data}
              />
            </div>
          </div>
        </main>
      </>
    );
  }
}

Settings.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Settings);
