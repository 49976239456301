/* eslint-disable max-len */
import config from "../../config";

const enValidationMsg = {
  /**
   * Login
   */
  enterEmail: "Enter an email",
  enterValidEmail: "Enter a valid email",
  enterPassword: "Enter a password",
  loginSuccessfully: "You login successfully",
  loginError: "Incorrect email or password",
  /**
   * Logout
   */
  logoutSuccessfully: "You logout successfully",
  /**
   * Reset Password
   */
  passwordSuccess: "Password is updated successfully",
  passwordLengthMessage: (min, max) =>
    `The Admin can be used  ${min}-${max} chars in the password field.`,
  passwordNotMatchMessage: "New Password and Confirm Password does not match",
  reEnterPassword: "Re-enter the password",
  spaceNotAllow: "Space are not allow",
  /**
   * Change Password
   */
  currentPasswordError: "Current password is wrong",
  passwordChangeSuccess: "You changed password successfully",
  enterCurrentPassword: "Enter current password",
  enterNewPassword: "Enter new password",
  reEnterNewPassword: "Re-enter the new password",
  /**
   * Approver
   */
  enterApproverName: "Enter approver name",
  addApproverMessage: "Approver added successfully",
  updateApproverMessage: "Approver updated successfully",
  selectUserRole: "Select user type",
  selectRole: "Select role",
  /**
   * Category
   */
  enterCategoryName: "Enter category name",
  addCategoryMessage: "Category added successfully",
  updateCategoryMessage: "Category updated successfully",
  /**
   * Collection
   */
  enterCollectionName: "Enter collection name",
  addCollectionMessage: "Collection added successfully",
  updateCollectionMessage: "Collection updated successfully",
  enterCollectionCurrentOrder: "Enter collection current order",
  enterCollectionNewOrder: "Enter collection new order",
  updateOrderCollectionMessage: "Collection order updated successfully",
  selectCollection: "Select Collection",
  collectionAssigned: "Collection assigned to audio successfully",
  /**
   * Artist
   */
  enterArtistName: "Enter artist name",
  selectArtistIcon: "Select artist icon",
  addArtistMessage: "Artist added successfully",
  updateArtistMessage: "Artist updated successfully",
  /**
   * Tag
   */
  enterTagName: "Enter tag title",
  validTagMessage: "# at starting and spaces in words not allowed",
  addTagMessage: "New tag added successfully",
  updateTagMessage: "Tag updated successfully",
  /**
   * Points
   */
  updatePointsMessage: "Points updated successfully",
  enterPoint: "Enter points",
  pointValueMessage: "Only numbers are allowed",
  /**
   * Settings
   */
  updateSettingsMessage: "Settings updated successfully",
  enterSetting: "Enter Setting value",
  /**
   * CMS
   */
  updateCMSMessage: "CMS updated successfully",
  enterCMSTitle: "Enter CMS title",
  enterCMSContent: "Enter CMS content",
  /**
   * FAQ
   */
  addFAQ: "New FAQ added successfully",
  updateFAQ: "FAQ updated successfully",
  deleteFAQ: "FAQ deleted successfully",
  enterFAQquestion: "Enter question",
  enterFAQanswer: "Enter answer",
  /**
   * ROLE
   */
  addRole: "New Role added successfully",
  updateRole: "Role updated successfully",
  enterRole: "Enter Role",
  /**
   * Album
   */
  addAlbumMessage: "Album added successfully",
  updateAlbumMessage: "Album updated successfully",
  enterAlbumName: "Enter name",
  selectAlbumIcon: "Select album icon",
  selectAlbumCategory: "Select category",
  /**
   * college
   */
  addCollegeMessage: "College added successfully",
  updateCollegeMessage: "College updated successfully",
  enterCollegeName: "Enter name",
  enterCollegeHandle: "Enter handle",
  selectCollegeCity: "Select city name",
  selectCollegeState: "Select state name",
  /**
   * college mates
   */
  addCollegeMatesMessage: "College mate added successfully",
  updateCollegeMatesMessage: "College mate updated successfully",
  selectMateType: "Select mate type",
  selectIsAmbassador: "Select is ambassador",
  selectUserName: "Select user name",
  selectCollegeName: "Select collage name",
  /**
   * Track
   */
  addTrackMessage: "Audio added successfully",
  updateTrackMessage: "Audio updated successfully",
  enterTrackLength: "Enter Audio length",
  enterTrackName: "Enter name",
  enterTrackMasterId: "Enter master ID",
  selectTrackIcon: "Select Audio file",
  selectTrackArtist: "Select artist",
  selectTrackAlbum: "Select album",
  /**
   * ReportedReasons
   */
  addReportedReason: "Reason added successfully",
  editReportedReason: "Reason Edited Successfully",
  reportedReasonDelete: "Reason Deleted Successfully",
  /**
   * Contest
   */
  addContestMessage: "Contest added successfully",
  updateContestMessage: "Contest updated successfully",
  enterContestName: "Enter contest name",
  enterContestRules: "Enter contest Rules",
  enterContestWinnerPrizeTitle: "Enter prize name",
  enterContestWinnerPrizeCorrectTitle: "Enter Correct prize name",
  selectContestPrizeImage: "Select Contest prize image",
  selectContestContestImage: "Select Contest image",
  enterCreateAt: "Select Contest start date and end date",
  entervideoIntervalLimit: "Enter valid video interval limit",
  enterTagId: "Select hastag name",
  /**
   * Preference
   */
  selectLanguage: "Select Language",
  selectCategory: "Select Category",
  /**
   * Interval
   */
  enterIntervalTime: "Enter interval time in seconds",
  timeIntervalError: "Time must be greater than 0",
  enterIntervalTimeFollowers: "Enter followers in numeric value",
  followerIntervalError: "Follower value should in the numeric",
  timeIntervalMustInError: `Time must be in the multiple of ${config.VIDEO_UPLOAD_LENGTH_MINIMUM}`,
  timeIntervalMaxError: (time) => `Max time ${time} sec is allowed`,
  addIntervalMessage: "Interval added successfully",
  updateIntervalMessage: "Interval updated successfully",
  deleteInterval: "Interval deleted successfully",
  helpingIntervalMessage: `NOTE: Enter interval in multiple of ${config.VIDEO_UPLOAD_LENGTH_MINIMUM}`,
  /**
   * Reply Message
   */
  enterResponse: "Enter message",
  /**
   * Notification
   */
  enterNotificationTitle: "Enter title",
  enterNotificationMessage: "Enter message",
  sentNotificationSuccessfully: "Notification sent successfully",
  /**
   * Upload file
   */
  fileUploadSuccess: (fileName) => `${fileName} upload successfully`,
  fileUploadFail: (fileName) => `${fileName} upload failed`,
  filesAllowed: (files) => `Only this ${files} file type are allowed`,
  fileSizeLimit: (size) => `File should be upto ${size} MB`,
  fileAudioLengthLimit: ({ minLength, maxLength }) =>
    `Audio file should be between ${minLength}-${maxLength} seconds in length`,
  fileCsvLength: `Empty Csv file not allow`,
  /**
   * Common
   */
  statusUpdate: "Status updated successfully",
  enterMinChar: (val) => `Minimum ${val} characters allowed.`,
  enterMaxChar: (val) => `Maximum ${val} characters allowed.`,
  alphaNumericOnly: "Only alphanumeric character allowed",
  orderLengthMessage: (min, max) => `Order must be between ${min}-${max}`,
  /**
   * No Data Message
   */
  notAvailable: "Not Available",
  noDataFound: "No record found",
  noDataUserList: "There is no user registered in the system",
  noDataCommunityList: "There is no community registered in the system",
  noDataApproverList: 'Click on the "Add Approver" button to add an approver',
  noDataCategoryList: 'Click on the "Add Category" button to add a category',
  noDataCollectionList:
    'Click on the "Add Collection" button to add a collection',
  noDataArtistList: 'Click on the "Add Artist" button to add an artist',
  noDataAlbumList: 'Click on the "Add Album" button to add an album',
  noDataCollegeMatesList:
    'Click on the "Add College Mate" button to add an college mates',
  noDataCollegeList: 'Click on the "Add College" button to add an college',
  noDataTrackList: 'Click on the "Add Audio" button to add an audio',
  noDataTagList: 'Click on the "Add Hashtag" button to add a hashtag',
  noDataIntervalList: 'Click on the "Add Interval" button to add an interval',
  noDataFaqList: 'Click on the "Add FAQ" button to add FAQ',
  noDataContestList: 'Click on the "Add Contest" button to add an contest',
  noDataRoleList: 'Click on the "Add Role" button to add an role',
  noAudiofound: "There are no audios to show",
  noEventfound: "There are no event to show",
  noTagDataFound: "There are no hashtag to show",
  noCollectionDataFound: "There are no collection to show",
  noUserDataFound: "There are no user to show",

  /**
   * Reserve Username
   */
  enterReserveUsername: "Enter username",
  validReserveUsernameMessage: "Spaces in words not allowed",
  addUsernameMessage: "Username added successfully",
  updateUsernameMessage: "Username updated successfully",
};

export default enValidationMsg;
