import React, { PureComponent } from "react";

import NotFoundView from "../../components/notFoundView";
import config from "../../config";
import MetaTags from "../../utilities/metaTags";

export default class NotFound extends PureComponent {
  render() {
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | Page Not Found`}
          description={`Page Not Found of ${config.NAME_TITLE}`}
        />
        <NotFoundView />
      </>
    );
  }
}
