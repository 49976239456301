export const getSortField = (sortField) => {
  switch (sortField) {
    case "User.name":
      return "name";
    case "User.username":
      return "username";
    case "User.createdAt":
      return "createdAt";
    case "User.updatedAt":
      return "updatedAt";
    case "User.id":
    case "isDummySno":
      return "id";
    default:
      return "";
  }
};
