import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Switch, Select, Avatar } from "antd";
import PropTypes from "prop-types";

import { getUserPermission } from "../../redux/auth/authSlice";
import { selectUserGroups } from "../../redux/common/commonSlice";
import { titleCase } from "../../utilities/common";
import Fancybox from "../fancybox";
import Image from "../image";

export function PhoneNumberComponent({ phoneNumberCountryCode, phoneNumber }) {
  return <> {`${phoneNumberCountryCode} ${phoneNumber}`}</>;
}

PhoneNumberComponent.propTypes = {
  phoneNumberCountryCode: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};

export function EmailComponent({ emailId }) {
  return <> {`${emailId}`}</>;
  // return <a href={`mailto: ${emailId}`}>{`${emailId}`}</a>
}

EmailComponent.propTypes = {
  emailId: PropTypes.string.isRequired,
};

export function ImageComponent({ src, imgprops }) {
  return (
    <Fancybox
      options={{
        Carousel: {
          infinite: false,
        },
      }}
    >
      <a data-fancybox="gallery" href={src}>
        <img src={src} {...imgprops} alt={src} />
      </a>
    </Fancybox>
  );
}

ImageComponent.propTypes = {
  src: PropTypes.string,
  imgprops: PropTypes.object,
};
export function NameComponentWithImage({
  firstName,
  lastName,
  image,
  userName = null,
}) {
  return (
    <div className="d-flex align-items-center avatarSec">
      <Avatar size={40} src={image} />
      <div>
        <p>
          {firstName} {lastName}
        </p>
        <small>{userName}</small>
      </div>
    </div>
  );
}
NameComponentWithImage.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  userName: PropTypes.string,
};
export function VideoComponent({ src, videoprops, imgSrc }) {
  return (
    <Fancybox
      options={{
        Carousel: {
          infinite: false,
        },
      }}
    >
      <a className="videoThumb" data-fancybox="video-gallery" href={src}>
        <Image src={imgSrc} alt={src} {...videoprops} />
        <span className="icon-video-button play_icon"></span>
      </a>
    </Fancybox>
  );
}

VideoComponent.propTypes = {
  src: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  videoprops: PropTypes.object,
};

export function AudioComponent({ src }) {
  return (
    <audio controls>
      <source src={src} />
    </audio>
  );
}
AudioComponent.propTypes = {
  src: PropTypes.string.isRequired,
};

AudioComponent.propTypes = {
  src: PropTypes.string.isRequired,
};

export function StatusBadgeComponent({ status }) {
  if (!["active", "inactive"].includes(status)) {
    return (
      <span className={"badge badge-pill align-middle ml-2"}>{status}</span>
    );
  }
  status = status === "active";
  return (
    <span
      className={`badge badge-pill ${
        status ? "badge-success" : "badge-danger"
      } align-middle ml-2`}
    >
      {status ? "Active" : "Inactive"}
    </span>
  );
}

StatusBadgeComponent.propTypes = {
  status: PropTypes.string.isRequired,
};

export function UsernameComponent({ username }) {
  return (
    <>
      <img alt="mail-icon" src="/images/user-icon.png" />{" "}
      <span>{username} </span>
    </>
  );
}

UsernameComponent.propTypes = {
  username: PropTypes.any.isRequired,
};

export function GoToUserDetailPage({ data, id, state = {}, classProps = "" }) {
  return (
    <Link
      className={classProps}
      to={{
        pathname: `/user-detail/${id}`,
        state: state,
      }}
    >
      {data}
    </Link>
  );
}

GoToUserDetailPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object,
};

export function GoToViewTracksPage({ data, id, state = {}, classProps = "" }) {
  return (
    <Link
      className={classProps}
      to={{
        pathname: `/view-track/${id}`,
        state: state,
      }}
    >
      {data}
    </Link>
  );
}

GoToViewTracksPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object,
};
export function GoToViewContestPage({ data, id, state = {}, classProps = "" }) {
  return (
    <Link
      className={classProps}
      to={{
        pathname: `/contest/${id}/participants`,
        state: state,
      }}
    >
      {data}
    </Link>
  );
}

GoToViewContestPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object,
};

export function GoToViewCollectionTracksPage({
  data,
  id,
  state = {},
  classProps = "",
}) {
  return (
    <Link
      className={classProps}
      to={{
        pathname: `/view-collection-track/${id}`,
        state: state,
      }}
    >
      <span className="text-capitalize"> {data}</span>
    </Link>
  );
}

GoToViewCollectionTracksPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object,
};
export function GoToViewTodayEventDetail({
  data,
  id,
  state = {},
  classProps = "",
}) {
  return (
    <Link
      className={classProps}
      to={{
        pathname: `/event/today-event/event-detail/${id}`,
        state: state,
      }}
    >
      {data}
    </Link>
  );
}

GoToViewTodayEventDetail.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object,
};

export function GoToViewCollectionVideosPage({
  data,
  id,
  state = {},
  classProps = "",
}) {
  return (
    <Link
      className={classProps}
      to={{
        pathname: `/view-collection-video/${id}`,
        state: state,
      }}
    >
      {data}
    </Link>
  );
}

GoToViewCollectionVideosPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object,
};

export function GoToVideoDetailPage({ data, id, state = {}, classProps = "" }) {
  return (
    <Link
      className={classProps}
      to={{
        pathname: `/video-detail/${id}`,
        state: state,
      }}
    >
      {data}
    </Link>
  );
}

GoToVideoDetailPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object,
};

export function GoToDetailPage({
  data,
  pathname,
  state = {},
  classProps = "",
}) {
  return (
    <Link
      className={classProps}
      to={{
        pathname,
        state: state,
      }}
    >
      {data}
    </Link>
  );
}

GoToDetailPage.propTypes = {
  data: PropTypes.string.isRequired,
  pathname: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object,
};

export function ReportedByCountShow({ data, row, showList }) {
  return (
    <a
      href="/"
      className="ml-5"
      onClick={(e) => {
        e.preventDefault();
        showList(row);
      }}
    >
      {data}
    </a>
  );
}

ReportedByCountShow.propTypes = {
  data: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  showList: PropTypes.func.isRequired,
};

export function ReadMoreTextShow({ data, type, showMoreText, t }) {
  if ([undefined, null, false].includes(data)) {
    return <></>;
  }
  if (data.length < 80) {
    return <> {data}</>;
  }

  return (
    <>
      {data.substring(0, 80)}{" "}
      <a
        href="/"
        className="theme-color ml-1"
        onClick={(e) => {
          e.preventDefault();
          showMoreText({ type, data });
        }}
      >
        {" "}
        {t("common.readMore")}...
      </a>
    </>
  );
}

ReadMoreTextShow.propTypes = {
  data: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  showMoreText: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export function DateComponent({ date }) {
  return <> {date}</>;
}

DateComponent.propTypes = {
  date: PropTypes.string.isRequired,
};

export function IntervalInSec({ time }) {
  return <> {time}</>;
}

IntervalInSec.propTypes = {
  time: PropTypes.number.isRequired,
};

export function LoadingSpinner() {
  return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;
}

export function GlobalLoader() {
  return (
    <div className="listloader text-center">
      <div className="Listpreloader">
        <div className="spinner">
          <div className="dot1"></div>
          <div className="dot2"></div>
        </div>
      </div>
    </div>
  );
}
export function ControlledSelect({
  cell,
  row,
  onChange,
  columnName,
  moduleKey = "",
}) {
  let isEdit = useSelector(getUserPermission(moduleKey));
  let userGroups = useSelector(selectUserGroups);
  let userGroupArray = [];
  if (userGroups.length) {
    userGroups.map((data, index) => {
      return userGroupArray.push(data.groupKey);
    });
  }

  const [isValue, setValue] = useState(cell);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setValue(cell);
  }, [cell, row]);

  const resHandleChange = (val) => {
    setIsLoading(false);
    setValue(val);
  };

  const handleChange = (val) => {
    setIsLoading(true);
    onChange(val, row, resHandleChange, columnName);
  };

  if (!userGroupArray.includes(cell)) {
    return cell;
  }
  return isEdit ? (
    <Select
      defaultValue={cell}
      onChange={handleChange}
      loading={isLoading}
      value={isValue}
    >
      {userGroups.map((data) => {
        return (
          <Select.Option
            key={JSON.stringify(data)}
            className="text-capitalize"
            value={data.groupKey}
          >
            {data.name}
          </Select.Option>
        );
      })}
    </Select>
  ) : (
    titleCase(cell)
  );
}

ControlledSelect.propTypes = {
  cell: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  columnName: PropTypes.string,
  moduleKey: PropTypes.string,
};

export function ControlledRestrictedLabelsSelect({
  cell,
  row,
  onChange,
  columnName,
  moduleKey = "",
}) {
  let isEdit = useSelector(getUserPermission(moduleKey));
  const { t } = useTranslation();
  const [statusValue, setStatusValue] = useState(cell);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setStatusValue(cell);
  }, [cell, row]);

  const resHandleChange = (val) => {
    setIsLoading(false);
    setStatusValue(val);
  };

  const handleChange = (val) => {
    setIsLoading(true);
    onChange(val, row, resHandleChange, columnName);
  };

  if (!["pending", "true", "false"].includes(cell)) {
    return cell;
  }

  let className = "";
  if (statusValue === "true") {
    className = "text-success";
  } else if (statusValue === "pending") {
    className = "text-warning";
  } else if (statusValue === "false") {
    className = "text-danger";
  }

  return (
    <>
      {" "}
      {isEdit ? (
        <Select
          defaultValue={cell}
          value={statusValue}
          onChange={handleChange}
          loading={isLoading}
          className={`${className}`}
        >
          <Select.Option value="true">{t("common.true")}</Select.Option>
          <Select.Option value="false">{t("common.false")}</Select.Option>
          <Select.Option value="pending">{t("common.pending")}</Select.Option>
        </Select>
      ) : (
        titleCase(cell)
      )}
    </>
  );
}

ControlledRestrictedLabelsSelect.propTypes = {
  cell: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  columnName: PropTypes.string,
  moduleKey: PropTypes.string,
};

export function ControlledSwitch({
  cell,
  row,
  onChange,
  columnName,
  moduleKey = "",
}) {
  let isEdit = useSelector(getUserPermission(moduleKey));
  const [isChecked, setIsChecked] = useState(
    ["active", "true", 1].includes(cell)
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsChecked(["active", "true", 1, "1"].includes(cell));
  }, [cell, row]);

  const resHandleChange = (val) => {
    setIsLoading(false);
    setIsChecked(["active", "true", 1].includes(val));
  };

  const handleChange = (val) => {
    setIsLoading(true);
    onChange(val, row, resHandleChange, columnName);
  };

  if (!["active", "inactive", "true", "false", 0, 1, "1", "0"].includes(cell)) {
    return cell;
  }

  return (
    <>
      {isEdit ? (
        <Switch
          defaultChecked={["active", "true", 1, "1"].includes(cell)}
          loading={isLoading}
          checked={isChecked}
          onChange={handleChange}
        />
      ) : (
        titleCase(cell)
      )}
    </>
  );
}

ControlledSwitch.propTypes = {
  cell: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  columnName: PropTypes.string,
  moduleKey: PropTypes.string,
};

export function WinnerTypesControlledSelect({
  cell,
  row,
  contestData,
  onChange,
}) {
  const [statusValue, setStatusValue] = useState(cell);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(
    ["gold", "silver", "bronze"].includes(cell)
  );

  useEffect(() => {
    setStatusValue(cell);
    setIsDisabled(["gold", "silver", "bronze"].includes(cell));
  }, [cell, row]);

  const resHandleChange = (val) => {
    setIsLoading(false);
    setStatusValue(val);
    setIsDisabled(true);
  };

  const handleChange = (val) => {
    setIsLoading(true);
    onChange(val, row, contestData, resHandleChange);
  };

  return (
    <>
      {" "}
      {cell ? (
        <span className="text-capitalize">{cell}</span>
      ) : (
        <Select
          defaultValue={cell}
          disabled={isDisabled}
          value={statusValue}
          onChange={handleChange}
          loading={isLoading}
          placeholder="Is Winner"
        >
          {contestData.ContestWinnerTypes.length
            ? contestData.ContestWinnerTypes.map((data) => {
                return (
                  <Select.Option
                    key={JSON.stringify(data)}
                    className="text-capitalize"
                    value={data.id}
                  >
                    <span className="text-capitalize">{data.winnerType}</span>{" "}
                  </Select.Option>
                );
              })
            : "-"}
        </Select>
      )}
    </>
  );
}

WinnerTypesControlledSelect.propTypes = {
  cell: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  contestData: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

export const DefaultOptionsModalConfirm = {
  render: (message, onConfirm, onCancel) => {
    return (
      <div className="confirm-box__content">
        <span> {message} </span>
        <div className="confirm-box__actions">
          <button
            className="ant-btn btn btn-primary ripple-effect"
            onClick={onConfirm}
          >
            {" "}
            Yes{" "}
          </button>
          <button
            className="ant-btn btn btn-outline-dark ripple-effect"
            onClick={onCancel}
          >
            {" "}
            No{" "}
          </button>
        </div>
      </div>
    );
  },
};
