import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Form, Input, Select, Button, Alert } from "antd";
import PropTypes from "prop-types";

import { selectArtists, selectAlbums } from "../../redux/common/commonSlice";
import ApiEndPoints from "../../utilities/apiEndPoints";
import validation from "../../utilities/validation";
import { LoadingSpinner } from "../common";
import UploadMedia from "../uploadMedia";

export default function TrackForm({
  form,
  onCancel,
  isSpin,
  onFinish,
  onFinishFailed,
  onFileUploaded,
  onFileRemoved,
  fullPathUploadedFile,
  onAudioFileUploaded,
  initialValues = {},
  media = [],
  submitButtonText,
  errorMsg,
  isEditFormType,
}) {
  const { t } = useTranslation();
  const artists = useSelector(selectArtists);
  const albums = useSelector(selectAlbums);
  const [audioSource, setAudioSource] = useState("");
  const { Option } = Select;
  return (
    <>
      {errorMsg && <Alert message={errorMsg} className="mb-4" type="error" />}
      <Form
        name="trackAddEdit"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        <div className="form-group">
          <label>{t("track.name")}</label>
          <Form.Item name="title" rules={validation.track.title}>
            <Input className="form-control" placeholder={t("track.name")} />
          </Form.Item>
        </div>
        <div className="form-group">
          <label>{t("album.name")} </label>
          <Form.Item name="albumId" rules={validation.track.albumId}>
            <Select className="form-control" placeholder={t("album.name")}>
              {albums.map((album) => {
                return (
                  <Option key={`${album.id}`} value={album.id}>
                    {album.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div className="form-group">
          <label>{t("track.uploadTrack")}</label>
          <Form.Item
            name="length"
            rules={validation.track.length}
            className="hide-track-length"
          >
            <Input hidden placeholder="Track Length" />
          </Form.Item>
          <Form.Item name="mediaFile" rules={validation.track.mediaFile}>
            <UploadMedia
              actionURL={ApiEndPoints.media.trackAudio}
              onFileUploaded={onFileUploaded}
              fullPathUploadedFile={fullPathUploadedFile}
              onAudioFileUploaded={onAudioFileUploaded}
              onFileRemoved={onFileRemoved}
              media={media}
              mediaType="audio"
              verifyAudioFileLength={true}
            />
          </Form.Item>
        </div>
        <div className="form-group">
          <label>{t("artist.names")} </label>
          <Form.Item name="artistId" rules={validation.track.artistId}>
            <Select
              className="form-control"
              mode="multiple"
              placeholder={t("artist.names")}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {artists.map((data) => {
                return (
                  <Option
                    key={`${data.id}`}
                    disabled={data.status === "inactive"}
                    className="text-capitalize"
                    value={data.id}
                  >
                    {data.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div className="form-group">
          <label>{t("common.status")} </label>
          <Form.Item name="status">
            <Select className="form-control" placeholder={t("common.status")}>
              <Option value="active">{t("common.active")}</Option>
              <Option value="inactive">{t("common.inactive")}</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="form-group">
          <label>{t("audio.audioSource")} </label>
          <Form.Item name="audioSource">
            <Select
              className="form-control"
              placeholder={t("audio.audioSource")}
              onChange={(e) => {
                setAudioSource(e);
              }}
            >
              {isEditFormType ? (
                <>
                  <Option value="fytness">{t("common.sourceFytness")}</Option>
                </>
              ) : (
                <>
                  <Option value="fytness">{t("common.sourceFytness")}</Option>
                  <Option value="custom">{t("common.custom")}</Option>
                </>
              )}
            </Select>
          </Form.Item>
        </div>
        {audioSource === "songtradr" && (
          <div className="form-group">
            <label>{t("track.masterId")}</label>
            <Form.Item name="masterId" rules={validation.track.masterId}>
              <Input
                className="form-control"
                placeholder={t("track.masterId")}
              />
            </Form.Item>
          </div>
        )}
        <div className="form-group btn-row text-center mb-0">
          <Form.Item>
            <Button
              disabled={isSpin}
              htmlType="submit"
              className="btn btn-primary width-120 ripple-effect text-uppercase"
            >
              {isSpin ? <LoadingSpinner /> : submitButtonText}
            </Button>
            <Button
              htmlType="button"
              onClick={onCancel}
              className="btn btn-outline-dark width-120 ripple-effect text-uppercase"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

TrackForm.propTypes = {
  form: PropTypes.any.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSpin: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
  onFileRemoved: PropTypes.func.isRequired,
  fullPathUploadedFile: PropTypes.func.isRequired,
  onAudioFileUploaded: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  media: PropTypes.array.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  errorMsg: PropTypes.string.isRequired,
  isEditFormType: PropTypes.string.isRequired,
};
