import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import PropTypes from "prop-types";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import { audioURLReader } from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";
import TrackForm from "../forms/track";

export default function TrackAddEdit(props) {
  const { t } = useTranslation();
  const [isSpin, setIsSpin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isEditFormType, setIsEditFormType] = useState(
    Object.keys(props.data).length > 0
  );
  const [form] = Form.useForm();

  useEffect(() => {
    const isEditFormType = Object.keys(props.data).length > 0;
    if (props.show && isEditFormType) {
      const artistId = [];
      for (const element of props.data.AudioArtists) {
        artistId.push(element.Artist.id);
      }

      form.setFieldsValue({
        title: props.data.title,
        status: props.data.status,
        audioSource: props.data.audioSource,
        masterId: props.data.masterId,
        mediaFile: props.data.mediaFile,
        length: props.data.length,
        artistId: artistId,
        albumId: props.data.Album.id,
      });
    } else {
      form.setFieldsValue({
        title: "",
        status: "active",
        audioSource: "fytness",
        mediaFile: "",
        masterId: "",
        length: "",
        artistId: [],
        albumId: "",
      });
    }
    setIsEditFormType(isEditFormType);
    setErrorMsg("");
    setIsSpin(false);
  }, [props.show, props.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAudioFileUploaded = ({ baseUrl, basePath, length }) => {
    const formPayload = {
      mediaFile: basePath,
    };
    if ([0, "", undefined, false, null].includes(length)) {
      fullPathUploadedFile(baseUrl);
    } else {
      formPayload.length = length;
    }
    form.setFieldsValue(formPayload);
  };

  const onFileUploaded = (mediaFile) => {
    form.setFieldsValue({
      mediaFile,
    });
  };

  const fullPathUploadedFile = async (url) => {
    const length = await audioURLReader(url);
    form.setFieldsValue({
      length,
    });
  };

  const onFileRemoved = () => {
    form.setFieldsValue({
      mediaFile: "",
    });
  };

  const onFinish = async (values) => {
    setIsSpin(true);
    setErrorMsg("");
    try {
      let payload = {
        ...ApiEndPoints.addTrack,
        bodyData: {
          albumId: values.albumId,
          title: values.title,
          mediaFile: values.mediaFile,
          length: values.length,
          artistId: values.artistId,
          status: values.status,
          audioSource: values.audioSource,
          masterId: values.masterId,
        },
      };

      if (isEditFormType) {
        payload = {
          ...payload,
          ...ApiEndPoints.editTrack(props.data.id),
        };
      }

      const res = await APIrequest(payload);
      setIsSpin(false);
      modalNotification({
        type: "success",
        message: "Success",
        description:
          res.message ||
          (isEditFormType
            ? textMessages.updateTrackMessage
            : textMessages.addTrackMessage),
      });
      props.onSubmitSuccess();
      props.onHide();
    } catch (error) {
      setIsSpin(false);
      setErrorMsg(error.message);
      logger({ "error:": error });
    }
  };

  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  const onCancel = () => {
    form.resetFields();
    props.onHide();
  };

  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-dialog-scrollable"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{isEditFormType ? t("track.edit") : t("track.add")}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TrackForm
          form={form}
          onCancel={onCancel}
          isSpin={isSpin}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onFileUploaded={onFileUploaded}
          fullPathUploadedFile={fullPathUploadedFile}
          onAudioFileUploaded={onAudioFileUploaded}
          onFileRemoved={onFileRemoved}
          media={isEditFormType ? [props.data.mediaFileUrl] : []}
          initialValues={
            isEditFormType
              ? {}
              : {
                  status: "active",
                }
          }
          submitButtonText={
            isEditFormType ? t("common.update") : t("common.save")
          }
          isEditFormType={isEditFormType}
          errorMsg={errorMsg}
        />
      </Modal.Body>
    </Modal>
  );
}

TrackAddEdit.propTypes = {
  data: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  // albumId: PropTypes.number.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};
