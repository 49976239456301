import { GoToViewTodayEventDetail } from "../../components/common";
import { dateFormatter, nameWithImageFormatter } from "../../utilities/common";
import { getColumnsState, getInitialState } from "../../utilities/defaultState";

export const getInitialStateEvents = ({
  props,
  dataFieldColumns,
  customSerialNumberFormatter,
  customStatusFormatter,
  defaultSorted,
}) => {
  const initialState = getInitialState({
    props,
    dataFieldColumns,
    customSerialNumberFormatter,
    customStatusFormatter,
    defaultSorted,
  });

  const { columns: initialColumns, ...defaultState } = initialState;

  const localColumns = [
    {
      dataField: "title",
      text: props.t("event.event"),
      headerAlign: "left",
      align: "left",
      formatter: eventFormatter,
    },
    {
      dataField: "eventType",
      text: props.t("event.type"),
      headerAlign: "left",
      align: "left",
      sort: true,
    },
    {
      dataField: "User.username",
      text: props.t("event.creator"),
      headerAlign: "left",
      align: "left",
      sort: true,
      formatter: (cell, row) => nameWithImageFormatter(cell, row.User),
    },

    {
      dataField: "eventStartDateTime",
      text: props.t("event.dateAndTime"),
      headerAlign: "left",
      align: "left",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "eventEndDateTime",
      text: props.t("event.dateAndTime"),
      headerAlign: "left",
      align: "left",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "isDummyField",
      text: props.t("common.action"),
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => actionFormatter(cell, row.User, props),
    },
  ];

  const columns = getColumnsState({
    initialColumns,
    dataFieldColumns,
    localColumns,
  });

  const state = {
    ...defaultState,
    columns,
  };

  return state;
};

const eventFormatter = (_cell, row) => {
  let badgeClass =
    row.eventType === "online"
      ? "badge badge-sm badge-dot has-bg badge-success d-mb-inline-flex"
      : "badge badge-sm badge-dot has-bg badge-danger d-mb-inline-flex";
  return (
    <>
      <span className={badgeClass}>{row.eventType}</span>
    </>
  );
};

const actionFormatter = (_cell, row, props) => {
  const { t } = props;
  return (
    <>
      <div className="dropdown">
        <a
          href="/"
          onClick={(e) => e.preventDefault()}
          className="dropdown-toggle"
          id={`dropdownMenuButton_${row.id}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="ni ni-more-h"></span>
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <GoToViewTodayEventDetail
            data={t("event.viewDetails")}
            id={row.id}
            classProps="dropdown-item"
          />
        </div>
      </div>
    </>
  );
};
