import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { tagClassToggle } from "../../utilities/common";
import { sidebarJSON } from "../../utilities/sidebar";

const Sidebar = ({ match, userType, userData }) => {
  const { t } = useTranslation();

  const path = match.path;

  const toggleDrawerMenu = () => {
    const element = document.getElementsByTagName("body")[0];
    if (element.classList.contains("menu-open")) {
      tagClassToggle("body", "menu-open");
    }
  };

  return (
    <>
      <div
        className="overlay-screen"
        onClick={(e) => {
          e.preventDefault();
          toggleDrawerMenu();
        }}
      ></div>
      <aside className="c-sidemenu" id="sidemenu">
        <div className="c-sidemenu__wrapper mCustomScrollbar">
          <ul id="sideSubMenu" className="nav flex-column">
            {sidebarJSON(t, userData.permissions).map((data) => {
              if (data.userTypes.includes(userType) && data.addInSideBar) {
                if (data.child && data.child.length > 0) {
                  return (
                    <li
                      key={JSON.stringify(data)}
                      className={`${
                        data.active.includes(path) ? "showMenu" : ""
                      }`}
                    >
                      <Link
                        to="#"
                        className={`nav-link nav__link ripple-effect subMenu 
                           ${data.active.includes(path) ? "" : "collapse"}
                          `}
                        data-toggle="collapse"
                        data-target={`#${data.endPoint}`}
                        role="button"
                        aria-expanded="false"
                        aria-controls={data.endPoint}
                      >
                        <span className="nav__icon">
                          <i className={data.icon}></i>
                        </span>
                        <span className="nav__title">{data.title}</span>
                      </Link>
                      <ul
                        className={`collapse list-unstyled  in-collapse nav__submenu  ${
                          data.active.includes(path) ? "show" : ""
                        }`}
                        // class='collapse'
                        id={data.endPoint}
                      >
                        {data.child.map((childData) => {
                          if (
                            childData.userTypes.includes(userType) &&
                            childData.addInSideBar
                          ) {
                            return (
                              <li
                                key={`${JSON.stringify(childData)}`}
                                className={
                                  childData.active.includes(path)
                                    ? "active"
                                    : ""
                                }
                              >
                                <Link
                                  className="nav-link"
                                  to={childData.endPoint}
                                  onClick={(e) => {
                                    toggleDrawerMenu();
                                  }}
                                >
                                  <span className="nav__title">
                                    {childData.title}
                                  </span>
                                </Link>
                              </li>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </ul>
                    </li>
                  );
                } else if (userType === "admin") {
                  return (
                    <li
                      key={`${data.title}_${JSON.stringify(data)}`}
                      className={data.active.includes(path) ? "active" : ""}
                    >
                      <Link
                        className="nav-link nav__link ripple-effect"
                        to={data.endPoint}
                        onClick={() => {
                          toggleDrawerMenu();
                        }}
                      >
                        <span className="nav__icon">
                          <i className={data.icon}></i>
                        </span>
                        <span className="nav__title">{data.title}</span>
                      </Link>
                    </li>
                  );
                } else if (userData.permissions) {
                  const moduleKeyIndex = userData.permissions.findIndex(
                    (item) => {
                      return item.moduleKey === data.moduleKey;
                    }
                  );
                  if (moduleKeyIndex > -1) {
                    return (
                      <li
                        key={JSON.stringify(data)}
                        className={data.active.includes(path) ? "active" : ""}
                      >
                        <Link
                          className="nav-link nav__link ripple-effect"
                          to={data.endPoint}
                          onClick={() => {
                            toggleDrawerMenu();
                          }}
                        >
                          <span className="nav__icon">
                            <i className={data.icon}></i>
                          </span>
                          <span className="nav__title">{data.title}</span>
                        </Link>
                      </li>
                    );
                  }
                }
              }
              return <li key={JSON.stringify(data)}></li>;
            })}
          </ul>
        </div>
      </aside>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cmsData: state.cms.cmsData,
    userType: state.auth.userData.userType || "",
    userData: state.auth.userData || "",
  };
};

Sidebar.propTypes = {
  match: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  cmsData: PropTypes.array.isRequired,
};

export default withRouter(connect(mapStateToProps, null)(Sidebar));
