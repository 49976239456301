import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import PropTypes from "prop-types";

import { GlobalLoader } from "../components/common";
import { logoutAction } from "../redux/auth/authSlice";
import { getSessionStorageToken } from "./common";
import modalNotification from "./notifications";
import {
  redirectPathIfRequireAuthFails,
  redirectPathIfNotRequireAuthFails,
} from "./routes";
import { sidebarJSON as routesJSON } from "./sidebar";

function WithAuthFn(ComposedComponent) {
  class WithAuth extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        isAuthenticate: false,
      };
    }

    componentDidMount() {
      this.checkAuth();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.auth.isLoggedIn !== this.props.auth.isLoggedIn) {
        this.setState(
          {
            isAuthenticate: false,
          },
          () => {
            this.checkAuth();
          }
        );
      }
    }

    checkAuth = () => {
      const isLoggedInProp = this.props.auth.isLoggedIn;
      const userType = this.props.auth.userData.userType;
      const path = this.props.match.path;
      const routePaths = this.getRouteArray(true, userType);
      const notRequireAuthPaths = this.getRouteArray(false, userType);

      if (routePaths.includes(path) && !isLoggedInProp) {
        this.props.history.push({
          pathname: redirectPathIfRequireAuthFails,
        });
      } else if (
        (notRequireAuthPaths.includes(path) || !routePaths.includes(path)) &&
        isLoggedInProp
      ) {
        if (getSessionStorageToken()) {
          this.props.history.push({
            pathname: this.redirectOn(userType),
          });
        } else {
          modalNotification({
            type: "error",
            message: "Logout",
            description: "Session Expired",
          });
          this.props.logoutRedux();
        }
      } else {
        this.setState({
          isAuthenticate: true,
        });
      }
    };

    getRouteArray = (authReq, type = "all") => {
      const routes = routesJSON(
        (arg) => arg,
        this.props.auth.userData.permissions
      );
      let pathArr = [];

      for (const element of routes) {
        if (element.child && element.child.length > 0) {
          for (const elementJ of element.child) {
            if (
              elementJ.userTypes &&
              (elementJ.userTypes.includes(type) || type === "all") &&
              elementJ.authRequire === authReq
            ) {
              pathArr.push(elementJ.endPoint);
            }
          }
        } else if (
          element.userTypes &&
          (element.userTypes.includes(type) || type === "all") &&
          element.authRequire === authReq
        ) {
          pathArr.push(element.endPoint);
        }
      }
      return pathArr;
    };

    redirectOn = (type) => {
      let redirectOn = "";
      for (const element of redirectPathIfNotRequireAuthFails) {
        if (element.userTypes && element.userTypes.includes(type)) {
          redirectOn = element.path;
        }
      }
      return redirectOn;
    };

    render() {
      const { isAuthenticate } = this.state;

      if (isAuthenticate) {
        return (
          <>
            <ComposedComponent {...this.props} />
          </>
        );
      }

      return <GlobalLoader />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      auth: state.auth,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      logoutRedux: (res) => dispatch(logoutAction(res)),
    };
  };

  WithAuth.propTypes = {
    auth: PropTypes.object.isRequired,
    logoutRedux: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(WithAuth));
}

export default WithAuthFn;
