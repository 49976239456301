// import socketIOClient from 'socket.io-client'
import config from "../config";

const ApiEndPoints = {
  /**
   * Account
   */
  accountLogin: {
    url: "/admin/login",
    method: "POST",
  },
  accountChangePassword: {
    url: "/admin/change-password",
    method: "POST",
  },
  accountForgotPassword: {
    url: "/admin/forgot-password",
    method: "POST",
  },
  accountResetPassword: {
    url: "/admin/reset-password",
    method: "POST",
  },
  accountLogout: {
    url: "/account/logout",
    method: "POST",
  },
  /**
   * Dashboard
   */
  dashboard: {
    url: "/admin/dashboard",
    method: "GET",
  },
  /**
   * Album
   */
  addAlbum: {
    url: "/admin/album",
    method: "POST",
  },
  getAlbum: {
    url: "/admin/album",
    method: "GET",
  },
  getSpecificAlbum: (id) => ({
    url: `/admin/album/${id}`,
    method: "GET",
  }),
  editAlbum: (id) => ({
    url: `/admin/album/${id}`,
    method: "PUT",
  }),
  updateStatusAlbum: (id) => ({
    url: `/admin/album/${id}/change-status`,
    method: "PUT",
  }),
  /**
   * college
   */
  addCollege: {
    url: "/admin/collage",
    method: "POST",
  },
  getCollege: {
    url: "/admin/collage",
    method: "GET",
  },
  editCollege: (id) => ({
    url: `/admin/collage/${id}`,
    method: "PUT",
  }),
  updateStatusCollege: (id) => ({
    url: `/admin/collage/${id}/change-status`,
    method: "PUT",
  }),
  /**
   * Restricted Labels
   */

  getRestrictedLabels: {
    url: "/admin/restricted-labels",
    method: "GET",
  },
  updateStatusRestrictedLabels: (id) => ({
    url: `/admin/restricted-labels/${id}/change-status`,
    method: "PUT",
  }),
  /**
   * college mates
   */
  addCollegeMates: {
    url: "/admin/collage-mates",
    method: "POST",
  },
  getCollegeMates: {
    url: "/admin/collage-mates",
    method: "GET",
  },

  editCollegeMates: (id) => ({
    url: `/admin/collage-mates/${id}`,
    method: "PUT",
  }),
  updateStatusCollegeMates: (id) => ({
    url: `/admin/collage-mates/${id}/change-status`,
    method: "PUT",
  }),
  /**
   * Artist
   */
  addArtist: {
    url: "/admin/artist",
    method: "POST",
  },
  getArtist: {
    url: "/admin/artist",
    method: "GET",
  },
  editArtist: (id) => ({
    url: `/admin/artist/${id}`,
    method: "PUT",
  }),
  updateStatusArtist: (id) => ({
    url: `/admin/artist/${id}/change-status`,
    method: "PUT",
  }),
  /**
   * Approver
   */
  addApprover: {
    url: "/admin/approver",
    method: "POST",
  },
  editApprover: {
    url: "/admin/approver",
    method: "PUT",
  },
  getApprover: {
    url: "/admin/approver",
    method: "GET",
  },
  addApproverPreferences: {
    url: "/admin/search-preference",
    method: "POST",
  },
  /**
   * Category
   */
  addCategory: {
    url: "/admin/category",
    method: "POST",
  },
  getCategory: {
    url: "/admin/category",
    method: "GET",
  },
  getAllCategory: {
    url: "/admin/category",
    method: "GET",
  },
  editCategory: (id) => ({
    url: `/admin/category/${id}`,
    method: "PUT",
  }),
  updateStatusCategory: (id) => ({
    url: `/admin/category/${id}/change-status`,
    method: "PUT",
  }),
  /**
   * city /state
   */

  getStates: {
    url: "/state",
    method: "GET",
  },
  getCities: (id) => ({
    url: `/state/${id}/city`,
    method: "Get",
  }),

  /**
   * Role
   */
  addRole: {
    url: "/admin/role/permission",
    method: "POST",
  },
  getRole: {
    url: "/admin/role/permission",
    method: "GET",
  },
  getSpecificRole: (id) => ({
    url: `/admin/role/permission/${id}`,
    method: "GET",
  }),
  deleteRole: (id) => ({
    url: `/admin/role/${id}`,
    method: "DELETE",
  }),
  /**
   * Collection
   */
  addCollection: {
    url: "/admin/collection",
    method: "POST",
  },
  addTrackToCollection: {
    url: "/admin/track/collection",
    method: "POST",
  },
  addTrackFromCollection: {
    url: "/admin/manage-collection/track",
    method: "POST",
  },
  deleteTrackFromCollection: {
    url: "/admin/manage-track/collection",
    method: "DELETE",
  },
  getCollection: {
    url: "/admin/collection",
    method: "GET",
  },
  getSpecificCollection: (id) => ({
    url: `/admin/collection/${id}`,
    method: "GET",
  }),
  editCollection: (id) => ({
    url: `/admin/collection/${id}`,
    method: "PUT",
  }),
  updateStatusCollection: (id) => ({
    url: `/admin/collection/${id}/change-status`,
    method: "PUT",
  }),
  updateOrderCollection: {
    url: "/admin/collection/order/change",
    method: "PUT",
  },

  /**
   * Reported Reasons
   */
  getReportedReasons: {
    url: "/admin/report-reason",
    method: "GET",
  },
  addReportedReason: {
    url: "/admin/report-reason",
    method: "POST",
  },
  editReportedReason: (id) => ({
    url: `/admin/report-reason/${id}`,
    method: "PUT",
  }),
  deleteReportedReason: (id) => ({
    url: `/admin/report-reason/${id}`,
    method: "DELETE",
  }),
  reportedReasonChangeStatus: (id) => ({
    url: `/admin/report-reason-change-status/${id}`,
    method: "PUT",
  }),

  /**
   * Reported Reasons end
   */

  /**
   * Report Video Reason
   */
  getReportVideoReasons: {
    url: "/admin/report-video-reason",
    method: "GET",
  },
  addReportVideoReason: {
    url: "/admin/report-video-reason",
    method: "POST",
  },
  editReportVideoReason: (id) => ({
    url: `/admin/report-video-reason/${id}`,
    method: "PUT",
  }),
  deleteReportVideoReason: (id) => ({
    url: `/admin/report-video-reason/${id}`,
    method: "DELETE",
  }),
  reportVideoReasonChangeStatus: (id) => ({
    url: `/admin/report-video-reason-change-status/${id}`,
    method: "PUT",
  }),

  /**
   * Collection Feed
   */
  addCollectionFeed: {
    url: "/admin/feed-collection",
    method: "POST",
  },
  addFeedOnTopCollection: {
    url: "/admin/feed-collection/mark-top",
    method: "POST",
  },
  addFeedToCollection: {
    url: "/admin/feed/feed-collection",
    method: "POST",
  },
  addFeedFromCollection: {
    url: "/admin/manage-collection/feed",
    method: "POST",
  },
  deleteFeedFromCollection: {
    url: "/admin/manage-feed/feed-collection",
    method: "DELETE",
  },
  getCollectionFeed: {
    url: "/admin/feed-collection",
    method: "GET",
  },
  getSpecificCollectionFeed: (id) => ({
    url: `/admin/feed-collection/${id}`,
    method: "GET",
  }),
  editCollectionFeed: (id) => ({
    url: `/admin/feed-collection/${id}`,
    method: "PUT",
  }),
  updateStatusCollectionFeed: (id) => ({
    url: `/admin/feed-collection/${id}/change-status`,
    method: "PUT",
  }),
  updateOrderCollectionFeed: {
    url: "/admin/feed-collection/order/change",
    method: "PUT",
  },
  updateOrderCollectionMultiFeed: {
    url: "/admin/collection-feed-order",
    method: "PUT",
  },
  /**
   * Tag
   */
  addTag: {
    url: "/admin/tag",
    method: "POST",
  },
  getTag: {
    url: "/admin/tag",
    method: "GET",
  },
  editTag: (id) => ({
    url: `/admin/tag/${id}`,
    method: "PUT",
  }),
  updateStatusTag: (id) => ({
    url: `/admin/tag/${id}/change-status`,
    method: "PUT",
  }),
  /**
   * Track
   */
  addTrack: {
    url: "/admin/audio-track",
    method: "POST",
  },
  getTrack: {
    url: "/admin/audio-track",
    method: "GET",
  },
  getTrackList: {
    url: "/admin/audio-track/list",
    method: "GET",
  },
  editTrack: (id) => ({
    url: `/admin/audio-track/${id}`,
    method: "PUT",
  }),
  updateStatusTrack: (id) => ({
    url: `/admin/audio-track/${id}/change-status`,
    method: "PUT",
  }),
  /**
   * CMS
   */
  getCms: {
    url: "/admin/cms",
    method: "GET",
  },
  getSpecificCms: (id) => ({
    url: `/admin/cms/${id}`,
    method: "GET",
  }),
  updateSpecificCms: (id) => ({
    url: `/admin/cms/${id}`,
    method: "PUT",
  }),
  /**
   * FAQ
   */
  addFaq: {
    url: "/admin/faq",
    method: "POST",
  },
  getFaq: {
    url: "/admin/faq",
    method: "GET",
  },
  getSpecificFaq: (id) => ({
    url: `/admin/faq/${id}`,
    method: "GET",
  }),
  updateSpecificFaq: (id) => ({
    url: `/admin/faq/${id}`,
    method: "PUT",
  }),
  deleteSpecificFaq: (id) => ({
    url: `/admin/faq/${id}`,
    method: "DELETE",
  }),
  /**
   * User
   */
  getUserGroupsList: {
    url: "/user/group",
    method: "GET",
  },
  getUser: {
    url: "/admin/user",
    method: "GET",
  },
  getSpecificUser: (id) => ({
    url: `/admin/user/${id}`,
    method: "GET",
  }),
  updateStatusUser: (id) => ({
    url: `/admin/user/${id}/change-status`,
    method: "PUT",
  }),
  assignUsername: (id) => ({
    url: `/admin/user/${id}/assign-username`,
    method: "PUT",
  }),
  updateUserTag: {
    url: "/admin/update/user-tag",
    method: "PUT",
  },
  /**
   * Feed
   */
  getFeed: {
    url: "/admin/feed",
    method: "GET",
  },
  getUserFeed: {
    url: "/admin/feed/user",
    method: "GET",
  },
  getSpecificUserFeed: (id) => ({
    url: `/admin/feed/${id}`,
    method: "GET",
  }),
  getSpecificFeed: (id) => ({
    url: `/admin/feed/detail/${id}`,
    method: "GET",
  }),
  updateStatusFeed: (id) => ({
    url: `/admin/feed/${id}/change-status`,
    method: "PUT",
  }),
  updateStatusContestFeed: (id) => ({
    url: `/admin/contest-feed/${id}`,
    method: "PUT",
  }),
  updateContestFeedOrder: (id) => ({
    url: `/admin/contest-feed-order/${id}`,
    method: "PUT",
  }),

  /**
   * Request
   */
  getRequest: {
    url: "/admin/support/request",
    method: "GET",
  },
  updateRequest: (id) => ({
    url: `/admin/support/request/${id}`,
    method: "PUT",
  }),
  userSendNotificationRequest: () => ({
    url: `/admin/user/notification`,
    method: "post",
  }),
  getEvent: {
    url: "/admin/event",
    method: "GET",
  },
  getSpecificEvent: (id) => ({
    url: `/event/${id}`,
    method: "GET",
  }),
  getSpecificEventUser: (id) => ({
    url: `/admin/event/${id}/user`,
    method: "GET",
  }),
  /**
   * Point
   */
  getPoint: {
    url: "/admin/point-setting",
    method: "GET",
  },
  getSettings: {
    url: "/admin/settings",
    method: "GET",
  },
  updateSettings: {
    url: "/admin/setting",
    method: "PUT",
  },
  updatePoint: {
    url: "/admin/point-setting",
    method: "PUT",
  },

  /**
   * Zing Short
   * */
  getZingShortList: {
    url: "/admin/zing-short/series",
    method: "GET",
  },
  /**
   * Reported Feed
   */
  getReportedFeed: {
    url: "/admin/report-feed",
    method: "GET",
  },
  getUsersReportedFeed: (id) => ({
    url: `/admin/report-feed/${id}/reported-by-list`,
    method: "GET",
  }),
  /**
   * Reported Feed
   */
  getReportedUser: {
    url: "/admin/report-user",
    method: "GET",
  },
  getUsersReportedUser: (id) => ({
    url: `/admin/report-user/${id}/reported-by-list`,
    method: "GET",
  }),
  /**
   * Interval
   */
  getInterval: {
    url: "/admin/interval-setting",
    method: "GET",
  },
  addInterval: {
    url: "/admin/interval-setting",
    method: "POST",
  },
  updateSpecificInterval: (id) => ({
    url: `/admin/interval-setting/${id}`,
    method: "PUT",
  }),
  deleteSpecificInterval: (id) => ({
    url: `/admin/interval-setting/${id}`,
    method: "DELETE",
  }),
  updateStatusInterval: (id) => ({
    url: `/admin/interval-setting/${id}/change-status`,
    method: "PUT",
  }),
  /**
   * Feedback
   */
  getFeedback: {
    url: "/admin/feedback",
    method: "GET",
  },
  /**
   * Notification
   */
  getNotification: {
    url: "/admin/notification",
    method: "GET",
  },
  getUnreadAdminNotification: {
    url: "/admin/unread-notification",
    method: "GET",
  },
  markReadAdminNotification: {
    url: "/admin/mark-notification-read",
    method: "GET",
  },
  postNotification: {
    url: "/admin/topic/notification",
    method: "POST",
  },
  deleteNotification: (id) => ({
    url: `/admin/notification/${id}`,
    method: "DELETE",
  }),
  /**
   * Language
   */
  getLanguage: {
    url: "/language",
    method: "GET",
  },
  /**
   * Video-Categories
   */
  getVideoCategories: {
    url: "/video-category",
    method: "GET",
  },
  /**
   * Contest
   */

  /**
   * Story BLock
   */
  getStoryblocksLibrary: {
    url: "/admin/storyblocks-library",
    method: "GET",
  },

  syncStoryblocksLibrary: {
    url: "/admin/sync-storyblocks-library",
    method: "GET",
  },

  getStoryblocksLibraryAudio: {
    url: "/admin/storyblocks-audio",
    method: "GET",
  },

  syncStoryblocksLibraryAudio: {
    url: "/admin/sync-storyblocks-audio",
    method: "GET",
  },

  addContest: {
    url: "/admin/contest",
    method: "POST",
  },
  getContest: {
    url: "/admin/contest",
    method: "GET",
  },
  getContestDetail: (id) => ({
    url: `/admin/contest/${id}`,
    method: "GET",
  }),
  getSpecificContest: (id) => ({
    url: `/contest/${id}/participants`,
    method: "GET",
  }),
  getSpecificContestFeed: (id) => ({
    url: `/user/contest/feed/${id}`,
    method: "GET",
  }),

  editContest: (id) => ({
    url: `/admin/contest/${id}`,
    method: "PUT",
  }),
  updateStatusContest: (id) => ({
    url: `/admin/contest/${id}/change-status`,
    method: "PUT",
  }),
  updateWinnerStatusContest: (id) => ({
    url: `/admin/contest/${id}/winner`,
    method: "PUT",
  }),

  getContestWinnerInfo: (contestId, userId) => ({
    url: `/admin/contest/${contestId}/winner/${userId}`,
    method: "GET",
  }),
  getShopifyProducts: {
    url: "/shopify-products",
    method: "GET",
  },
  getGroup: {
    url: "/admin/group",
    method: "GET",
  },
  getGroupById: (communityId) => ({
    url: `/admin/group/${communityId}`,
    method: "GET",
  }),
  updateStatusGroup: (communityId) => ({
    url: `/admin/group/${communityId}/status`,
    method: "PUT",
  }),
  getPostByGroupId: (communityId) => ({
    url: `/admin/group/${communityId}/post`,
    method: "GET",
  }),
  updatePostStatusGroup: (groupId, groupPostId) => ({
    url: `/admin/group/${groupId}/post/${groupPostId}/status`,
    method: "PUT",
  }),
  /**
   * Reserve Username
   */
  getReserveUsername: {
    url: "/admin/reserve-username",
    method: "GET",
  },
  getAllReserveUsername: {
    url: "/admin/reserve-username-all",
    method: "GET",
  },
  addReserveUsername: {
    url: "/admin/reserve-username",
    method: "POST",
  },
  editReserveUsername: (id) => ({
    url: `/admin/reserve-username/${id}`,
    method: "PUT",
  }),
  updateStatusReserveUsername: (id) => ({
    url: `/admin/reserve-username/${id}/change-status`,
    method: "PUT",
  }),
  deleteReserveUsername: (id) => ({
    url: `/admin/reserve-username/${id}`,
    method: "DELETE",
  }),
  /**
   * Media URL
   */
  media: {
    categoryImage: `${config.API_BASE_URL}/media/upload/category/image`,
    artistImage: `${config.API_BASE_URL}/media/upload/artist/image`,
    albumImage: `${config.API_BASE_URL}/media/upload/album/image`,
    trackAudio: `${config.API_BASE_URL}/media/upload/audio-track/audio`,
    contestImage: `${config.API_BASE_URL}/media/upload/contest/image`,
    contestPrizeImage: `${config.API_BASE_URL}/media/upload/contest/image`,
  },
  socket: {
    // client: socketIOClient(config.SOCKET_URL)
  },
};

export default ApiEndPoints;
