import React from "react";
import { useTranslation } from "react-i18next";

import { Form, Input, Button, Alert, Select } from "antd";
import PropTypes from "prop-types";

import { LoadingSpinner } from "../common";

export default function CollectionVideoOrderForm({
  onHide,
  isSpin,
  onFinish,
  onFinishFailed,
  initialValues,
  submitButtonText,
  totalRecords,
  errorMsg,
}) {
  const { t } = useTranslation();
  return (
    <>
      {errorMsg && <Alert message={errorMsg} className="mb-4" type="error" />}
      <Form
        name="collectionUpdateOrder"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        <div className="form-group">
          <label>{t("collectionVideo.name")}</label>
          <Form.Item name="title" disabled>
            <Input
              disabled
              readOnly
              className="form-control"
              placeholder={t("collectionVideo.name")}
            />
          </Form.Item>
        </div>
        <div className="form-group">
          <label>{t("common.currentListOrder")}</label>
          <Form.Item name="currentListOrder">
            <Select
              disabled
              className="form-control"
              placeholder={t("common.currentListOrder")}
            >
              <Select.Option value={0}>{0}</Select.Option>
              {[...Array(5)].map((data, index) => {
                return (
                  <Select.Option
                    key={`current_position__${index + 1}_di`}
                    value={5 - Number(index)}
                  >
                    {Number(index) + 1}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div className="form-group">
          <label>{t("common.topPosition")}</label>
          <Form.Item name="listOrder">
            <Select
              className="form-control"
              placeholder={t("common.topPosition")}
            >
              {[...Array(5)].map((data, index) => {
                return (
                  <Select.Option
                    key={`position__${index + 1}_di`}
                    value={5 - Number(index)}
                  >
                    {Number(index) + 1}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div className="form-group btn-row text-center mb-0">
          <Form.Item>
            <Button
              disabled={isSpin}
              htmlType="submit"
              className="btn btn-primary width-120 ripple-effect text-uppercase"
            >
              {isSpin ? <LoadingSpinner /> : submitButtonText}
            </Button>
            <Button
              htmlType="button"
              onClick={onHide}
              className="btn btn-outline-dark width-120 ripple-effect text-uppercase"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

CollectionVideoOrderForm.propTypes = {
  onHide: PropTypes.func.isRequired,
  isSpin: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  errorMsg: PropTypes.string.isRequired,
  totalRecords: PropTypes.any.isRequired,
};
