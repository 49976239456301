import React from "react";
import { useTranslation } from "react-i18next";

import { Form, Input, Select, Button, Alert } from "antd";
import PropTypes from "prop-types";

import validation from "../../utilities/validation";
import { LoadingSpinner } from "../common";

export default function ReserveUsername({
  onHide,
  isSpin,
  onFinish,
  onFinishFailed,
  initialValues = {},
  submitButtonText,
  errorMsg,
}) {
  const { t } = useTranslation();
  return (
    <>
      {errorMsg && <Alert message={errorMsg} className="mb-4" type="error" />}
      <Form
        name="usernameAddEdit"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        <div className="form-group">
          <label>{t("username.name")}</label>
          <Form.Item name="name" rules={validation.username.name}>
            <Input className="form-control" placeholder={t("username.name")} />
          </Form.Item>
        </div>
        <div className="form-group">
          <label>{t("common.status")} </label>
          <Form.Item name="status">
            <Select className="form-control" placeholder={t("common.status")}>
              <Select.Option value="active">{t("common.active")}</Select.Option>
              <Select.Option value="inactive">
                {t("common.inactive")}
              </Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="form-group btn-row text-center mb-0">
          <Form.Item>
            <Button
              disabled={isSpin}
              htmlType="submit"
              className="btn btn-primary width-120 ripple-effect text-uppercase"
            >
              {isSpin ? <LoadingSpinner /> : submitButtonText}
            </Button>
            <Button
              htmlType="button"
              onClick={onHide}
              className="btn btn-outline-dark width-120 ripple-effect text-uppercase"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

ReserveUsername.propTypes = {
  onHide: PropTypes.func.isRequired,
  isSpin: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  errorMsg: PropTypes.string.isRequired,
};
