import React, { PureComponent } from "react";
import ReactFileReader from "react-file-reader";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";

class CsvUpload extends PureComponent {
  constructor(props) {
    super(props);
    this.handleFiles = this.handleFiles.bind(this);
    this.state = {
      collectionId: this.props.collectionId,
    };
  }

  /**
   * Function to upload file
   */
  handleFiles = async (files) => {
    let fileType = files[0].name.split(".").pop();
    if (fileType === "csv") {
      let feedIds = [];
      const reader = new FileReader();
      reader.onload = async () => {
        //Split csv file data by new line so that we can skip first row which is header
        let jsonData = reader.result.split("\n");

        jsonData.forEach((element, index) => {
          if (index) {
            //Split csv file data by comma so that we will have column data
            const elementRaw = element.split(",");
            if (element) {
              let id = Number(elementRaw[0]);
              if (Number.isFinite(id)) {
                feedIds.push(id);
              }
            }
          }
        });

        try {
          if (feedIds.length > 0) {
            let payload = {
              ...ApiEndPoints.addFeedFromCollection,
              bodyData: {
                collectionId: this.state.collectionId,
                feedIds: feedIds,
              },
            };
            const res = await APIrequest(payload);
            modalNotification({
              type: "success",
              message: "Success",
              description: res.message,
            });
            this.props.reFetchCollectionFeed();
          } else {
            let errorMessage = textMessages.fileCsvLength;
            modalNotification({
              type: "error",
              message: "Error",
              description: errorMessage,
            });
            return false;
          }
        } catch (error) {
          logger({ "error:": error });
        }
      };
      reader.readAsText(files[0]);
    } else {
      let errorMessage = textMessages.filesAllowed("csv");
      modalNotification({
        type: "error",
        message: "Error",
        description: errorMessage,
      });
      return false;
    }
  };

  render() {
    const { t } = this.props;
    return (
      <ReactFileReader fileTypes={".csv"} handleFiles={this.handleFiles}>
        <button className="btn btn-sm btn-primary ripple-effect text-uppercase">
          {t("common.importCsv")}
        </button>
      </ReactFileReader>
    );
  }
}
export default CsvUpload;
