import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import { ImageComponent } from "../common";

export default function ArtistList(props) {
  const { t } = useTranslation();
  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      id="artistListModal"
      className="artistModal"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{t("artist.list")}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="comment-list">
        <ul className="list-inline mCustomScrollbar">
          {props.data.map((data) => {
            return (
              <li
                key={`${data.id}_${data.Artist.id}`}
                className="list-inline-items"
              >
                <div className="media align-items-center">
                  <div className="media__left">
                    <ImageComponent
                      src={data.Artist.iconThumbUrl}
                      imgprops={{
                        className: "img-fluid",
                      }}
                    />
                  </div>
                  <div className="media__body">
                    <h5 className="mt-0 text-capitalize">{data.Artist.name}</h5>
                    <p className="text-capitalize">{props.title}</p>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </Modal.Body>
    </Modal>
  );
}

ArtistList.propTypes = {
  data: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
