import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";
import CollectionManagementForm from "../forms/collectionManagement";

export default function CollectionManagement(props) {
  const { t } = useTranslation();
  const [isSpin, setIsSpin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    setErrorMsg("");
    setIsSpin(false);
  }, [props.show, props.data]);

  const onFinish = async (values) => {
    setIsSpin(true);
    setErrorMsg("");
    try {
      let payload = {
        ...ApiEndPoints.addTrackToCollection,
        bodyData: {
          trackId: props.data.id,
          collectionIds: values.collectionIds,
        },
      };

      if (props.for === "video") {
        payload = {
          ...ApiEndPoints.addFeedToCollection,
          bodyData: {
            feedId: props.data.id,
            collectionIds: values.collectionIds,
          },
        };
      }

      const res = await APIrequest(payload);
      setIsSpin(false);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || textMessages.collectionAssigned,
      });
      props.onSubmitSuccess();
      props.onHide();
    } catch (error) {
      setIsSpin(false);
      setErrorMsg(error.message);
      logger({ "error:": error });
    }
  };

  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  if (!props.show) {
    return <></>;
  }

  const collectionId = [];
  let collectionKey = "CollectionTracks";
  let innerCollectionKey = "Collection";

  if (props.for === "video") {
    collectionKey = "CollectionFeeds";
    innerCollectionKey = "FeedCollection";
  }

  if (props.data[collectionKey]) {
    for (const element of props.data[collectionKey]) {
      collectionId.push(element[innerCollectionKey].id);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{t("collection.title")}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CollectionManagementForm
          onHide={props.onHide}
          isSpin={isSpin}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            collectionIds: collectionId,
          }}
          submitButtonText={
            props.data[collectionKey].length > 0
              ? t("common.update")
              : t("common.save")
          }
          errorMsg={errorMsg}
          forForm={props.for}
        />
      </Modal.Body>
    </Modal>
  );
}

CollectionManagement.propTypes = {
  data: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  for: PropTypes.string,
};
