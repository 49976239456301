import React, { PureComponent } from "react";
import { confirm } from "react-confirm-box";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Badge, Button, Input, Form } from "antd";
import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import RemoteDataTable from "../../components/dataTable";
import DeactivateReason from "../../components/modals/deactivateReason";
import ReportedByList from "../../components/modals/reportedByList";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  serialNumberFormatter,
  videoFormatter,
  statusFormatter,
  reportedByCount,
  getPageSizeFromURL,
  addPageSizeInURL,
  nameWithImageFormatter,
  filterDataObj,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

const options = {
  render: (message, onConfirm, onCancel) => {
    return (
      <div className="confirm-box__content">
        <span> {message} </span>
        <div className="confirm-box__actions">
          <button
            className="ant-btn btn btn-primary ripple-effect"
            onClick={onConfirm}
          >
            {" "}
            Yes{" "}
          </button>
          <button
            className="ant-btn btn btn-outline-dark ripple-effect"
            onClick={onCancel}
          >
            {" "}
            No{" "}
          </button>
        </div>
      </div>
    );
  },
};

class ReportedVideos extends PureComponent {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isFirstTimeFetching: true,
      isLoading: true,
      showUserListId: "",
      showUserListModal: false,
      rowData: {},
      data: [],
      openFilter: false,
      filterCount: 0,
      totalSize: 0,
      page: 1,
      feedId: 0,
      status: "",
      showReason: false,
      showConfirm: false,
      isReasonLoading: false,
      sizePerPage: 10,
      defaultSorted: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      columns: [
        {
          dataField: "id",
          text: props.t("common.id"),
          sort: true,
          hidden: true,
        },
        {
          dataField: "isDummySno",
          text: props.t("common.sNo"),
          // sort: true,
          formatter: (cell, row, rowIndex) =>
            serialNumberFormatter(
              rowIndex,
              this.state.page,
              this.state.sizePerPage
            ),
        },
        {
          dataField: "Feed.User.username",
          text: props.t("reportedVideo.creator"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: (cell, row) => nameWithImageFormatter(cell, row.Feed.User),
        },
        {
          dataField: "Feed.mediaFileUrl",
          text: props.t("reportedVideo.video"),
          headerAlign: "left",
          align: "left",
          formatter: (cell, row) => videoFormatter(cell, row.Feed),
        },
        {
          dataField: "reportedByCount",
          text: props.t("reported.by"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: (cell, row) =>
            reportedByCount(cell, row, this.userListModalShowHide),
        },
        {
          dataField: "Feed.status",
          text: props.t("common.status"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: (cell, row) =>
            statusFormatter(cell, row, this.onchangeStatus),
        },
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchReportedFeed(queryParams);
        }
      );
    }
  };

  fetchReportedFeed = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
      ...this.state.filterData,
    };
    try {
      const payload = {
        ...ApiEndPoints.getReportedFeed,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  onchangeStatus = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }
      const result = await confirm(
        `Are you sure want to ${status} this video?`,
        options
      );
      if (result) {
        const payload = {
          ...ApiEndPoints.updateStatusFeed(row.Feed.id),
          bodyData: {
            status,
          },
        };
        const res = await APIrequest(payload);
        modalNotification({
          type: "success",
          message: "Success",
          description: res.message || textMessages.statusUpdate,
        });
        const dataTemp = this.state.data;
        const indexData = dataTemp.findIndex((d) => d.Feed.id === row.Feed.id);
        if (indexData > -1) {
          dataTemp[indexData].Feed.status = status;
        }
        this.setState({
          status: status,
          feedId: row.Feed.id,
          showConfirm: true,
        });
        resHandleChange(status);
      }
      resHandleChange(row.Feed.status);
    } catch (error) {
      resHandleChange(row.Feed.status);
      logger({ "error:": error });
    }
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        if (sortField === "Feed.User.name") {
          sortField = "name";
        }
        if (sortField === "Feed.User.username") {
          sortField = "username";
        }
        if (sortField === "Feed.status") {
          sortField = "status";
        }
        if (sortField === "isDummySno") {
          sortField = "id";
        }
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };

        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history, {
            tab: "video",
          });
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchReportedFeed(queryParams);
        }
      }
    } else {
      this.fetchReportedFeed(queryParams);
    }
  };

  userListModalShowHide = (row = {}) => {
    let id = "";
    if (Object.keys(row).length > 0) {
      id = row.feedId;
    }
    this.setState(
      {
        showUserListId: id,
      },
      () => {
        this.handleToggle("showUserListModal");
      }
    );
  };

  onFinish = async (values) => {
    const { filterData, filterCount } = filterDataObj(values);

    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData,
        filterCount,
      },
      () => {
        this.fetchReportedFeed();
        this.handleToggle("openFilter");
      }
    );
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  onReset = () => {
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData: {},
        filterCount: 0,
      },
      () => {
        this.formRef.current.resetFields();
        this.fetchReportedFeed();
      }
    );
  };
  hideReasonModal = () => {
    this.setState(
      {
        ...this.state,
        showReason: false,
        showConfirm: false,
        data: [],
        feedId: 0,
        status: "",
      },
      () => {
        this.fetchReportedFeed();
      }
    );
  };
  ReasonModalController = async (selectedReason = "") => {
    try {
      if (selectedReason) {
        this.setState({
          ...this.state,
          isReasonLoading: true,
        });
      }
      const status = this.state.status;
      const payload = {
        ...ApiEndPoints.updateStatusFeed(this.state.feedId),
        bodyData: {
          status,
          selectedReason,
        },
      };
      const res = await APIrequest(payload);
      if (res.success) {
        modalNotification({
          type: "success",
          message: "Success",
          description: res.message || textMessages.statusUpdate,
        });
        this.setState(
          {
            ...this.state,
            isReasonLoading: false,
            showReason: false,
            showConfirm: false,
          },
          () => {
            this.fetchReportedFeed();
          }
        );
      }
    } catch (error) {
      logger(error);
    }
  };

  render() {
    const {
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      showUserListId,
      showUserListModal,
      openFilter,
      filterCount,
    } = this.state;
    const { t } = this.props;
    const bredcrumbs = [
      {
        name: t("dashboard.title"),
        path: "/",
      },
      {
        name: t("reportedVideo.title"),
        path: "/reported-content",
      },
    ];

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("reportedVideo.title")}`}
          description={`${t("reportedVideo.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            {/* <!-- page title section start --> */}
            <div className="page-title-row page-title-btn d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title text-capitalize mb-lg-0">
                  {t("reportedVideo.title")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div
                  className={`filterForm ${
                    openFilter ? "filterForm--open" : ""
                  }`}
                >
                  <div className="filterForm__head">
                    <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleToggle("openFilter");
                      }}
                      id="filterClose"
                    >
                      <i className="icon-cross"></i>
                    </a>
                  </div>
                  <div className="filterForm__body">
                    <Form
                      name="categoryFilter"
                      className="form-inline"
                      ref={this.formRef}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      initialValues={{ status: "" }}
                    >
                      <div className="form-group">
                        <label>{t("reportedVideo.creator")}</label>
                        <Form.Item name="name">
                          <Input
                            className="form-control"
                            placeholder={t("reportedVideo.creator")}
                          />
                        </Form.Item>
                      </div>
                      <div className="btn_clumn d-flex justify-content-between">
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="btn btn-primary ripple-effect"
                          >
                            {t("common.search")}
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="button"
                            onClick={this.onReset}
                            className="btn btn-outline-dark ripple-effect"
                          >
                            {t("common.reset")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="btnBox d-block d-flex w-100">
                  <Badge count={filterCount}>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleToggle("openFilter");
                      }}
                      id="filter"
                      className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-3"
                    >
                      <i className="icon-filter-line"></i>
                    </a>
                  </Badge>
                </div>
              </div>
            </div>
            {/* <!-- page title section end --> */}
            <div className="custom-tabs">
              <div className="detail-wrap mt-2 mt-md-3">
                <div className="custom-tabs detail-ta bs">
                  <div className="tab-content mt-0">
                    <div className="tab-pane fade show active">
                      <div className="common-table">
                        <div className="table-responsive">
                          <RemoteDataTable
                            data={data}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange}
                            isFilter={false}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            loading={isLoading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ReportedByList
          id={Number(showUserListId)}
          type={"feed"}
          show={showUserListModal}
          onHide={() => this.userListModalShowHide()}
        />
        <DeactivateReason
          show={this.state.showReason}
          onHide={this.hideReasonModal}
          controller={this.ReasonModalController}
          loading={this.state.isReasonLoading}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    // languagesList: state.common.languages,
    // videoCategories: state.common.videoCategories,
    userData: state.auth.userData || "",
  };
};
ReportedVideos.propTypes = {
  // bredcrumbs: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(ReportedVideos))
);
