/* eslint-disable import-helpers/order-imports */
import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";

import RemoteDataTable from "../../components/dataTable";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  serialNumberFormatter,
  getPageSizeFromURL,
  addPageSizeInURL,
  checkUserPermission,
} from "../../utilities/common";

import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";

class ManageParticipantsListing extends PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isFirstTimeFetching: true,
      resetSelected: false,

      trackData: {},
      openFilter: false,
      isLoading: true,
      data: [],
      trackIds: [],
      filterData: {},
      showCollectionListData: [],
      showArtistListData: [],
      currentTrackName: "",
      filterCount: 0,
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      defaultSorted: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      columns: [
        {
          dataField: "id",
          text: props.t("common.id"),
          sort: true,
          hidden: true,
        },
        {
          dataField: "isDummySno",
          text: props.t("common.sNo"),

          formatter: (cell, row, rowIndex) =>
            serialNumberFormatter(
              rowIndex,
              this.state.page,
              this.state.sizePerPage
            ),
        },
        {
          dataField: "User.username",
          text: props.t("common.username"),
          headerAlign: "left",
          align: "left",
          sort: true,
          style: {
            textTransform: "capitalize",
          },
        },
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchTrack(queryParams);
        }
      );
    }
  };

  fetchTrack = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
      ...this.state.filterData,
      id: this.props.id,
      listType: this.props.tabName ? this.props.tabName : "interested",
    };
    try {
      const payload = {
        ...ApiEndPoints.getSpecificEventUser(this.props.id),
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        resetSelected: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
      if (this.state.page > 1 && res.data.rows.length < 1) {
        this.setState(
          (prevState) => {
            return {
              page: prevState.page - 1,
              isLoading: true,
              data: [],
              totalSize: 0,
            };
          },
          () => {
            addPageSizeInURL(
              this.state.page,
              this.state.sizePerPage,
              this.props.history,
              { tab: this.props.tabName }
            );
            this.fetchTrack();
          }
        );
      }
    } catch (error) {
      logger({ "error:": error });
    }
  };

  handleFilter = () => {
    this.setState((state) => {
      document.body.classList.toggle("overflow-hidden", !state.openFilter);
      return {
        openFilter: !state.openFilter,
      };
    });
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        if (sortField === "isDummySno") {
          sortField = "id";
        }
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };

        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history, {
            tab: this.props.tabName,
          });
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  handleSelectedRows = (trackIds) => {
    this.setState({
      trackIds,
    });
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchTrack(queryParams);
        }
      }
    } else {
      this.fetchTrack(queryParams);
    }
  };

  render() {
    const {
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      resetSelected,
    } = this.state;

    const noDataMessage = textMessages.noUserDataFound;
    const hasPermission = checkUserPermission(
      this.props.userData,
      "audio_collection"
    );

    return (
      <>
        <RemoteDataTable
          data={data}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          onTableChange={this.handleTableChange}
          isFilter={false}
          columns={columns}
          selectRow={hasPermission ? undefined : true}
          resetSelected={hasPermission ? undefined : resetSelected}
          defaultSorted={defaultSorted}
          loading={isLoading}
          noDataMessage={noDataMessage}
          handleSelectedRows={
            hasPermission ? undefined : this.handleSelectedRows
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData || "",
  };
};

ManageParticipantsListing.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
  tabName: PropTypes.string.isRequired,
  id: PropTypes.any.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(ManageParticipantsListing))
);
