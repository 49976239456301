import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import PropTypes from "prop-types";

import RemoteDataTable from "../../components/dataTable";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  imageFormatter,
  goToUserDetail,
  readMoreText,
  serialNumberFormatter,
  dateFormatter,
  getPageSizeFromURL,
  addPageSizeInURL,
  checkUserPermission,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import { getSortField } from "../../utilities/table";
import ReplyMessage from "../modals/replyMessage";
import ShowMoreText from "../modals/showMoreText";

class PendingRequest extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isFirstTimeFetching: true,
      replyMessageModal: false,
      openMessageReplyModal: false,
      isLoading: true,
      rowData: {},
      dataOfMessageReply: {},
      data: [],
      totalSize: 0,
      page: 1,
      sizePerPage: 10,
      defaultSorted: [
        {
          dataField: "id",
          order: "desc",
        },
      ],
      columns: [
        {
          dataField: "id",
          text: props.t("common.id"),
          sort: true,
          hidden: true,
        },
        {
          dataField: "isDummySno",
          text: props.t("common.sNo"),
          // sort: true,
          formatter: (cell, row, rowIndex) =>
            serialNumberFormatter(
              rowIndex,
              this.state.page,
              this.state.sizePerPage
            ),
        },
        {
          dataField: "User.profilePictureThumbUrl",
          text: props.t("common.image"),
          headerAlign: "left",
          align: "left",
          formatter: imageFormatter,
        },
        {
          dataField: "User.username",
          text: props.t("common.username"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: (cell, row) =>
            goToUserDetail(cell, row, row.User.id, {
              bredcrumbs: props.bredcrumbs,
            }),
        },
        {
          dataField: "User.name",
          text: props.t("common.name"),
          headerAlign: "left",
          align: "left",
          sort: true,
          style: {
            textTransform: "capitalize",
          },
          // formatter: (cell, row) => goToUserDetail(cell, row, row.User.id, { bredcrumbs: props.bredcrumbs })
        },
        {
          dataField: "message",
          text: props.t("common.message"),
          headerAlign: "left",
          align: "left",
          classes: "w_460 text-wrap",
          formatter: (cell, row) =>
            readMoreText(cell, row, "message", this.showHideMoreText, props.t),
        },
        {
          dataField: "createdAt",
          text: props.t("common.createdAt"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: dateFormatter,
        },
        {
          dataField: "updatedAt",
          text: props.t("common.updatedAt"),
          headerAlign: "left",
          align: "left",
          sort: true,
          formatter: dateFormatter,
        },
        {
          dataField: "isDummyField",
          text: props.t("common.action"),
          headerAlign: "left",
          align: "left",
          formatter: this.actionFormatter,
        },
      ],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchPendingRequest(queryParams);
        }
      );
    }
  };

  fetchPendingRequest = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
      status: "open",
    };
    try {
      const payload = {
        ...ApiEndPoints.getRequest,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  actionFormatter = (cell, row) => {
    const { t } = this.props;
    return (
      <>
        {" "}
        {checkUserPermission(this.props.userData, "support") ? (
          <div className="dropdown">
            <a
              href="/"
              onClick={(e) => e.preventDefault()}
              className="dropdown-toggle"
              id={`dropdownMenuButton_${row.id}`}
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="ni ni-more-h"></span>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState(
                    {
                      rowData: row,
                    },
                    () => {
                      this.handleToggle("replyMessageModal");
                    }
                  );
                }}
              >
                {t("common.reply")}
              </a>
            </div>
          </div>
        ) : (
          "-"
        )}
      </>
    );
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        sortField = getSortField(sortField);

        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };
        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history, {
            tab: "pending",
          });
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchPendingRequest(queryParams);
        }
      }
    } else {
      this.fetchPendingRequest(queryParams);
    }
  };

  showHideMoreText = (dataOfMessageReply = {}) => {
    this.setState(
      {
        dataOfMessageReply,
      },
      () => {
        this.handleToggle("openMessageReplyModal");
      }
    );
  };

  render() {
    const {
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      replyMessageModal,
      rowData,
      dataOfMessageReply,
      openMessageReplyModal,
    } = this.state;

    const { t } = this.props;

    return (
      <>
        <RemoteDataTable
          data={data}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          onTableChange={this.handleTableChange}
          isFilter={false}
          columns={columns}
          defaultSorted={defaultSorted}
          loading={isLoading}
        />
        <ReplyMessage
          data={rowData}
          show={replyMessageModal}
          onHide={() => this.handleToggle("replyMessageModal")}
          onSubmitSuccess={this.fetchPendingRequest}
        />
        <ShowMoreText
          title={t("common.message")}
          data={
            Object.keys(dataOfMessageReply).length
              ? dataOfMessageReply.data
              : ""
          }
          show={openMessageReplyModal}
          onHide={this.showHideMoreText}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData || "",
  };
};

PendingRequest.propTypes = {
  bredcrumbs: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(PendingRequest))
);
