import React from "react";
import { Modal } from "react-bootstrap";

import PropTypes from "prop-types";

export default function ShowMoreText(props) {
  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-dialog-scrollable"
      centered
      id="messagemodal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="modal-title font-libre-bold w-100 text-capitalize">
            {props.title}
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.data}</Modal.Body>
    </Modal>
  );
}

ShowMoreText.propTypes = {
  data: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
