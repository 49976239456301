import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import { GlobalLoader, StatusBadgeComponent } from "../../components/common";
import CsvUpload from "../../components/csvUpload";
import FeedSelection from "../../components/feedSelection";
import NotFoundView from "../../components/notFoundView";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  dateFormatter,
  getPageSizeFromURL,
  statusFormatter,
  updateQueryInURL,
  checkUserPermission,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

class ManageVideoCollectionFeeds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabName: "collection",
      error: false,
      collectionData: {},
    };
  }

  componentDidMount() {
    if (this.props.location && this.props.location.search) {
      const query = this.props.location.search;
      this.reFetchOnUrlBasis(query);
    }
    this.checkCollectionExist(this.props.match.params.collectionId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.collectionId !==
      this.props.match.params.collectionId
    ) {
      this.checkCollectionExist(this.props.match.params.collectionId);
    }

    if (prevProps.location !== this.props.location) {
      if (this.props.location) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { tab },
      } = res;
      if (tab) {
        this.handleTabChange(tab);
      }
    }
  };

  handleTabChange = (value) => {
    this.setState({
      tabName: value,
    });
  };

  checkCollectionExist = async (id) => {
    try {
      const payload = {
        ...ApiEndPoints.getSpecificCollectionFeed(id),
      };
      const res = await APIrequest(payload);
      this.setState({
        error: false,
        collectionData: res.data,
      });
    } catch (error) {
      this.setState({
        error: true,
      });
      logger({ "error:": error });
    }
  };

  onchangeStatusCollection = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }
      const payload = {
        ...ApiEndPoints.updateStatusCollectionFeed(row.id),
        bodyData: {
          status,
        },
      };
      const res = await APIrequest(payload);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || textMessages.statusUpdate,
      });
      const dataTemp = this.state.collectionData;
      dataTemp.status = status;
      this.setState({
        collectionData: dataTemp,
      });
      resHandleChange(status);
    } catch (error) {
      resHandleChange(row.status);
      logger({ "error:": error });
    }
  };

  reFetchCollectionFeed = () => {
    this.childComponent.fetchFeed();
  };

  render() {
    const { error, collectionData, tabName } = this.state;
    const { t } = this.props;

    const bredcrumbs = [
      {
        name: t("dashboard.title"),
        path: "/",
      },
      {
        name: t("collectionVideo.title"),
        path: "/manage-video-collection",
      },
      {
        name: t("collectionVideo.track"),
        path: `/view-collection-video/${this.props.match.params.collectionId}`,
      },
    ];

    if (error) {
      return <NotFoundView />;
    }

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("collectionVideo.track")}`}
          description={`${t("collectionVideo.track")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent userDetail">
          <div className="container-fluid">
            {/* <!-- page title section start --> */}
            <div className="page-title-row d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title text-capitalize mb-lg-0">
                  {t("collectionVideo.track")}
                </h1>
              </div>
              {checkUserPermission(this.props.userData, "video_collection") ? (
                <div className="page-title-row__right">
                  <CsvUpload
                    collectionId={this.props.match.params.collectionId}
                    t={t}
                    reFetchCollectionFeed={this.reFetchCollectionFeed}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="view-detail bg-white mb-3">
              {Object.keys(collectionData).length > 0 ? (
                <div className="view-detail-header order-view">
                  <div className="d-block d-sm-flex align-items-start">
                    <div className="view-detail-content d-block d-xl-flex align-items-center justify-content-between w-100">
                      <div className="view-detail-left w-100">
                        <div className="d-sm-flex justify-content-between">
                          <h4 className="view-detail-name">
                            {collectionData.title}{" "}
                            <StatusBadgeComponent
                              status={collectionData.status}
                            />
                          </h4>
                          <div className="d-flex align-items-center mt-2 mt-sm-0">
                            <span className="d-block mr-2">
                              {t("common.status")}{" "}
                            </span>
                            {statusFormatter(
                              collectionData.status,
                              collectionData,
                              this.onchangeStatusCollection,
                              "",
                              "video_collection"
                            )}
                          </div>
                        </div>
                        <ul className="list-unstyled customer-info-list d_block mt-2 mb-0">
                          <li className="list-inline-item pl-0">
                            <span className="d-block">
                              {t("common.createdAt")}{" "}
                            </span>
                            <strong>
                              {dateFormatter(collectionData.createdAt)}
                            </strong>
                          </li>
                          <li className="list-inline-item pl-0">
                            <span className="d-block">
                              {t("common.updatedAt")}{" "}
                            </span>
                            <strong>
                              <span>
                                {dateFormatter(collectionData.updatedAt)}
                              </span>
                            </strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <GlobalLoader />
              )}
            </div>
            {/* <!-- page title section end --> */}
            <div className="custom-tabs">
              <ul
                className="nav nav-tabs nav-justified border-top-0"
                role="tablist"
              >
                <li className="nav-item w-auto">
                  <a
                    className={`nav-link ${
                      tabName === "collection" ? "active" : ""
                    }`}
                    id="collectionRequests-tab"
                    data-toggle="tab"
                    href="#collectionRequests"
                    role="tab"
                    aria-controls="collectionRequestsTab"
                    aria-selected="false"
                    onClick={(e) => {
                      e.preventDefault();
                      updateQueryInURL(this.props.history, {
                        tab: "collection",
                      });
                      setTimeout(() => {
                        this.handleTabChange("collection");
                      }, 1000 / 2);
                    }}
                  >
                    {t("collectionVideo.tracks")}
                  </a>
                </li>
                <li className="nav-item w-auto">
                  <a
                    className={`nav-link ${tabName === "all" ? "active" : ""}`}
                    id="allRequest-tab"
                    data-toggle="tab"
                    href="#allRequest"
                    role="tab"
                    aria-controls="allRequestTab"
                    aria-selected="false"
                    onClick={(e) => {
                      e.preventDefault();
                      updateQueryInURL(this.props.history, { tab: "all" });
                      setTimeout(() => {
                        this.handleTabChange("all");
                      }, 1000 / 2);
                    }}
                  >
                    {t("video.all")}
                  </a>
                </li>
              </ul>
              <div className="detail-wrap mt-2 mt-md-3">
                <div className="custom-tabs detail-ta bs">
                  <div className="tab-content mt-0">
                    <div className="tab-pane fade show active">
                      {tabName === "collection" && (
                        <FeedSelection
                          onRef={(ref) => (this.childComponent = ref)}
                          collectionId={this.props.match.params.collectionId}
                          tabName={tabName}
                          bredcrumbs={bredcrumbs}
                        />
                      )}
                      {tabName === "all" && (
                        <FeedSelection
                          onRef={(ref) => (this.childComponent = ref)}
                          collectionId={this.props.match.params.collectionId}
                          tabName={tabName}
                          bredcrumbs={bredcrumbs}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData || "",
  };
};
ManageVideoCollectionFeeds.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps)(ManageVideoCollectionFeeds)
);
