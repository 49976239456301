import React, { PureComponent } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import RemoteDataTable from "../../components/dataTable";
import ReportedReasonAddEdit from "../../components/modals/reportedReasons/reportedReasonAddEdit";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  serialNumberFormatter,
  statusFormatter,
  getPageSizeFromURL,
  addPageSizeInURL,
  checkUserPermission,
} from "../../utilities/common";
import { getColumnsState, getInitialState } from "../../utilities/defaultState";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";
import { getSortField } from "../../utilities/table";

class ReportVideoReason extends PureComponent {
  constructor(props) {
    super(props);

    const dataFieldColumns = [
      "id",
      "isDummySno",
      "message",
      "status",
      "isDummyField",
    ];

    const customSerialNumberFormatter = (_cell, _row, rowIndex) =>
      serialNumberFormatter(rowIndex, this.state.page, this.state.sizePerPage);

    const customStatusFormatter = (cell, row) =>
      statusFormatter(cell, row, this.onchangeStatus);

    const initialState = getInitialState({
      props,
      dataFieldColumns,
      customSerialNumberFormatter,
      customStatusFormatter,
    });

    const { columns: initialColumns, ...defaultState } = initialState;

    const localColumns = [
      {
        dataField: "message",
        text: props.t("reportedReasons.name"),
        headerAlign: "left",
        align: "left",
        sort: true,
        style: {
          textTransform: "capitalize",
        },
      },
      {
        dataField: "isDummyField",
        text: props.t("common.action"),
        headerAlign: "left",
        align: "left",
        formatter: this.actionFormatter,
      },
    ];

    const columns = getColumnsState({
      initialColumns,
      dataFieldColumns,
      localColumns,
    });

    this.state = {
      showUserListId: "",
      showUserListModal: false,
      reportedReasonsAddEditModal: false,
      showModal: false,
      reasonId: 0,
      ...defaultState,
      columns,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchReportedReasons(queryParams);
        }
      );
    }
  };

  fetchReportedReasons = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
    };
    try {
      const payload = {
        ...ApiEndPoints.getReportVideoReasons,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  actionFormatter = (_cell, row) => {
    const { t } = this.props;

    return (
      <div className="dropdown">
        <a
          href="/"
          onClick={(e) => e.preventDefault()}
          className="dropdown-toggle"
          id={`dropdownMenuButton_${row.id}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="ni ni-more-h"></span>
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a
            className="dropdown-item"
            href="/"
            onClick={(e) => {
              e.preventDefault();
              this.reportedReasonAddEdit(row);
            }}
          >
            {t("common.edit")}
          </a>
          {this.state.totalSize > 1 ? (
            <a
              className="dropdown-item"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                this.showConfirm(row.id);
              }}
            >
              {t("common.delete")}
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  onchangeStatus = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }
      const payload = {
        ...ApiEndPoints.reportVideoReasonChangeStatus(row.id),
        bodyData: {
          status,
        },
      };
      const res = await APIrequest(payload);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || textMessages.statusUpdate,
      });
      const dataTemp = this.state.data;
      const indexData = dataTemp.findIndex((d) => d.id === row.id);
      if (indexData > -1) {
        dataTemp[indexData].status = status;
      }
      this.setState({
        data: dataTemp,
      });
      resHandleChange(status);
    } catch (error) {
      resHandleChange(row.status);
      logger({ "error:": error });
    }
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        sortField = getSortField(sortField);
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };

        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history, {
            tab: "user",
          });
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchReportedReasons(queryParams);
        }
      }
    } else {
      this.fetchReportedReasons(queryParams);
    }
  };
  reportedReasonAddEdit = (row = {}) => {
    this.setState(
      {
        editData: row,
      },
      () => {
        this.handleToggle("reportedReasonsAddEditModal");
      }
    );
  };
  showConfirm = (id) => {
    this.setState({
      ...this.state,
      showModal: true,
      reasonId: id,
    });
  };
  reportedReasonDelete = async (row) => {
    try {
      let payload = ApiEndPoints.deleteReportVideoReason(this.state.reasonId);
      let res = await APIrequest(payload);
      if (res.success) {
        modalNotification({
          type: "success",
          message: "Deleted",
          description: textMessages.reportedReasonDelete,
        });
        this.setState(
          {
            ...this.state,
            reasonsId: 0,
            showModal: false,
          },
          () => {
            this.fetchReportedReasons();
          }
        );
      }
    } catch (error) {
      logger(error);
    }
  };

  onHideConfirmation = () => {
    this.setState({
      ...this.state,
      showModal: false,
      reasonId: 0,
    });
  };

  render() {
    const {
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      editData,
      isLoading,
      reportedReasonsAddEditModal,
    } = this.state;
    const { t } = this.props;
    const bredcrumbs = [
      {
        name: t("dashboard.title"),
        path: "/",
      },
      {
        name: t("reportVideoReason.title"),
        path: "/reported-content",
      },
    ];
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("reportVideoReason.title")}`}
          description={`${t("reportVideoReason.title")} of ${
            config.NAME_TITLE
          }`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            {/* <!-- page title section start --> */}
            <div className="page-title-row page-title-btn d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title text-capitalize mb-lg-0">
                  {t("reportVideoReason.title")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div className="btnBox d-flex w-100">
                  {checkUserPermission(
                    this.props.userData,
                    "audio_collection"
                  ) ? (
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.reportedReasonAddEdit();
                      }}
                      className="btn btn-sm btn-primary ripple-effect text-uppercase"
                    >
                      {t("reportedReasons.add")}
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/* <!-- page title section end --> */}
            <div className="custom-tabs">
              <div className="detail-wrap mt-2 mt-md-3">
                <div className="custom-tabs detail-ta bs">
                  <div className="tab-content mt-0">
                    <div className="tab-pane fade show active">
                      <div className="common-table">
                        <div className="table-responsive">
                          <RemoteDataTable
                            data={data}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            onTableChange={this.handleTableChange}
                            isFilter={false}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            loading={isLoading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ReportedReasonAddEdit
          show={reportedReasonsAddEditModal}
          data={editData}
          onSubmitSuccess={this.fetchReportedReasons}
          onHide={() => this.reportedReasonAddEdit()}
          isVideoReportReason="true"
        />
        <SweetAlert
          warning
          showCancel
          show={this.state.showModal}
          confirmBtnText={t("common.confirm")}
          confirmBtnBsStyle="danger"
          title={t("common.confirmWarning")}
          onConfirm={this.reportedReasonDelete}
          onCancel={this.onHideConfirmation}
          focusCancelBtn
        >
          {t("reportedReasons.warning")}
        </SweetAlert>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData || "",
  };
};
ReportVideoReason.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(ReportVideoReason))
);
