import React, { PureComponent } from "react";
import { confirm } from "react-confirm-box";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Form, Input, Button, DatePicker, Badge } from "antd";
import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import { GoToUserDetailPage } from "../../components/common";
import RemoteDataTable from "../../components/dataTable";
import AssignUsername from "../../components/modals/assignUsername";
import config from "../../config";
import { addUserGroupsAction } from "../../redux/common/commonSlice";
import { updateFiltersUsers } from "../../redux/manageUser/manageUserSlice";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  statusFormatter,
  dateFormatDMY,
  filterDataObj,
  serialNumberFormatter,
} from "../../utilities/common";
import { getColumnsState, getInitialState } from "../../utilities/defaultState";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

const options = {
  render: (message, onConfirm, onCancel) => {
    return (
      <div className="confirm-box__content">
        <span> {message} </span>
        <div className="confirm-box__actions">
          <button
            className="ant-btn btn btn-primary ripple-effect"
            onClick={onConfirm}
          >
            {" "}
            Yes{" "}
          </button>
          <button
            className="ant-btn btn btn-outline-dark ripple-effect"
            onClick={onCancel}
          >
            {" "}
            No{" "}
          </button>
        </div>
      </div>
    );
  },
};

class ManageUser extends PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    const filters = props.filters ? filterDataObj(props.filters) : undefined;
    const filtered =
      props.history.action === "POP" && filters && filters?.filterCount;

    const filterData = filters?.filterData ?? {};
    const filterCount = filters?.filterCount ?? 0;

    const dataFieldColumns = [
      "id",
      "isDummySno",
      "profilePictureThumbUrl",
      "email",
      "username",
      "isVerified",
      "status",
      "createdAt",
      "isDummyField",
    ];

    const customStatusFormatter = (cell, row) =>
      statusFormatter(cell, row, this.onchangeStatus, "", "zingler");

    const initialState = getInitialState({
      props,
      dataFieldColumns,
      customStatusFormatter,
    });
    const { columns: initialColumns, ...defaultState } = initialState;

    const localColumns = [
      {
        dataField: "isDummySno",
        text: props.t("common.sNo"),
        sort: true,
        formatter: (cell, row, rowIndex) =>
          serialNumberFormatter(
            rowIndex,
            this.state.page,
            this.state.sizePerPage
          ),
      },
      {
        dataField: "isVerified",
        text: props.t("user.verified"),
        headerAlign: "left",
        align: "left",
        formatter: (cell, row) =>
          statusFormatter(
            cell.toString(),
            row,
            this.onchangeUserTag,
            "isVerified",
            "zingler"
          ),
      },
      {
        dataField: "isDummyField",
        text: props.t("common.action"),
        headerAlign: "left",
        align: "left",
        formatter: this.actionFormatter,
      },
    ];

    const columns = getColumnsState({
      dataFieldColumns,
      initialColumns,
      localColumns,
    });

    if (!filtered && props.updateFiltersUsers !== null) {
      props.updateFiltersUsers(undefined);
    }

    this.state = {
      ...defaultState,
      columns,
      resetSelected: false,
      sendNotificationModal: false,
      userIds: [],
      userId: 0,
      verifyUserId: 0,
      showConfirm: false,
      verifyShowConfirm: false,
      verifyColumnName: "",
      status: "",
      verifyStatus: "",
      tagAddEditModal: false,
      filterData: filtered ? filterData : {},
      filterCount: filtered ? filterCount : 0,
      filtered,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        this.setState(
          {
            isLoading: true,
            page: 1,
            sizePerPage: 10,
          },
          () => {
            this.fetchUsers({
              offset: (this.state.page - 1) * this.state.sizePerPage,
              limit: this.state.sizePerPage,
            });
          }
        );
      }
    }
  }

  componentWillUnmount() {
    if (!this.props.history.location.pathname.match(/user-detail/)) {
      if (this.props.updateFiltersUsers !== null) {
        this.props.updateFiltersUsers(undefined);
      }
    }
  }

  fetchUsers = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
      ...this.state.filterData,
    };
    try {
      const payload = {
        ...ApiEndPoints.getUser,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        resetSelected: false,
        userIds: [],
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  handleFilter = () => {
    this.setState((state) => {
      document.body.classList.toggle("overflow-hidden", !state.openFilter);
      return {
        openFilter: !state.openFilter,
      };
    });
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (_type, { page, sizePerPage, sortField, sortOrder }) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        if (sortField === "isDummySno") {
          sortField = "id";
        }
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };

        this.fetchUsers(queryParams);
      }
    );
  };

  /**
   * Local formatters
   */
  actionFormatter = (cell, row) => {
    const { t } = this.props;
    return (
      <div className="dropdown">
        <Link
          to="#"
          onClick={(e) => e.preventDefault()}
          className="dropdown-toggle"
          id={`dropdownMenuButton_${row.id}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="ni ni-more-h"></span>
        </Link>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <GoToUserDetailPage
            data={t("common.view")}
            id={row.id}
            classProps={"dropdown-item"}
          />
        </div>
      </div>
    );
  };

  tagAddEdit = (row = {}) => {
    this.setState(
      {
        editData: row,
      },
      () => {
        this.handleToggle("tagAddEditModal");
      }
    );
  };

  onchangeUserTag = async (val, row, resHandleChange, columnName) => {
    try {
      let value = "";
      let userStatus = "";
      if (val) {
        value = "true";
        userStatus = "verify";
      } else {
        value = "false";
        userStatus = "unverify";
      }
      const result = await confirm(
        `Are you sure want to ${userStatus} this user?`,
        options
      );
      if (result) {
        const payload = {
          ...ApiEndPoints.updateUserTag,
          bodyData: {
            userId: row.id,
            columnName: columnName,
            value: value,
          },
        };
        const res = await APIrequest(payload);
        modalNotification({
          type: "success",
          message: "Success",
          description: res.message || textMessages.statusUpdate,
        });
        const dataTemp = this.state.data;
        const indexData = dataTemp.findIndex((d) => d.id === row.id);
        if (indexData > -1) {
          dataTemp[indexData].isVerified = value;
        }
        this.setState({
          data: dataTemp,
        });
        resHandleChange(value);
      }
      resHandleChange(row[columnName]);
    } catch (error) {
      resHandleChange(row[columnName]);
      logger({ "error:": error });
    }
  };

  onchangeStatus = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }
      const result = await confirm(
        `Are you sure want to ${status} this user?`,
        options
      );
      if (result) {
        const payload = {
          ...ApiEndPoints.updateStatusUser(row.id),
          bodyData: {
            status,
          },
        };
        const res = await APIrequest(payload);
        modalNotification({
          type: "success",
          message: "Success",
          description: res.message || textMessages.statusUpdate,
        });
        const dataTemp = this.state.data;
        const indexData = dataTemp.findIndex((d) => d.id === row.id);
        if (indexData > -1) {
          dataTemp[indexData].status = status;
        }
        this.setState({
          data: dataTemp,
        });
        resHandleChange(status);
      }
      resHandleChange(row.status);
    } catch (error) {
      resHandleChange(row.status);
      logger({ "error:": error });
    }
  };

  onFinish = async (values) => {
    const { filterData, filterCount } = filterDataObj(values);
    this.props.updateFiltersUsers(values);

    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData,
        filterCount,
        filtered: filterCount > 0,
      },
      () => {
        this.fetchUsers();
        this.handleFilter();
      }
    );
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  onReset = () => {
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData: {},
        filterCount: 0,
      },
      () => {
        this.formRef.current.resetFields();
        this.fetchUsers();
      }
    );
  };

  handleSelectedRows = (userIds) => {
    this.setState({
      userIds,
    });
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      document.body.classList.toggle("overflow-hidden", !state.openFilter);
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  render() {
    const {
      openFilter,
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      filterCount,
      resetSelected,
      tagAddEditModal,
      editData,
      filterData,
    } = this.state;

    const { t } = this.props;
    const noDataMessage =
      filterCount > 0 ? textMessages.noDataFound : textMessages.noDataUserList;

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("user.title")}`}
          description={`${t("user.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            <div className="page-title-row page-title-btn d-flex">
              <div className="page-title-row__left">
                <BreadCrumb
                  bredcrumbs={[
                    {
                      name: t("dashboard.title"),
                      path: "/",
                    },
                    {
                      name: t("user.title"),
                    },
                  ]}
                />
                <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                  {t("user.userList")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div
                  className={`filterForm ${
                    openFilter ? "filterForm--open" : ""
                  }`}
                >
                  <div className="filterForm__head">
                    <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleFilter();
                      }}
                      id="filterClose"
                    >
                      <i className="icon-cross"></i>
                    </a>
                  </div>
                  <div className="filterForm__body">
                    <Form
                      name="userFilter"
                      className="form-inline"
                      ref={this.formRef}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      initialValues={filterData}
                    >
                      <div className="form-group">
                        <label>{t("common.username")}</label>
                        <Form.Item name="username">
                          <Input
                            className="form-control"
                            placeholder={t("common.username")}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("common.emailAddress")}</label>
                        <Form.Item name="email">
                          <Input
                            className="form-control"
                            placeholder={t("common.emailAddress")}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("common.createdAt")}</label>
                        <Form.Item name="createdAt">
                          <DatePicker.RangePicker
                            placeholder={[
                              t("common.startDate"),
                              t("common.endDate"),
                            ]}
                            format={dateFormatDMY}
                          />
                        </Form.Item>
                      </div>
                      <div className="btn_clumn d-flex justify-content-between">
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="btn btn-primary ripple-effect"
                          >
                            {t("common.search")}
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="button"
                            onClick={this.onReset}
                            className="btn btn-outline-dark ripple-effect"
                          >
                            {t("common.reset")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="btnBox">
                  <Badge count={filterCount}>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleFilter();
                      }}
                      id="filter"
                      className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-0"
                    >
                      <i className="icon-filter-line"></i>
                    </a>
                  </Badge>
                </div>
              </div>
            </div>
            <RemoteDataTable
              data={data}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
              isFilter={false}
              columns={columns}
              resetSelected={resetSelected}
              defaultSorted={defaultSorted}
              loading={isLoading}
              noDataMessage={noDataMessage}
              handleSelectedRows={this.handleSelectedRows}
            />
          </div>
        </main>
        <AssignUsername
          show={tagAddEditModal}
          data={editData}
          onSubmitSuccess={this.fetchUsers}
          onHide={() => this.tagAddEdit()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData || "",
    userGroups: state.common.userGroups,
    filters: state.manageUser.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUserGroupsRedux: (res) => dispatch(addUserGroupsAction(res)),
    updateFiltersUsers: (res) => dispatch(updateFiltersUsers(res)),
  };
};
ManageUser.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
  userGroups: PropTypes.array.isRequired,
  addUserGroupsRedux: PropTypes.func.isRequired,
  filters: PropTypes.object,
  updateFiltersUsers: PropTypes.func,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ManageUser)
);
