import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";

import { Row, Col, DatePicker } from "antd";
import SkeletonButton from "antd/lib/skeleton/Button";
import moment from "moment";
import PropTypes from "prop-types";

import BarChart from "../../components/graphs/barChart";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import { numberFormatter } from "../../utilities/common";
import logger from "../../utilities/logger";
import MetaTags from "../../utilities/metaTags";

class Dashboard extends PureComponent {
  pickerInitialValue = [moment(), moment("2015")];
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: {},
      filterData: {},
      pickerDate: this.pickerInitialValue,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      let filterData = this.state.filterData;
      const payload = {
        ...ApiEndPoints.dashboard,
        queryParams: {
          to_date: filterData.toDate,
          from_date: filterData.fromDate,
        },
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        data: res.data,
        filterData: {},
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  filterUserByDate = async (filterDataMap) => {
    try {
      if (filterDataMap) {
        console.log(
          "🚀 ~ file: index.js ~ line 56 ~ Dashboard ~ filterUserByDate= ~ filterDataMap",
          filterDataMap
        );
        let dateFilter = [];
        filterDataMap.forEach((momentObj, index) => {
          if (momentObj) {
            dateFilter[index] = momentObj.format("YYYY-MM-DD");
          } else if (index) {
            dateFilter[index] = moment().format("YYYY-MM-DD");
          } else {
            dateFilter[index] = moment("2015").format("YYYY-MM-DD");
          }
        });
        let applyFilter = {
          fromDate: dateFilter[0],
          toDate: dateFilter[1],
        };
        this.setState(
          {
            filterData: applyFilter,
            isLoading: true,
          },
          () => {
            this.loadData();
          }
        );
      } else {
        this.loadData();
      }
    } catch (error) {
      logger({ "error:": error });
    }
  };

  render() {
    const { isLoading, data } = this.state;
    const { t } = this.props;
    const { RangePicker } = DatePicker;
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("dashboard.title")}`}
          description={`${t("dashboard.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent dashboard-pages">
          <div className="container-fluid">
            <div className="page-title-row filter-page-btn">
              <div className="page-title-row__left    ">
                <h1 className="page-title-row__left__title mb-lg-0 text-capitalize">
                  {t("dashboard.title")}
                </h1>
              </div>
            </div>
            <div className="row rowSpace">
              <div className="col-sm-6 col-lg-4">
                <div className="counterBox bg-white">
                  <div className="counterBox__item">
                    <h3>{t("dashboard.totalUsers")}</h3>
                    <ul className="list-inline mb-0">
                      <li>
                        <label>
                          {isLoading ? (
                            <SkeletonButton active />
                          ) : (
                            numberFormatter(data.total_users)
                          )}
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="counterBox__icon">
                    <img
                      src="/images/total-users.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div className="counterBox bg-white">
                  <div className="counterBox__item">
                    <h3>{t("dashboard.todaysRegisteredUsers")}</h3>
                    <ul className="list-inline mb-0">
                      <li>
                        <label>
                          {isLoading ? (
                            <SkeletonButton active />
                          ) : (
                            numberFormatter(data.todays_registered)
                          )}
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="counterBox__icon">
                    <img
                      src="/images/today-registered.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div className="counterBox bg-white">
                  <div className="counterBox__item">
                    <h3>{t("dashboard.totalVideos")} </h3>
                    <ul className="list-inline mb-0">
                      <li>
                        <label>
                          {isLoading ? (
                            <SkeletonButton active />
                          ) : (
                            numberFormatter(data.total_videos)
                          )}
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="counterBox__icon">
                    <img
                      src="/images/total-videos.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div className="counterBox bg-white">
                  <div className="counterBox__item">
                    <h3>{t("dashboard.totalTracks")}</h3>
                    <ul className="list-inline mb-0">
                      <li>
                        <label>
                          {isLoading ? (
                            <SkeletonButton active />
                          ) : (
                            numberFormatter(data.total_audio)
                          )}
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="counterBox__icon">
                    <img
                      src="/images/total-audio.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-4">
                <div className="counterBox bg-white">
                  <div className="counterBox__item">
                    <h3>{t("dashboard.todaysPostedVideos")} </h3>
                    <ul className="list-inline mb-0">
                      <li>
                        <label>
                          {isLoading ? (
                            <SkeletonButton active />
                          ) : (
                            numberFormatter(data.todays_added_video)
                          )}
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="counterBox__icon__videoposted">
                    <img
                      src="/images/added-video.svg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row rowSpace">
              <div className="col-md-6 col-lg-6">
                <div className="counterBox bg-white">
                  <div className="counterBox__item">
                    <Row justify="space-between">
                      <Col>
                        <h3>{t("dashboard.registeredUser")}</h3>
                      </Col>
                      <Col>
                        <RangePicker
                          allowEmpty={[true, true]}
                          onCalendarChange={this.filterUserByDate}
                        />
                      </Col>
                    </Row>
                    <BarChart
                      color="#559bfb"
                      graphType="user"
                      graphUserData={data.user_filter_count}
                      graphAudioData=""
                      graphVideoData=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="counterBox bg-white">
                  <div className="counterBox__item">
                    <h3>{t("dashboard.totalAudioVideo")}</h3>
                    <BarChart
                      color="#2c3782"
                      graphType="audio"
                      graphUserData=""
                      graphAudioData={data.total_audio}
                      graphVideoData={data.total_videos}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
};

export default withTranslation()(Dashboard);
