import React from "react";
import { Link } from "react-router-dom";

export default function NotFoundView() {
  return (
    <main className="mainContent error-page p-0">
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>Oops!</h1>
            <h2>404 - The Page {"can't"} be found</h2>
          </div>
          <Link to="/" className="btn btn-primary text-uppercase font-md">
            Go TO Homepage
          </Link>
        </div>
      </div>
    </main>
  );
}
