import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { logoutAction } from "../../redux/auth/authSlice";
import {
  addLanguagesAction,
  addVideoCategoriesAction,
} from "../../redux/common/commonSlice";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  removeSessionStorageToken,
  tagClassToggle,
  getTimeDiff,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";
import ChangePassword from "../modals/changePassword";

import "./notification.css";
class AdminHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showChangePasswordModal: false,
      unReadNotificaitons: [],
      newNotifications: 0,
    };
  }

  componentDidMount() {
    const { languagesList, videoCategories: videoCategoriesAH } = this.props;
    if (languagesList.length < 1) {
      this.fetchInfiniteLanguageList();
    }
    if (videoCategoriesAH?.rows?.length < 1 || videoCategoriesAH?.length < 1) {
      this.fetchInfiniteVideoCategoriesList();
    }
    this.getUnreadNotification();
    setInterval(() => {
      this.getUnreadNotification();
    }, 1000 * 60 * 5);
  }

  fetchInfiniteLanguageList = async () => {
    try {
      const payload = {
        ...ApiEndPoints.getLanguage,
      };
      const res = await APIrequest(payload);
      this.props.addLanguagesRedux(res.data);
    } catch (error) {
      logger({ "error:": error });
    }
  };

  fetchInfiniteVideoCategoriesList = async () => {
    try {
      const payload = {
        ...ApiEndPoints.getVideoCategories,
      };
      const res = await APIrequest(payload);
      this.props.addVideoCategoriesRedux(res.data);
    } catch (error) {
      logger({ "error:": error });
    }
  };
  getUnreadNotification = async () => {
    try {
      const payload = {
        ...ApiEndPoints.getUnreadAdminNotification,
      };
      const res = await APIrequest(payload);
      if (res.success) {
        this.setState({
          unReadNotificaitons: res.data.rows,
          newNotifications: res.data.count,
        });
      }
    } catch (error) {
      logger({ "error:": error });
    }
  };
  markReadNotification = async () => {
    try {
      const payload = {
        ...ApiEndPoints.markReadAdminNotification,
      };
      const res = await APIrequest(payload);
      if (res.success) {
        this.setState({
          newNotifications: 0,
          unReadNotificaitons: [],
        });
      }
    } catch (error) {
      logger({ "error:": error });
    }
  };

  logout = async () => {
    const payload = {
      ...ApiEndPoints.accountLogout,
    };
    try {
      const res = await APIrequest(payload);
      this.updateStorage(res.message);
    } catch (error) {
      this.updateStorage();
    }
  };

  updateStorage = (description = "") => {
    modalNotification({
      type: "success",
      message: "Logout",
      description: description || textMessages.logoutSuccessfully,
    });
    this.props.logoutRedux();
    removeSessionStorageToken();
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  render() {
    const { showChangePasswordModal, newNotifications, unReadNotificaitons } =
      this.state;
    const {
      t,
      // i18n,
      userDetail,
    } = this.props;
    return (
      <>
        <div className="navbar__toggle-icon d-xl-none">
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              tagClassToggle("body", "menu-open");
            }}
            id="menu"
          >
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
        <ul className="nav navbar__nav nav-right mb-0 ml-auto align-items-center">
          <li className="dropdown notification-dropdown">
            <div
              className="dropdown-toggle nk-quick-nav-icon"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {newNotifications ? (
                <div className="icon-status icon-status-info">
                  <em className="icon ni ni-bell" />
                </div>
              ) : (
                <div className="icon-status">
                  <em className="icon ni ni-bell" />
                </div>
              )}
            </div>
            <div className="dropdown-menu dropdown-menu-xl dropdown-menu-right">
              <div className="dropdown-head">
                <span className="sub-title nk-dropdown-title">
                  {t("notification.heading")}
                </span>
                <span onClick={this.markReadNotification}>
                  {t("notification.markAllRead")}
                </span>
              </div>
              <div className="dropdown-body">
                <div className="nk-notification">
                  {unReadNotificaitons.length ? (
                    unReadNotificaitons.map((notificationData) => {
                      return (
                        <div
                          className="nk-notification-item dropdown-inner"
                          key={notificationData.createdAt}
                        >
                          <div className="nk-notification-icon">
                            <em className="icon icon-circle bg-warning-dim ni ni-curve-down-right" />
                          </div>
                          <div className="nk-notification-content">
                            <div className="nk-notification-text">
                              {notificationData.message}
                            </div>
                            <div className="nk-notification-time">
                              {getTimeDiff(notificationData.createdAt)}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="nk-notification-item dropdown-inner">
                      <div className="nk-notification-content text-center">
                        {t("notification.noNotification")}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="dropdown-foot center">
                <Link to="/notifications" onClick={this.markReadNotification}>
                  View All
                </Link>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown author ml-2 ml-md-4">
            <a
              href="/"
              onClick={(e) => e.preventDefault()}
              className="dropdown-toggle"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {userDetail.name || "Admin"}
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleToggle("showChangePasswordModal");
                }}
              >
                {t("header.changePassword")}
              </a>
              <a
                className="dropdown-item"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.logout();
                }}
              >
                {t("header.logout")}
              </a>
            </div>
          </li>
        </ul>
        <ChangePassword
          show={showChangePasswordModal}
          onHide={() => this.handleToggle("showChangePasswordModal")}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.auth.userData,
    languagesList: state.common.languages,
    videoCategories: state.common.videoCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutRedux: (res) => dispatch(logoutAction(res)),
    addLanguagesRedux: (res) => dispatch(addLanguagesAction(res)),
    addVideoCategoriesRedux: (res) => dispatch(addVideoCategoriesAction(res)),
  };
};

AdminHeader.propTypes = {
  logoutRedux: PropTypes.func.isRequired,
  addLanguagesRedux: PropTypes.func.isRequired,
  addVideoCategoriesRedux: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userDetail: PropTypes.object.isRequired,
  languagesList: PropTypes.array.isRequired,
  videoCategories: PropTypes.array.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AdminHeader)
);
