export const notRequireAuthPaths = [
  "/",
  "/forgot-password",
  "/reset-password/:token",
];

export const redirectPathIfRequireAuthFails = "/";

export const redirectPathIfNotRequireAuthFails = [
  {
    path: "/dashboard",
    userTypes: ["admin"],
  },
  {
    path: "/dashboard",
    userTypes: ["subadmin"],
  },
  {
    path: "/manage-user-role",
    userTypes: ["approver"],
  },
];
