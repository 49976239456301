import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Form, Select, Button, Row, Col } from "antd";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
export default function DeactivateReason({
  show,
  controller,
  onHide,
  loading,
}) {
  const { t } = useTranslation();
  const { Option } = Select;
  const [reasons, setReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  useEffect(() => {
    fetchReasons();
  }, [reasons.total, selectedReason, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  function onChange(value) {
    setSelectedReason(value);
    setDisableButton(false);
  }
  const changeState = () => {
    controller(selectedReason);
    setSelectedReason("");
    setDisableButton(true);
  };
  async function fetchReasons() {
    try {
      let payload = {
        ...ApiEndPoints.getReportVideoReasons,
      };
      let res = await APIrequest(payload);
      if (res.success) {
        setReasons(res.data);
      }
    } catch (error) {
      logger(error);
    }
  }
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{t("common.reason")}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Item name="reason">
            <Select
              onChange={onChange}
              placeholder="Select Reason"
              style={{ border: "1px solid #c2c2c2" }}
            >
              {reasons.total ? (
                reasons.rows.map((reason) => {
                  return (
                    <Option key={reason.message} value={reason.message}>
                      {reason.message}
                    </Option>
                  );
                })
              ) : (
                <Option>{t("common.noReasons")}</Option>
              )}
            </Select>
          </Form.Item>
        </Form>
        <Row justify="end" className="mt-3">
          <Col pull={1}>
            <Button onClick={onHide} loading={loading}>
              {t("common.cancel")}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              loading={loading}
              onClick={changeState}
              disabled={disableButton}
            >
              {t("common.confirm")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

DeactivateReason.propTypes = {
  // data: PropTypes.object.isRequired,
  // show: PropTypes.bool.isRequired,
  // onHide: PropTypes.func.isRequired,
  // onSubmitSuccess: PropTypes.func.isRequired
};
