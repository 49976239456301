import React from "react";

import Routes from "./routes";
import "antd/dist/antd.css";
import "./styles/scss/main.scss";

function App() {
  return <Routes />;
}

export default App;
