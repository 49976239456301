import { confirm } from "react-confirm-box";

import { DefaultOptionsModalConfirm } from "../../components/common";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";

export const useManageCommunityPosts = ({ callbackOnChangeStatus }) => {
  const onChangeStatus = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }
      const result = await confirm(
        `Are you sure want to ${status} this post?`,
        DefaultOptionsModalConfirm
      );
      if (result) {
        const payload = {
          ...ApiEndPoints.updatePostStatusGroup(row.groupId, row.id),
          bodyData: {
            status,
          },
        };
        const res = await APIrequest(payload);
        modalNotification({
          type: "success",
          message: "Success",
          description: res.message || textMessages.statusUpdate,
        });

        if (callbackOnChangeStatus !== null) {
          callbackOnChangeStatus(status, row.id);
        }
        resHandleChange(status);
      }
      resHandleChange(row.status);
    } catch (error) {
      resHandleChange(row.status);
      logger({ "error:": error });
    }
  };

  return {
    onChangeStatus,
  };
};
