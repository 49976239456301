import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import CmsForm from "../../components/forms/cms";
import LoadingView from "../../components/loadingView";
import NotFoundView from "../../components/notFoundView";
import config from "../../config";
import { singleUpdateCmsDataAction } from "../../redux/cms/cmsSlice";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

export class Cms extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      optionsData: {},
      dummyOptionsdata: {},
      isLoading: true,
      isSpin: false,
      error: false,
    };
  }

  formRef = React.createRef();
  editorRef = React.createRef();

  componentDidMount() {
    let id = this.props.match.url.charAt(this.props.match.url.length - 1);
    this.fetchCmsOptions(id);
  }

  componentDidUpdate(prevProps) {
    let id = this.props.match.url.charAt(this.props.match.url.length - 1);
    let prevId = prevProps.match.url.charAt(prevProps.match.url.length - 1);

    if (prevId !== id) {
      this.setState(
        {
          isLoading: true,
          error: false,
        },
        () => {
          this.fetchCmsOptions(id);
        }
      );
    }
  }

  fetchCmsOptions = async (id) => {
    try {
      const payload = {
        ...ApiEndPoints.getSpecificCms(id),
      };
      const res = await APIrequest(payload);

      this.setState(
        {
          isLoading: false,
          error: false,
          optionsData: res.data,
        },
        () => {
          if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
              title: res.data.pageName,
              content: res.data.pageContent,
            });
          }
          if (this.editorRef.current) {
            this.editorRef.current.editor.setContent(
              res.data ? res.data.pageContent : ""
            );
          }
        }
      );
    } catch (error) {
      logger({ "error:": error });
      this.setState({
        isLoading: false,
        error: true,
      });
    }
  };

  onReset = () => {
    const { optionsData } = this.state;
    this.formRef.current.setFieldsValue({
      title: optionsData ? optionsData.pageName : "",
      content: optionsData ? optionsData.pageContent : "",
    });

    this.editorRef.current.editor.setContent(
      optionsData ? optionsData.pageContent : ""
    );
  };

  onFinish = (values) => {
    let id = this.props.match.url.charAt(this.props.match.url.length - 1);
    this.setState(
      {
        isSpin: true,
      },
      async () => {
        try {
          const payload = {
            ...ApiEndPoints.updateSpecificCms(id),
            bodyData: {
              pageName: values.title,
              pageContent: values.content,
            },
          };
          const res = await APIrequest(payload);
          this.props.updateSingleCmsDataRedux(res.data);
          modalNotification({
            type: "success",
            message: "Updated",
            description: res.message || textMessages.updateCMSMessage,
          });
          this.setState({
            isSpin: false,
          });
        } catch (error) {
          this.setState({
            isSpin: false,
          });
          logger({ "error:": error });
        }
      }
    );
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  handleEditorChange = (content) => {
    if (this.formRef.current) {
      this.formRef.current.setFieldsValue({
        content: content || "",
      });
    }
  };

  render() {
    const { optionsData, error, isLoading, isSpin } = this.state;
    const { t } = this.props;

    if (isLoading) {
      return <LoadingView />;
    }

    if (error) {
      return <NotFoundView />;
    }

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("cms.title")}`}
          description={`${t("cms.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent dashboard-pages">
          <div className="container-fluid">
            <div className="page-title-row filter-page-btn">
              <div className="page-title-row__left">
                <BreadCrumb
                  bredcrumbs={[
                    {
                      path: "/",
                      name: "Dashboard",
                    },
                    {
                      name: optionsData.pageName,
                    },
                  ]}
                />
                <h1 className="page-title-row__left__title text-capitalize mb-lg-0">
                  {optionsData.pageName}
                </h1>
              </div>
            </div>
            <div className="edit-cms__wrapper">
              <CmsForm
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                formRef={this.formRef}
                editorRef={this.editorRef}
                isSpin={isSpin}
                submitButtonText={"Update"}
                handleEditorChange={this.handleEditorChange}
                onReset={this.onReset}
                optionsData={optionsData}
              />
            </div>
          </div>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSingleCmsDataRedux: (res) => dispatch(singleUpdateCmsDataAction(res)),
  };
};

Cms.propTypes = {
  match: PropTypes.object.isRequired,
  updateSingleCmsDataRedux: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export const CMSComponent = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Cms)
);

export default CMSComponent;
