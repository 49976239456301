import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import config from "../config";
import authReducer from "../redux/auth/authSlice";
import cmsReducer from "../redux/cms/cmsSlice";
import commonReducer from "../redux/common/commonSlice";
import counterReducer from "../redux/counter/counterSlice";
import manageUserReducer from "../redux/manageUser/manageUserSlice";
import manageVideoReducer from "../redux/manageVideo/manageVideoSlice";
import logger from "../utilities/logger";

const RootReducer = combineReducers({
  counter: counterReducer,
  auth: authReducer,
  manageVideo: manageVideoReducer,
  manageUser: manageUserReducer,
  common: commonReducer,
  cms: cmsReducer,
});

const encryptor = encryptTransform({
  secretKey: `${config.NAME_KEY}-storage`,
  onError: (error) => {
    // Handle the error.
    logger({ error: error });
  },
});

const persistConfig = {
  key: config.NAME_KEY,
  storage,
  whitelist: ["auth", "cms"],
  transforms: [encryptor],
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    // https://github.com/rt2zz/redux-persist/issues/988#issuecomment-552242978
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
  devTools: config.NODE_ENV !== "production",
});

export default store;

export const Persistor = persistStore(store);
