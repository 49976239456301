import config from "../../config";

const hiValidationMsg = {
  /**
   * Login
   */
  enterEmail: "एक ईमेल दर्ज करें",
  enterValidEmail: "एक वैध ईमेल प्रविष्ट करें",
  enterPassword: "पासवर्ड ङालें",
  loginSuccessfully: "आप सफलतापूर्वक लॉगिन करें",
  loginError: "गलत ई-मेल और पासवर्ड",
  /**
   * Logout
   */
  logoutSuccessfully: "आप सफलतापूर्वक लॉगआउट करते हैं",
  /**
   * Reset Password
   */
  passwordSuccess: "पासवर्ड सफलतापूर्वक अपडेट किया गया है",
  passwordLengthMessage: (min, max) =>
    `अपने पासवर्ड के लिए ${min}-${max} वर्ण का उपयोग करें`,
  passwordNotMatchMessage: "नया पासवर्ड और कन्फर्म पासवर्ड मेल नहीं खाते हैं",
  reEnterPassword: "पासवर्ड को फिर से दर्ज करें",
  /**
   * Approver
   */
  enterApproverName: "स्वीकृत नाम दर्ज करें",
  addApproverMessage: "अनुमोदन सफलतापूर्वक जोड़ा गया",
  updateApproverMessage: "अनुमोदन सफलतापूर्वक अपडेट किया गया",
  /**
   * Change Password
   */
  currentPasswordError: "वर्तमान पासवर्ड गलत है",
  passwordChangeSuccess: "आपने पासवर्ड सफलतापूर्वक बदल दिया है",
  enterCurrentPassword: "वर्तमान पासवर्ड डालें",
  enterNewPassword: "नया पासवर्ड दर्ज करें",
  reEnterNewPassword: "नया पासवर्ड पुनः दर्ज करें",
  /**
   * Category
   */
  enterCategoryName: "श्रेणी का नाम दर्ज करें",
  addCategoryMessage: "श्रेणी को सफलतापूर्वक जोड़ा गया",
  updateCategoryMessage: "श्रेणी को सफलतापूर्वक अपडेट किया गया",
  /**
   * Collection
   */
  enterCollectionName: "संग्रह नाम दर्ज करें",
  addCollectionMessage: "संग्रह को सफलतापूर्वक जोड़ा गया",
  updateCollectionMessage: "संग्रह को सफलतापूर्वक अपडेट किया गया",
  enterCollectionCurrentOrder: "संग्रह वर्तमान क्रम दर्ज करें",
  enterCollectionNewOrder: "संग्रह नया आदेश दर्ज करें",
  updateOrderCollectionMessage: "संग्रह क्रम सफलतापूर्वक अपडेट किया गया",
  selectCollection: "संग्रह का चयन करें",
  collectionAssigned: "संग्रह सफलतापूर्वक ऑडियो को सौंपा गया",
  /**
   * Artist
   */
  enterArtistName: "कलाकार का नाम दर्ज करें",
  selectArtistIcon: "कलाकार आइकन चुनें",
  addArtistMessage: "कलाकार सफलतापूर्वक जोड़ा गया",
  updateArtistMessage: "कलाकार सफलतापूर्वक अपडेट किया गया",
  /**
   * Tag
   */
  enterTagName: "टैग शीर्षक दर्ज करें",
  validTagMessage: "# शुरू करने और शब्दों में रिक्त स्थान की अनुमति नहीं है",
  addTagMessage: "नया टैग सफलतापूर्वक जोड़ा गया",
  updateTagMessage: "टैग सफलतापूर्वक अपडेट किया गया",
  /**
   * Points
   */
  updatePointsMessage: "अंक सफलतापूर्वक अपडेट किए गए",
  enterPoint: "अंक दर्ज करें",
  pointValueMessage: "केवल संख्या की अनुमति है",
  /**
   * CMS
   */
  updateCMSMessage: "CMS सफलतापूर्वक अपडेट किया गया",
  enterCMSTitle: "CMS शीर्षक दर्ज करें",
  enterCMSContent: "CMS सामग्री दर्ज करें",
  /**
   * FAQ
   */
  addFAQ: "नए FAQ को सफलतापूर्वक जोड़ा गया",
  updateFAQ: "सफलतापूर्वक अपडेट किया गया",
  deleteFAQ: "सफलतापूर्वक हटाए गए FAQ",
  enterFAQquestion: "प्रश्न दर्ज करें",
  enterFAQanswer: "उत्तर दर्ज करें",
  /**
   * Album
   */
  addAlbumMessage: "एल्बम सफलतापूर्वक जोड़ा गया",
  updateAlbumMessage: "एल्बम सफलतापूर्वक अपडेट किया गया",
  enterAlbumName: "नाम दर्ज",
  selectAlbumIcon: "एल्बम आइकन चुनें",
  selectAlbumCategory: "श्रेणी का चयन करें",
  /**
   * Track
   */
  addTrackMessage: "ट्रैक सफलतापूर्वक जोड़ा गया",
  updateTrackMessage: "ट्रैक सफलतापूर्वक अपडेट किया गया",
  enterTrackLength: "ट्रैक की लंबाई दर्ज करें",
  enterTrackName: "नाम दर्ज",
  selectTrackIcon: "ट्रैक फ़ाइल का चयन करें",
  selectTrackArtist: "कलाकार का चयन करें",
  /**
   * Interval
   */
  enterIntervalTime: "सेकंड में अंतराल समय दर्ज करें",
  timeIntervalError: "समय 0 से अधिक होना चाहिए",
  enterIntervalTimeFollowers: "अनुयायी दर्ज करें",
  followerIntervalError: "न्यूमेरिक में फॉलोअर वैल्यू होनी चाहिए",
  timeIntervalMustInError: `समय ${config.VIDEO_UPLOAD_LENGTH_MINIMUM} के एकाधिक में होना चाहिए`,
  timeIntervalMaxError: (time) => `अधिकतम समय ${time} सेकंड की अनुमति है`,
  addIntervalMessage: "अंतराल को सफलतापूर्वक जोड़ा गया",
  updateIntervalMessage: "अंतराल को सफलतापूर्वक अपडेट किया गया",
  deleteInterval: "अंतराल सफलतापूर्वक हटा दिया गया",
  helpingIntervalMessage: `नोट: ${config.VIDEO_UPLOAD_LENGTH_MINIMUM} के कई में अंतराल दर्ज करें`,
  /**
   * Reply Message
   */
  enterResponse: "संदेश दर्ज करें",
  /**
   * Notification
   */
  enterNotificationTitle: "शीर्षक दर्ज करें",
  enterNotificationMessage: "संदेश दर्ज करें",
  sentNotificationSuccessfully: "अधिसूचना सफलतापूर्वक भेजी गई",
  /**
   * Upload file
   */
  fileUploadSuccess: (fileName) => `${fileName} सफलतापूर्वक अपलोड किया गया`,
  fileUploadFail: (fileName) => `${fileName} अपलोड विफल रहा`,
  filesAllowed: (files) => `केवल इस ${files} फ़ाइल प्रकार की अनुमति है`,
  fileSizeLimit: (size) => `फ़ाइल ${size} एमबी तक होनी चाहिए`,
  fileAudioLengthLimit: ({ minLength, maxLength }) =>
    `ऑडियो फ़ाइल ${minLength}-${maxLength} सेकंड की लंबाई के बीच होनी चाहिए`,
  /**
   * Common
   */
  statusUpdate: "स्थिति सफलतापूर्वक अपडेट की गई",
  enterMinChar: (val) => `न्यूनतम ${val} अक्षर दर्ज करें`,
  enterMaxChar: (val) => `अधिकतम ${val} वर्णों की अनुमति है`,
  alphaNumericOnly: "केवल अल्फ़ान्यूमेरिक वर्ण की अनुमति है",
  orderLengthMessage: (min, max) => `ऑर्डर ${min}-${max} के बीच होना चाहिए`,
  /**
   * No Data Message
   */
  notAvailable: "उपलब्ध नहीं है",
  noDataFound: "कोई रिकॉर्ड नहीं मिला",
  noDataUserList: "सिस्टम में कोई उपयोगकर्ता पंजीकृत नहीं है",
  noDataApproverList:
    'एक अनुमोदन जोड़ने के लिए "जोड़ें स्वीकृति प्रदान करने वाले" बटन पर क्लिक करें',
  noDataCategoryList: 'श्रेणी जोड़ने के लिए "श्रेणी जोड़ें" बटन पर क्लिक करें',
  noDataCollectionList:
    'संग्रह जोड़ने के लिए "संग्रह जोड़ें" बटन पर क्लिक करें',
  noDataArtistList: 'कलाकार जोड़ने के लिए "कलाकार जोड़ें" बटन पर क्लिक करें',
  noDataAlbumList: 'एल्बम जोड़ने के लिए "एल्बम जोड़ें" बटन पर क्लिक करें',
  noDataTrackList: 'ट्रैक जोड़ने के लिए "ट्रैक जोड़ें" बटन पर क्लिक करें',
  noDataTagList: 'हैशटैग जोड़ने के लिए "हैशटैग जोड़ें" बटन पर क्लिक करें',
  noDataIntervalList: 'अंतराल जोड़ने के लिए "अंतराल जोड़ें" बटन पर क्लिक करें',
  noDataFaqList:
    'सामान्य प्रश्न जोड़ने के लिए "सामान्य प्रश्न जोड़ें" बटन पर क्लिक करें',
};

export default hiValidationMsg;
