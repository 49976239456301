export const sidebarJSON = (t = (arg) => arg, permissions = []) => [
  {
    endPoint: "/dashboard",
    authRequire: true,
    addInSideBar: true,
    active: ["/dashboard"],
    title: t("dashboard.title"),
    icon: "ni ni-grid-alt",
    userTypes: ["admin", "subadmin"],
    moduleKey: "dashboard",
  },
  {
    endPoint: "/manage-user",
    authRequire: true,
    addInSideBar: true,
    active: ["/manage-user", "/user-detail/:userid"],
    title: t("user.title"),
    icon: "ni ni-user-list",
    userTypes: checkPermission(permissions, "zingler"),
    moduleKey: "zingler",
  },
  {
    endPoint: "/manage-video",
    authRequire: true,
    addInSideBar: true,
    active: ["/manage-video", "/view-collection-video/:collectionId"],
    title: t("collectionVideo.title"),
    icon: "ni ni-video",
    userTypes: checkPermission(permissions, "video_collection"),
    moduleKey: "video_collection",
  },
  {
    endPoint: "/manage-audio",
    authRequire: true,
    addInSideBar: true,
    active: ["/manage-audio"],
    title: t("audio.title"),
    icon: "ni ni-mic",
    userTypes: checkPermission(permissions, "audio"),
    moduleKey: "audio",
  },
  {
    endPoint: "/communities",
    authRequire: true,
    addInSideBar: true,
    active: ["/communities", "/community/:id"],
    title: t("community.title"),
    icon: "ni ni-user-list",
    userTypes: ["admin", "subadmin"],
    moduleKey: "communities",
  },
  {
    title: t("community.title"),
    icon: "ni ni-user-list",
    userTypes: ["admin", "subadmin"],
    moduleKey: "communities",
    active: ["/communities", "/community/:id"],
    endPoint: "/community/:id",
    addInSideBar: false,
    authRequire: true,
  },
  {
    endPoint: "/community-posts/:id",
    authRequire: true,
    addInSideBar: false,
    active: ["/community-posts/:id"],
    title: t("community-posts.title"),
    icon: "ni ni-rss",
    userTypes: ["admin", "subadmin"],
    moduleKey: "community-posts",
  },
  {
    endPoint: "/reported-video",
    authRequire: true,
    addInSideBar: true,
    active: ["/reported-video"],
    title: t("reportedVideo.title"),
    icon: "ni ni-question-alt",
    userTypes: checkPermission(permissions, "reported_video"),
    moduleKey: "reported_video",
  },
  {
    endPoint: "/event/today-event/event-detail/:id",
    authRequire: true,
    addInSideBar: false,
    active: [
      "/event/today-event/event-detail/:id",
      "/event",
      "/event/today-event",
    ],
    title: t("audio.title"),
    icon: "ni ni-mic",
    userTypes: checkPermission(permissions, "event"),
    moduleKey: "event",
  },

  {
    endPoint: "event",
    authRequire: true,
    addInSideBar: true,
    active: [
      "/event",
      "/event/today-event",
      "/event/upcoming-event",
      "/event/past-event",
    ],
    title: t("event.title"),
    icon: "ni ni-calendar-booking",
    userTypes: checkPermission(permissions, "event"),
    moduleKey: "event",
    child: [
      {
        endPoint: "/event/today-event",
        authRequire: true,
        addInSideBar: true,
        active: ["/event/today-event", "/event"],
        title: t("event.todayEvent"),
        icon: "",
        userTypes: checkPermission(permissions, "event"),
        // userTypes: ['admin', 'executive'],
        moduleKey: "event",
      },
      {
        endPoint: "/event/upcoming-event",
        authRequire: true,
        addInSideBar: true,
        active: ["/event/upcoming-event", "/event"],
        title: t("event.upcomingEvent"),
        icon: "",
        userTypes: checkPermission(permissions, "event"),
        // userTypes: ['admin', 'executive'],
        moduleKey: "event",
      },
      {
        endPoint: "/event/past-event",
        authRequire: true,
        addInSideBar: true,
        active: ["/event/past-event", "/event"],
        title: t("event.pastEvent"),
        icon: "",
        userTypes: checkPermission(permissions, "event"),
        // userTypes: ['admin', 'executive'],
        moduleKey: "event",
      },
    ],
  },
  {
    endPoint: "/reported-reasons",
    authRequire: true,
    addInSideBar: true,
    active: ["/reported-reasons"],
    title: t("reportedReasons.title"),
    icon: "ni ni-file-text",
    userTypes: checkPermission(permissions, "reported_reasons"),
    moduleKey: "reported_reasons",
  },
  {
    endPoint: "/report-video-reason",
    authRequire: true,
    addInSideBar: false,
    active: ["/report-video-reason"],
    title: t("reportVideoReason.title"),
    icon: "ni ni-file-text",
    userTypes: checkPermission(permissions, "reported_reasons"),
    moduleKey: "reported_reasons",
  },
  {
    endPoint: "cms",
    authRequire: true,
    addInSideBar: true,
    active: ["/cms", "/cms/1", "/cms/2", "/cms/3", "/faq"],
    title: t("cms.title"),
    icon: "ni ni-opt",
    userTypes: checkPermission(permissions, "cms"),
    moduleKey: "cms",
    child: [
      {
        endPoint: "/cms/1",
        authRequire: true,
        addInSideBar: true,
        active: ["/cms", "/cms/1"],
        title: t("cms.aboutUs"),
        icon: "",
        userTypes: checkPermission(permissions, "cms"),
        // userTypes: ['admin', 'executive'],
        moduleKey: "cms",
      },
      {
        endPoint: "/cms/2",
        authRequire: true,
        addInSideBar: true,
        active: ["/cms", "/cms/2"],
        title: t("cms.termsConditions"),
        icon: "",
        userTypes: checkPermission(permissions, "cms"),
        // userTypes: ['admin', 'executive'],
        moduleKey: "cms",
      },
      {
        endPoint: "/cms/3",
        authRequire: true,
        addInSideBar: true,
        active: ["/cms", "/cms/3"],
        title: t("cms.privacyPolicy"),
        icon: "",
        userTypes: checkPermission(permissions, "cms"),
        // userTypes: ['admin', 'executive'],
        moduleKey: "cms",
      },
      {
        endPoint: "/faq",
        authRequire: true,
        addInSideBar: true,
        active: ["/cms", "/faq", "/add-faq", "/edit-faq/:faqId"],
        title: t("cms.faq"),
        icon: "",
        userTypes: checkPermission(permissions, "cms"),
        // userTypes: ['admin', 'executive'],
        moduleKey: "cms",
      },
    ],
  },
  {
    endPoint: "support",
    authRequire: true,
    addInSideBar: true,
    active: [
      "/support",
      "/support/pending-queries",
      "/support/replied-queries",
    ],
    title: t("support.title"),
    icon: "ni ni-headphone",
    userTypes: checkPermission(permissions, "support"),
    moduleKey: "support",
    child: [
      {
        endPoint: "/support/pending-queries",
        authRequire: true,
        addInSideBar: true,
        active: ["/support/pending-queries", "/support"],
        title: t("support.pendingQueries"),
        icon: "",
        userTypes: checkPermission(permissions, "support"),
        // userTypes: ['admin', 'executive'],
        moduleKey: "support",
      },
      {
        endPoint: "/support/replied-queries",
        authRequire: true,
        addInSideBar: true,
        active: ["/support/replied-queries", "/support"],
        title: t("support.repliedQueries"),
        icon: "",
        userTypes: checkPermission(permissions, "support"),

        // userTypes: ['admin', 'executive'],
        moduleKey: "support",
      },
    ],
  },
  {
    endPoint: "/user-detail/:userid",
    authRequire: true,
    addInSideBar: false,
    active: ["/manage-user", "/user-detail/:userid"],
    title: t("user.title"),
    icon: "icon-total-user",
    userTypes: checkPermission(permissions, "zingler"),
    moduleKey: "zingler",
  },
  {
    endPoint: "/add-faq",
    authRequire: true,
    addInSideBar: false,
    active: ["/cms/faq", "/add-faq"],
    title: "",
    icon: "",
    userTypes: ["admin"],
    moduleKey: "",
  },
  {
    endPoint: "/edit-faq/:faqId",
    authRequire: true,
    addInSideBar: false,
    active: ["/cms/faq", "/edit-faq/:faqId"],
    title: "",
    icon: "",
    userTypes: ["admin"],
    moduleKey: "",
  },

  {
    endPoint: "/view-collection-track/:collectionId",
    authRequire: true,
    addInSideBar: false,
    active: ["/manage-collection", "/view-collection-track/:collectionId"],
    title: t("collection.title"),
    icon: "icon-data-collection",
    userTypes: checkPermission(permissions, "audio_collection"),
    moduleKey: "audio_collection",
  },

  {
    endPoint: "/video-detail/:videoid",
    authRequire: true,
    addInSideBar: false,
    active: ["/manage-video", "/video-detail/:videoid"],
    title: t("video.title"),
    icon: "icon-video-add",
    userTypes: checkPermission(permissions, "video"),
    moduleKey: "video",
  },

  {
    endPoint: "/notifications",
    authRequire: true,
    addInSideBar: false,
    active: ["/notifications"],
    title: t("notifications.title"),
    icon: "ni ni-file-text",
    userTypes: checkPermission(permissions, "notifications"),
    moduleKey: "notifications",
  },
  {
    endPoint: "/reserve-username",
    authRequire: true,
    addInSideBar: false,
    active: ["/reserve-username"],
    title: t("username.title"),
    icon: "ni ni-hash",
    userTypes: checkPermission(permissions, "reserve_username"),
    moduleKey: "reserve-username",
  },
];

const checkPermission = (permissions, moduleKey) => {
  if (permissions) {
    let moduleKeyIndex = permissions.findIndex((item) => {
      return moduleKey === "send_notification"
        ? item.moduleKey === moduleKey && item.permission === "edit"
        : item.moduleKey === moduleKey;
    });
    if (moduleKeyIndex > -1) {
      return ["admin", "subadmin"];
    }
  }
  return ["admin"];
};
