import React, { PureComponent } from "react";

import Login from "../../components/login";
import config from "../../config";
import MetaTags from "../../utilities/metaTags";

export default class Home extends PureComponent {
  render() {
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | Login`}
          description={`Login page of ${config.NAME_TITLE}`}
        />
        <Login />
      </>
    );
  }
}
