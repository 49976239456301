import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import BreadCrumb from "../../components/breadCrumb";
import {
  GoToDetailPage,
  ImageComponent,
  StatusBadgeComponent,
} from "../../components/common";
import RemoteDataTable from "../../components/dataTable";
import config from "../../config";
import {
  dateFormatter,
  nameWithImageFormatter,
  statusFormatter,
} from "../../utilities/common";
import { DEFAULT_PAGINATION } from "../../utilities/constants";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import { useManageCommunity } from "./useManageCommunity";

const CommuntyDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const pagination = DEFAULT_PAGINATION;
  const isLoading = false;

  const data = useMemo(() => {
    if (id) {
      const mockData = {
        iconUrl: "",
        id: 1,
        name: "Best Community",
        description: "The best community",
        icon: "https://cdn-icons-png.flaticon.com/512/615/615075.png",
        type: "public",
        status: "active",
        owner: 2,
        ownerName: "Jhon",
        createdAt: "2023-10-10T15:34:54.000Z",
        updatedAt: "2023-10-10T15:34:54.000Z",
        lastMessage: null,
        messageType: null,
        messageMedia: null,
        lastMessagebyUserId: null,
        postCount: 0,
        memberCount: 2,
        actions: "",
        lastPostDate: "2023-10-10T15:34:54.000Z",
        members: [
          {
            id: 1,
            profilePictureUrl:
              "https://collyge-back-collygedev.bunnyenv.com/public/default-images/user.png",
            profilePictureThumbUrl:
              "https://collyge-back-collygedev.bunnyenv.com/public/default-images/user.png",
            coverPictureUrl:
              "https://collyge-back-collygedev.bunnyenv.com/public/default-images/user.png",
            coverPictureThumbUrl:
              "https://collyge-back-collygedev.bunnyenv.com/public/default-images/user.png",
            email: "user@collyge.com",
            firstName: "Felipe",
            lastName: "Siqueira",
            username: "siqueirinha",
            gender: "male",
            profilePicture: null,
            coverPicture: null,
            phoneNumberCountryCode: null,
            phoneNumber: null,
            userType: "user",
            lastLoginTime: null,
            status: "active",
            isOnline: false,
            isVerified: false,
            isEmailVerified: false,
            notificationStatus: "active",
          },
          {
            id: 2,
            profilePictureUrl:
              "https://collyge-back-collygedev.bunnyenv.com/public/default-images/user.png",
            profilePictureThumbUrl:
              "https://collyge-back-collygedev.bunnyenv.com/public/default-images/user.png",
            coverPictureUrl:
              "https://collyge-back-collygedev.bunnyenv.com/public/default-images/user.png",
            coverPictureThumbUrl:
              "https://collyge-back-collygedev.bunnyenv.com/public/default-images/user.png",
            email: "user2@collyge.com",
            firstName: "Felipe2",
            lastName: "Siqueira2",
            username: "siqueirinha2",
            gender: "male",
            profilePicture: null,
            coverPicture: null,
            phoneNumberCountryCode: null,
            phoneNumber: null,
            userType: "user",
            lastLoginTime: null,
            status: "active",
            isOnline: false,
            isVerified: false,
            isEmailVerified: false,
            notificationStatus: "active",
          },
        ],
        linkToPosts:
          "https://collyge-back-collygedev.bunnyenv.com/api/admin/group/post",
      };

      return mockData;
    }
    return null;
  }, [id]);

  const updateDataChangeStatus = (status, id) => {
    console.log({ status, id });
  };

  const resetSelected = () => {
    console.log("resetSelected");
  };

  const { onChangeStatus } = useManageCommunity({
    callbackOnChangeStatus: updateDataChangeStatus,
  });

  const noDataMessage = useMemo(() => {
    return data ? textMessages.noDataFound : textMessages.noDataUserList;
  }, [data]);

  const bredcrumbs = [
    {
      path: "/",
      name: t("dashboard.title"),
    },
    {
      path: "/communities",
      name: t("community.title"),
    },
    {
      path: `/community/1`,
      name: t("community.detail"),
    },
  ];

  const actionFormatter = useCallback((_cell, row) => {
    return (
      <div className="dropdown">
        <a
          href="/"
          onClick={(e) => e.preventDefault()}
          className="dropdown-toggle"
          id={`dropdownMenuButton_${row.id}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="ni ni-more-h"></span>
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <GoToDetailPage
            classProps={"dropdown-item"}
            data={"Posts"}
            pathname={`/community-posts/11`}
          />
        </div>
      </div>
    );
  }, []);

  const columns = useMemo(() => {
    const localColumns = [
      {
        dataField: "id",
        text: t("common.sNo"),
        sort: true,
      },
      {
        dataField: "profilePictureThumbUrl",
        text: t("common.name"),
        headerAlign: "left",
        align: "left",
        formatter: nameWithImageFormatter,
      },
      {
        dataField: "email",
        text: t("common.email"),
      },
      {
        dataField: "status",
        text: t("common.status"),
        headerAlign: "left",
        align: "left",
        formatter: (cell, row) => statusFormatter(cell, row, console.log, ""),
      },
      {
        dataField: "isDummyField",
        text: t("common.action"),
        headerAlign: "left",
        align: "left",
        formatter: actionFormatter,
      },
    ];

    return localColumns;
  }, [t, actionFormatter]);

  return (
    <>
      <MetaTags
        title={`${config.NAME_TITLE} | ${t("community.detail")}`}
        description={`${t("community.detail")} of ${config.NAME_TITLE}`}
      />
      <main className="mainContent userDetail">
        <div className="container-fluid">
          <div className="page-title-row page-title-btn d-flex">
            <div className="page-title-row__left">
              <BreadCrumb bredcrumbs={bredcrumbs} />
              <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                {t("community.detail")}
              </h1>
            </div>
            <div className="page-title-row__right">
              <div className="btnBox d-block d-sm-flex w-100">
                <button
                  className="btn btn-sm btn-outline-secondary mobile-btn back-btn ripple-effect text-uppercase"
                  onClick={() => history.goBack()}
                >
                  <i className="icon-back-arrow"></i> {t("common.back")}
                </button>
              </div>
            </div>
          </div>
          <div className="view-detail mb-3">
            <div className="view-detail-header">
              <div className="d-block d-sm-flex align-items-start">
                <div className="customer-img">
                  <ImageComponent src={data.icon} />
                </div>
                <div className="view-detail-content d-block d-xl-flex align-items-start justify-content-between w-100">
                  <div className="view-detail-left w-100">
                    <div className="d-sm-flex justify-content-between">
                      <h4 className="view-detail-name">
                        {data.name}

                        <StatusBadgeComponent
                          status={data.status}
                          data={data}
                        />
                      </h4>
                      <div className="d-flex align-items-center mt-2 mt-sm-0">
                        <span className="d-block mr-2">
                          {t("common.status")}
                        </span>
                        {statusFormatter(data.status, data, onChangeStatus, "")}
                      </div>
                    </div>
                    <ul className="list-unstyled d_block mb-0 info-list">
                      <li className="list-inline-item">
                        <span className="d-block mb-1">
                          {t("community.postCount")}
                        </span>
                        {data.postCount}
                      </li>
                      <li className="list-inline-item">
                        <span className="d-block mb-1">
                          {t("community.memberCount")}
                        </span>
                        {data.memberCount}
                      </li>
                      <li className="list-inline-item">
                        <span className="d-block mb-1">
                          {t("community.lastPostDate")}
                        </span>
                        {dateFormatter(data.lastPostDate)}
                      </li>
                      <li className="list-inline-item">
                        <span className="d-block mb-1">
                          {t("community.privacy")}
                        </span>
                        {data.type}
                      </li>
                      <li className="list-inline-item">
                        <span className="d-block mb-1">
                          {t("community.owner")}
                        </span>
                        {data.ownerName}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="custom-detail mb-3">
            <RemoteDataTable
              data={data.members}
              page={pagination.page}
              sizePerPage={pagination.sizePerPage}
              totalSize={pagination.totalSize}
              onTableChange={console.log}
              isFilter={false}
              columns={columns}
              resetSelected={resetSelected}
              defaultSorted={[
                {
                  dataField: "id",
                  order: "desc",
                },
              ]}
              loading={isLoading}
              noDataMessage={noDataMessage}
              handleSelectedRows={console.log}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default CommuntyDetail;
