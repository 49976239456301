import React, { Component } from "react";
import { confirm } from "react-confirm-box";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import {
  StatusBadgeComponent,
  ImageComponent,
  VideoComponent,
  GoToUserDetailPage,
  UsernameComponent,
  EmailComponent,
} from "../../components/common";
import LoadingView from "../../components/loadingView";
import ArtistList from "../../components/modals/artistList";
import DeactivateReason from "../../components/modals/deactivateReason";
import NotFoundView from "../../components/notFoundView";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import { dateFormatter, statusFormatter } from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

const options = {
  render: (message, onConfirm, onCancel) => {
    return (
      <div className="confirm-box__content">
        <span> {message} </span>
        <div className="confirm-box__actions">
          <button
            className="ant-btn btn btn-primary ripple-effect"
            onClick={onConfirm}
          >
            {" "}
            Yes{" "}
          </button>
          <button
            className="ant-btn btn btn-outline-dark ripple-effect"
            onClick={onCancel}
          >
            {" "}
            No{" "}
          </button>
        </div>
      </div>
    );
  },
};

class VideoDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showArtistListModal: false,
      showArtistListData: [],
      currentTrackName: "",
      data: {},
      isLoading: true,
      error: false,
      bredcrumbs: [],
      videoId: 0,
      status: "",
      showReason: false,
      showConfirm: false,
      isReasonLoading: false,
    };
  }

  componentDidMount() {
    this.loadVideoData(this.props.match.params.videoid);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.videoid !== this.props.match.params.videoid ||
      prevProps.status !== this.props.status
    ) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.loadVideoData(this.props.match.params.videoid);
        }
      );
    }
  }

  updateBredcrums = () => {
    const { t } = this.props;
    let bredcrumbs = [
      {
        path: "/",
        name: t("dashboard.title"),
      },
      {
        path: "/manage-video",
        name: t("video.title"),
      },
      {
        path: `/video-detail/${this.state.data.id}`,
        name: t("video.detail"),
      },
    ];

    if (
      this.props.history.location.state &&
      Object.keys(this.props.history.location.state).length > 0 &&
      "bredcrumbs" in this.props.history.location.state
    ) {
      bredcrumbs = this.props.history.location.state.bredcrumbs;
      bredcrumbs.push({
        path: `/video-detail/${this.state.data.id}`,
        name: t("video.detail"),
      });
    }
    this.setState({
      bredcrumbs,
    });
  };

  loadVideoData = async (id) => {
    try {
      const payload = {
        ...ApiEndPoints.getSpecificFeed(id),
      };
      const res = await APIrequest(payload);
      this.setState(
        {
          isLoading: false,
          error: false,
          data: res.data,
        },
        () => {
          this.updateBredcrums();
        }
      );
    } catch (error) {
      logger({ "error:": error });
      this.setState({
        isLoading: false,
        error: true,
      });
    }
  };

  onchangeStatus = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }
      const result = await confirm(
        `Are you sure want to ${status} this video?`,
        options
      );
      if (result) {
        const payload = {
          ...ApiEndPoints.updateStatusFeed(row.id),
          bodyData: {
            status,
          },
        };
        const res = await APIrequest(payload);
        modalNotification({
          type: "success",
          message: "Success",
          description: res.message || textMessages.statusUpdate,
        });
        this.setState({
          ...this.state,
          data: {
            ...this.state.data,
            status,
          },
          videoId: row.id,
          showConfirm: true,
        });
      }
      resHandleChange(status);
    } catch (error) {
      resHandleChange(row.status);
      logger({ "error:": error });
    }
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  artistListModal = (data) => {
    this.setState(
      {
        showArtistListData: data,
        currentTrackName: this.state.data.AudioTrack.title,
      },
      () => {
        this.handleToggle("showArtistListModal");
      }
    );
  };

  artistListModalHide = () => {
    this.setState(
      {
        showArtistListData: [],
        currentTrackName: "",
      },
      () => {
        this.handleToggle("showArtistListModal");
      }
    );
  };
  hideReasonModal = () => {
    this.setState(
      {
        ...this.state,
        showReason: false,
        showConfirm: false,
        videoId: 0,
        status: "",
      },
      () => {
        this.loadVideoData(this.props.match.params.videoid);
        this.forceUpdate();
      }
    );
  };
  ReasonModalController = async (selectedReason) => {
    try {
      if (selectedReason) {
        this.setState({
          ...this.state,
          isReasonLoading: true,
        });
      }
      const status = this.state.status;
      const payload = {
        ...ApiEndPoints.updateStatusFeed(this.state.videoId),
        bodyData: {
          status,
          selectedReason,
        },
      };
      const res = await APIrequest(payload);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || textMessages.statusUpdate,
      });
      this.setState(
        {
          ...this.state,
          isReasonLoading: false,
          showReason: false,
          showConfirm: false,
          status: "",
        },
        () => {
          this.loadVideoData(this.props.match.params.videoid);
          this.forceUpdate();
        }
      );
    } catch (error) {
      logger(error);
    }
  };

  render() {
    const {
      isLoading,
      error,
      data,
      showArtistListModal,
      showArtistListData,
      currentTrackName,
      bredcrumbs,
    } = this.state;

    const { t } = this.props;

    if (isLoading) {
      return <LoadingView />;
    }

    if (error) {
      return <NotFoundView />;
    }

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("video.detail")}`}
          description={`${t("video.detail")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent userDetail">
          <div className="container-fluid">
            <div className="page-title-row d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                  {t("video.detail")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div className="btnBox d-block d-sm-flex w-100">
                  <button
                    className="btn btn-sm btn-outline-secondary mobile-btn back-btn ripple-effect text-uppercase"
                    onClick={() => this.props.history.goBack()}
                  >
                    <i className="icon-back-arrow"></i> {t("common.back")}
                  </button>
                </div>
              </div>
            </div>
            <div className="view-detail mb-3">
              <div className="view-detail-header">
                <div className="d-block d-sm-flex align-items-start">
                  <div className="customer-img">
                    <ImageComponent src={data.User.profilePictureThumbUrl} />
                  </div>
                  <div className="view-detail-content d-block d-xl-flex align-items-start justify-content-between w-100">
                    <div className="view-detail-left w-100">
                      <div className="d-sm-flex justify-content-between">
                        <h4 className="view-detail-name">
                          {data.User.name}
                          <StatusBadgeComponent status={data.User.status} />
                        </h4>
                      </div>
                      <ul className="list-unstyled customer-info-list d_block mt-2 mb-0">
                        <li className="list-inline-item">
                          <UsernameComponent
                            username={
                              <GoToUserDetailPage
                                data={data.User.username}
                                id={data.User.id}
                                state={{ bredcrumbs }}
                              />
                            }
                          />
                        </li>
                        <li className="list-inline-item">
                          <img alt="mail-icon" src="/images/mail.svg" />{" "}
                          <span>
                            <EmailComponent emailId={data.User.email} />
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div className="commonBox bg-white p-30 mb-3 mb-lg-0">
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="font-semi h-20 mb-0">{t("common.video")}</h5>
                    <StatusBadgeComponent status={data.status} />
                    <div className="d-flex align-items-center ml-auto  mt-2 mt-sm-0">
                      <span className="d-block mr-2">
                        {t("common.status")}{" "}
                      </span>
                      {statusFormatter(
                        data.status,
                        data,
                        this.onchangeStatus,
                        "",
                        "video"
                      )}
                    </div>
                  </div>
                  <ul className="list-unstyled mt-2 mb-0 aVlist">
                    <li className="list-inline-item w-100">
                      <VideoComponent
                        src={data.mediaFileUrl}
                        imgSrc={data.mediaFileThumbUrl}
                        videoprops={{
                          className: "img-thumbnail",
                        }}
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="custom-detail mb-3 text-capitalize h-100">
                  <h5 className="font-semi h-20">{t("video.detail")}</h5>

                  <div className="row g-3">
                    <div className="col-sm-6 mb-3">
                      <span>Creator Info</span>
                      <br />
                      <strong>{data.User.username}</strong>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <span>Video Category</span>
                      <br />
                      {data.FeedCategories.length > 0 ? (
                        <>
                          {data.FeedCategories.map((d, i) => {
                            return <strong>{d.Category.name}</strong>;
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-sm-12 mb-3">
                      <span>{t("video.description")}</span>
                      <br />
                      <strong>{data.title}</strong>
                    </div>
                    <div className="col-sm-12 mb-3">
                      <span>{t("hashtag.tags")}</span>
                      <br />
                      <div className="badge-wrapper">
                        {
                          <>
                            {data.FeedTags.length > 0 ? (
                              <>
                                {data.FeedTags.map((d, i) => {
                                  return (
                                    <span
                                      key={`${d.id}_video`}
                                      className="badge badge-info"
                                    >{`#${d.Tag.title}`}</span>
                                  );
                                })}
                              </>
                            ) : (
                              <strong className="theme-color">
                                {t("hashtag.noTagsAdded")}
                              </strong>
                            )}
                          </>
                        }
                      </div>
                    </div>
                    <div className="col-sm-12 mb-3">
                      <span>Location</span>
                      <br />
                      <strong>{data.location}</strong>
                    </div>
                    <div className="col-sm-12 mb-3">
                      <span>Tagged Users</span>
                      <br />
                      <div className="badge-wrapper">
                        {
                          <>
                            {data.FeedTagUsers.length > 0 ? (
                              <>
                                {data.FeedTagUsers.map((d, i) => {
                                  return (
                                    <span
                                      key={`${d.id}_video`}
                                      className="badge badge-info"
                                    >{`@${d.User.username}`}</span>
                                  );
                                })}
                              </>
                            ) : (
                              <strong className="theme-color">
                                {t("hashtag.noTagsAdded")}
                              </strong>
                            )}
                          </>
                        }
                      </div>
                    </div>
                    <div className="col-sm-6 mb-3">
                      <span>Status</span>
                      <br />
                      <StatusBadgeComponent status={data.status} />
                    </div>
                    <div className="col-sm-6 mb-3">
                      <span>Create Date</span>
                      <br />
                      <strong>{dateFormatter(data.createdAt)}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* Artist list */}
        <ArtistList
          data={showArtistListData}
          title={currentTrackName}
          show={showArtistListModal}
          onHide={() => this.artistListModalHide()}
        />
        <DeactivateReason
          show={this.state.showReason}
          onHide={this.hideReasonModal}
          controller={this.ReasonModalController}
          loading={this.state.isReasonLoading}
        />
      </>
    );
  }
}

VideoDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(VideoDetail);
