import config from "../config";
import { handlePrizeTitileChange } from "../utilities/common";
import textMessages from "./messages";
const EMAIL = [
  {
    required: true,
    whitespace: true,
    message: textMessages.enterEmail,
  },
  {
    type: "email",
    message: textMessages.enterValidEmail,
  },
];

const PASSWORD = [
  {
    required: true,
    // whitespace: false,
    message: textMessages.enterPassword,
  },
];

const PASSWORD_LENGTH = [
  {
    min: 6,
    message: textMessages.passwordLengthMessage(6, 15),
  },
  {
    max: 15,
    message: textMessages.passwordLengthMessage(6, 15),
  },
];

const ALPHA_NUMERIC = [
  {
    pattern: /^[a-zA-Z0-9\-\s]+$/,
    message: textMessages.alphaNumericOnly,
  },
];
const WHITESPACE = [
  {
    pattern: /^\S*$/,
    message: textMessages.spaceNotAllow,
  },
];

const CHAR_LENGTH = (min, max) => [
  {
    min: min,
    message: textMessages.enterMinChar(min),
  },
  {
    max: max,
    message: textMessages.enterMaxChar(max),
  },
];

const validation = {
  contest: {
    name: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterContestName,
      },
      ...CHAR_LENGTH(3, 25),
      ...ALPHA_NUMERIC,
    ],
    videoIntervalLimit: [
      {
        required: true,
        pattern: /^[1-9]\d*$/,
        message: textMessages.entervideoIntervalLimit,
      },
    ],
    tagId: [
      {
        required: true,
        message: textMessages.enterTagId,
      },
    ],
    shopifyProductId: [
      {
        required: true,
        message: textMessages.enterContestWinnerPrizeCorrectTitle,
      },
    ],
    winnerPrizeTitle: [
      {
        required: true,
        message: textMessages.enterContestWinnerPrizeTitle,
      },
      ({ getFieldValue }) => ({
        async validator(rule, value) {
          if (value) {
            let res = await handlePrizeTitileChange(value);
            if (res) {
              return Promise.resolve();
            }
            return Promise.reject(
              textMessages.enterContestWinnerPrizeCorrectTitle
            );
          }
        },
      }),
    ],
    selectPrizeTitle: [
      ({ getFieldValue }) => ({
        async validator(rule, value) {
          if (value) {
            let res = await handlePrizeTitileChange(value);
            if (res) {
              return Promise.resolve();
            }
            return Promise.reject(
              textMessages.enterContestWinnerPrizeCorrectTitle
            );
          }
        },
      }),
    ],
    rules: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterContestRules,
      },
    ],
    createdAt: [
      {
        required: true,
        message: textMessages.enterCreateAt,
      },
    ],
    prizeImage: [
      {
        required: true,
        whitespace: true,
        message: textMessages.selectContestPrizeImage,
      },
    ],
    contestImage: [
      {
        required: true,
        message: textMessages.selectContestContestImage,
      },
    ],
  },
  approver: {
    name: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterApproverName,
      },
      ...CHAR_LENGTH(3, 20),
      ...ALPHA_NUMERIC,
    ],
    email: EMAIL,
    password: [...PASSWORD, ...PASSWORD_LENGTH],
    newPassword: [
      {
        required: false,
        whitespace: true,
        message: textMessages.enterPassword,
      },
      ...PASSWORD_LENGTH,
    ],
    userType: [
      {
        required: true,
        message: textMessages.selectUserRole,
      },
    ],
    roleId: [
      {
        required: true,
        message: textMessages.selectRole,
      },
    ],
  },
  category: {
    name: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterCategoryName,
      },
      ...CHAR_LENGTH(3, 20),
      ...ALPHA_NUMERIC,
    ],
  },
  role: {
    name: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterRole,
      },
      ...CHAR_LENGTH(3, 20),
      ...ALPHA_NUMERIC,
    ],
  },
  collection: {
    name: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterCollectionName,
      },
      ...CHAR_LENGTH(3, 50),
      // ...ALPHA_NUMERIC
    ],
    currentOrder: (max) => [
      {
        required: true,
        message: textMessages.enterCollectionCurrentOrder,
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          // ORDER_RANGE(getFieldValue, value, max)
          if (Number(value) <= Number(max)) {
            return Promise.resolve();
          }
          return Promise.reject(textMessages.orderLengthMessage(1, max));
        },
      }),
    ],
    newOrder: (max) => [
      {
        required: true,
        message: textMessages.enterCollectionNewOrder,
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          // ORDER_RANGE(getFieldValue, value, max)
          if (Number(value) <= Number(max) && Number(value) > 0) {
            return Promise.resolve();
          }
          return Promise.reject(textMessages.orderLengthMessage(1, max));
        },
      }),
    ],
    collectionId: [
      {
        required: true,
        message: textMessages.selectCollection,
      },
    ],
  },
  artist: {
    name: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterArtistName,
      },
      ...CHAR_LENGTH(3, 25),
    ],
    icon: [
      {
        required: true,
        whitespace: true,
        message: textMessages.selectArtistIcon,
      },
    ],
  },
  album: {
    name: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterAlbumName,
      },
      ...CHAR_LENGTH(3, 25),
    ],
    icon: [
      {
        required: true,
        whitespace: true,
        message: textMessages.selectAlbumIcon,
      },
    ],
    categoryId: [
      {
        required: true,
        message: textMessages.selectAlbumCategory,
      },
    ],
  },
  track: {
    title: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterTrackName,
      },
      ...CHAR_LENGTH(3, 50),
    ],
    masterId: [
      {
        required: false,
        whitespace: true,
        message: textMessages.enterTrackMasterId,
      },
      ...CHAR_LENGTH(3, 50),
      ...ALPHA_NUMERIC,
    ],
    length: [
      {
        required: true,
        message: textMessages.enterTrackLength,
      },
    ],
    mediaFile: [
      {
        required: true,
        whitespace: true,
        message: textMessages.selectTrackIcon,
      },
    ],
    artistId: [
      {
        required: true,
        message: textMessages.selectTrackArtist,
      },
    ],
    albumId: [
      {
        required: true,
        message: textMessages.selectTrackAlbum,
      },
    ],
  },
  preference: {
    languageIds: [
      {
        required: true,
        message: textMessages.selectLanguage,
      },
    ],
    categoryIds: [
      {
        required: true,
        message: textMessages.selectCategory,
      },
    ],
  },
  tag: {
    title: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterTagName,
      },
      ...CHAR_LENGTH(3, 20),
      {
        pattern: /^[^\\#|\s]*$/,
        message: textMessages.validTagMessage,
      },
    ],
  },
  interval: {
    interval: [
      {
        required: true,
        message: textMessages.enterIntervalTime,
      },
      {
        pattern: /^[1-9]\d*$/,
        message: textMessages.timeIntervalError,
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (value % config.VIDEO_UPLOAD_LENGTH_MINIMUM === 0) {
            return Promise.resolve();
          }
          const errShow = textMessages.timeIntervalMustInError;
          return Promise.reject(errShow);
        },
      }),
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (value <= config.VIDEO_UPLOAD_LENGTH_LIMIT) {
            return Promise.resolve();
          }
          const errShow = textMessages.timeIntervalMaxError(
            config.VIDEO_UPLOAD_LENGTH_LIMIT
          );
          return Promise.reject(errShow);
        },
      }),
    ],
    followers: [
      {
        required: true,
        message: textMessages.enterIntervalTimeFollowers,
      },
      {
        pattern: /^[0-9]\d*$/,
        message: textMessages.followerIntervalError,
      },
    ],
  },
  point: {
    points: [
      {
        required: true,
        message: textMessages.enterPoint,
      },
      {
        pattern: /^[0-9]*$/,
        message: textMessages.pointValueMessage,
      },
    ],
  },
  setting: {
    settings: [
      {
        required: true,
        message: textMessages.enterSetting,
      },
      {
        pattern: /^[0-9]*$/,
        message: textMessages.pointValueMessage,
      },
    ],
  },
  replyMessage: {
    response: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterResponse,
      },
      ...CHAR_LENGTH(3, 1000),
    ],
  },
  notification: {
    message: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterNotificationMessage,
      },
      ...CHAR_LENGTH(3, 1000),
    ],
  },
  cms: {
    title: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterCMSTitle,
      },
      ...CHAR_LENGTH(3, 25),
    ],
    content: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterCMSContent,
      },
    ],
  },
  faq: {
    question: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterFAQquestion,
      },
      ...CHAR_LENGTH(3, 200),
    ],
    answer: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterFAQanswer,
      },
      ...CHAR_LENGTH(3, 1000),
    ],
  },
  college: {
    name: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterCollegeName,
      },
      ...CHAR_LENGTH(3, 25),
      ...ALPHA_NUMERIC,
    ],
    handle: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterCollegeHandle,
      },
      ...CHAR_LENGTH(3, 25),
      ...ALPHA_NUMERIC,
    ],
    cityid: [
      {
        required: true,
        message: textMessages.selectCollegeCity,
      },
    ],
    stateid: [
      {
        required: true,
        message: textMessages.selectCollegeState,
      },
    ],
  },
  collegeMates: {
    userId: [
      {
        required: true,
        message: textMessages.selectUserName,
      },
    ],
    collegeId: [
      {
        required: true,
        message: textMessages.selectCollegeName,
      },
    ],
    isAmbassador: [
      {
        required: true,
        message: textMessages.selectIsAmbassador,
      },
    ],
    mateType: [
      {
        required: true,
        message: textMessages.selectMateType,
      },
    ],
  },
  changePassword: {
    currentPassword: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterCurrentPassword,
      },
    ],
    newPassword: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterNewPassword,
      },
      ...PASSWORD_LENGTH,
    ],
    confirmNewPassword: [
      {
        required: true,
        whitespace: true,
        message: textMessages.reEnterNewPassword,
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue("newPassword") === value) {
            return Promise.resolve();
          }
          return Promise.reject(textMessages.passwordNotMatchMessage);
        },
      }),
    ],
  },
  login: {
    email: EMAIL,
    password: [...WHITESPACE, ...PASSWORD],
  },
  forgotPassword: {
    email: EMAIL,
  },
  resetPassword: {
    password: [...PASSWORD, ...PASSWORD_LENGTH],
    confirm: [
      {
        required: true,
        whitespace: true,
        message: textMessages.reEnterPassword,
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }

          return Promise.reject(textMessages.passwordNotMatchMessage);
        },
      }),
    ],
  },
  username: {
    name: [
      {
        required: true,
        whitespace: true,
        message: textMessages.enterReserveUsername,
      },
      ...CHAR_LENGTH(3, 20),
      {
        pattern: /^[^\\#|\s]*$/,
        message: textMessages.validReserveUsernameMessage,
      },
    ],
  },
};

const inputParser = {
  number: (value) => {
    value = value.replace(/[^0-9]/g, "");
    const regexNum = /^[0-9]*$/;
    if (regexNum.test(value)) {
      return value;
    }
  },
};

export default validation;
export { inputParser };
