import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";
import ReserveUsername from "../forms/reserverUsername";

export default function ReserveUsernameAddEdit(props) {
  const { t } = useTranslation();
  const [isSpin, setIsSpin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isEditFormType, setIsEditFormType] = useState(
    Object.keys(props.data).length > 0
  );

  useEffect(() => {
    const isEditFormType = Object.keys(props.data).length > 0;
    setIsEditFormType(isEditFormType);
    setErrorMsg("");
    setIsSpin(false);
  }, [props.show, props.data]);

  const onFinish = async (values) => {
    setIsSpin(true);
    setErrorMsg("");
    try {
      let payload = {
        ...ApiEndPoints.addReserveUsername,
        bodyData: {
          name: values.name,
          status: values.status,
        },
      };

      if (isEditFormType) {
        payload = {
          ...payload,
          ...ApiEndPoints.editReserveUsername(props.data.id),
        };
      }

      const res = await APIrequest(payload);
      setIsSpin(false);
      modalNotification({
        type: "success",
        message: "Success",
        description:
          res.message ||
          (isEditFormType
            ? textMessages.updateTagMessage
            : textMessages.addTagMessage),
      });
      props.onSubmitSuccess();
      props.onHide();
    } catch (error) {
      setIsSpin(false);
      setErrorMsg(error.message);
      logger({ "error:": error });
    }
  };

  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-dialog-scrollable"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{isEditFormType ? t("username.edit") : t("username.add")}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReserveUsername
          onHide={props.onHide}
          isSpin={isSpin}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={
            isEditFormType
              ? {
                  name: props.data.name,
                  status: props.data.status,
                }
              : {
                  status: "active",
                }
          }
          submitButtonText={
            isEditFormType ? t("common.update") : t("common.save")
          }
          errorMsg={errorMsg}
        />
      </Modal.Body>
    </Modal>
  );
}

ReserveUsernameAddEdit.propTypes = {
  data: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};
