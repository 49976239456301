import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Form, Input, Button, DatePicker, Badge, Select } from "antd";

import BreadCrumb from "../../components/breadCrumb";
import { GoToDetailPage } from "../../components/common";
import RemoteDataTable from "../../components/dataTable";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  dateFormatDMY,
  dateFormatter,
  filterDataObj,
  statusFormatter,
} from "../../utilities/common";
import { DEFAULT_PAGINATION } from "../../utilities/constants";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import { useManageCommunity } from "./useManageCommunity";

const ManageCommunity = () => {
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [data, setData] = useState([]);
  const [totalPageSize, setTotalPageSize] = useState(0);
  const [queryParams, setQueryParams] = useState(DEFAULT_PAGINATION);

  const formRef = useRef();

  const updateDataChangeStatus = (status, id) => {
    setData((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status: status,
          };
        }
        return item;
      })
    );
  };

  const { onChangeStatus } = useManageCommunity({
    callbackOnChangeStatus: updateDataChangeStatus,
  });

  const noDataMessage = useMemo(() => {
    return filterCount > 0
      ? textMessages.noDataFound
      : textMessages.noDataCommunityList;
  }, [filterCount]);

  const handleFilter = useCallback(() => {
    document.body.classList.toggle("overflow-hidden", !openFilter);
    setOpenFilter(!openFilter);
  }, [openFilter]);

  const actionFormatter = (_cell, row) => {
    return (
      <div className="dropdown">
        <a
          href="/"
          onClick={(e) => e.preventDefault()}
          className="dropdown-toggle"
          id={`dropdownMenuButton_${row.id}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="ni ni-more-h"></span>
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <GoToDetailPage
            classProps={"dropdown-item"}
            data={"View"}
            pathname={`/community/${row.id}`}
          />
          <GoToDetailPage
            classProps={"dropdown-item"}
            data={"Posts"}
            pathname={`/community-posts/${row.id}`}
          />
        </div>
      </div>
    );
  };

  const columns = useMemo(() => {
    const localColumns = [
      {
        dataField: "id",
        text: t("common.sNo"),
        sort: true,
      },
      {
        dataField: "name",
        text: t("community.name"),
        headerAlign: "left",
        align: "left",
        sort: true,
      },
      {
        dataField: "postCount",
        text: t("community.postCount"),
        headerAlign: "left",
        align: "left",
        sort: true,
      },
      {
        dataField: "memberCount",
        text: t("community.memberCount"),
        headerAlign: "left",
        align: "left",
        sort: true,
      },
      {
        dataField: "lastPostDate",
        text: t("community.lastPostDate"),
        headerAlign: "left",
        align: "left",
        sort: true,
        formatter: dateFormatter,
      },
      {
        dataField: "type",
        text: t("common.privacy"),
        headerAlign: "left",
        align: "left",
        sort: true,
        style: {
          textTransform: "capitalize",
        },
      },
      {
        dataField: "ownerName",
        text: t("community.owner"),
        headerAlign: "left",
        align: "left",
      },
      {
        dataField: "status",
        text: t("common.status"),
        headerAlign: "left",
        align: "left",
        formatter: (cell, row) =>
          statusFormatter(cell, row, onChangeStatus, ""),
      },
      {
        dataField: "isDummyField",
        text: t("common.action"),
        headerAlign: "left",
        align: "left",
        formatter: actionFormatter,
      },
    ];

    return localColumns;
  }, [onChangeStatus, t]);

  useEffect(() => {
    setIsLoading(true);
    const { filterData, ...restParams } = queryParams;

    const payload = {
      ...ApiEndPoints.getGroup,
      queryParams: {
        ...restParams,
        ...filterData,
      },
    };

    APIrequest(payload)
      .then((res) => {
        if (res) {
          setData(res.data.rows);
          setTotalPageSize(res.data.rows.length > 0 ? res.data.total : 0);
        }
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [queryParams]);

  const onFinish = (values) => {
    const { filterData, filterCount } = filterDataObj(values);
    setFilterCount(filterCount);
    setQueryParams((prevState) => ({
      ...prevState,
      offset: 0,
      limit: 10,
      filterData,
    }));
    handleFilter();
  };

  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  const onReset = useCallback(() => {
    setFilterCount(0);
    setQueryParams((prevState) => ({
      ...prevState,
      filterData: undefined,
    }));
    setTimeout(() => {
      formRef.current.resetFields();
    }, [1000]);
  }, [formRef]);

  const handleTableChange = (
    _type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setQueryParams((prevState) => ({
      ...prevState,
      offset: (page - 1) * sizePerPage,
      limit: sizePerPage,
      sortBy: sortField,
      sortType: sortOrder,
    }));
  };

  return (
    <>
      <MetaTags
        title={`${config.NAME_TITLE} | ${t("community.title")}`}
        description={`${t("community.title")} of ${config.NAME_TITLE}`}
      />

      <main className="mainContent">
        <div className="container-fluid">
          <div className="page-title-row page-title-btn d-flex">
            <div className="page-title-row__left">
              <BreadCrumb
                bredcrumbs={[
                  {
                    name: t("dashboard.title"),
                    path: "/",
                  },
                  {
                    name: t("community.title"),
                  },
                ]}
              />
              <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                {t("community.communityList")}
              </h1>
            </div>
            <div className="page-title-row__right">
              <div
                className={`filterForm ${openFilter ? "filterForm--open" : ""}`}
              >
                <div className="filterForm__head">
                  <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFilter();
                    }}
                    id="filterClose"
                  >
                    <i className="icon-cross"></i>
                  </a>
                </div>
                <div className="filterForm__body">
                  <Form
                    name="communityFilter"
                    className="form-inline"
                    ref={formRef}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={queryParams.filterData}
                  >
                    <div className="form-group">
                      <label>{t("community.name")}</label>
                      <Form.Item name="name" value="">
                        <Input
                          className="form-control"
                          placeholder={t("community.name")}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <label>{t("community.memberCount")}</label>
                      <div className="d-flex justify-content-between gap-10">
                        <Form.Item name="countMembersFrom">
                          <Input
                            className="form-control"
                            placeholder={t("community.rangeFrom")}
                          />
                        </Form.Item>
                        <Form.Item name="countMembersTo">
                          <Input
                            className="form-control"
                            placeholder={t("community.rangeTo")}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>{t("community.postCount")}</label>
                      <div className="d-flex justify-content-between gap-10">
                        <Form.Item name="countPostsFrom">
                          <Input
                            className="form-control"
                            placeholder={t("community.rangeFrom")}
                          />
                        </Form.Item>
                        <Form.Item name="countPostsTo">
                          <Input
                            className="form-control"
                            placeholder={t("community.rangeTo")}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>{t("community.lastPostDate")}</label>
                      <Form.Item name="lastPostDate">
                        <DatePicker
                          placeholder={t("community.lastPostDate")}
                          format={dateFormatDMY}
                          className="w-100"
                          style={{ height: "44px" }}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <label>{t("common.status")} </label>
                      <Form.Item name="status">
                        <Select
                          className="form-control"
                          placeholder={t("common.status")}
                        >
                          <Select.Option value="">
                            {t("common.allStatus")}
                          </Select.Option>
                          <Select.Option value="active">
                            {t("common.active")}
                          </Select.Option>
                          <Select.Option value="inactive">
                            {t("common.inactive")}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="btn_clumn d-flex justify-content-between">
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          className="btn btn-primary ripple-effect"
                        >
                          {t("common.search")}
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          htmlType="button"
                          onClick={onReset}
                          className="btn btn-outline-dark ripple-effect"
                        >
                          {t("common.reset")}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="btnBox">
                <Badge count={filterCount}>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFilter();
                    }}
                    id="filter"
                    className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-0"
                  >
                    <i className="icon-filter-line"></i>
                  </a>
                </Badge>
              </div>
            </div>
          </div>
          <RemoteDataTable
            data={data}
            page={queryParams.page}
            sizePerPage={queryParams.limit}
            totalSize={totalPageSize}
            onTableChange={handleTableChange}
            isFilter={false}
            columns={columns}
            resetSelected={false}
            defaultSorted={[
              {
                dataField: "id",
                order: "desc",
              },
            ]}
            loading={isLoading}
            noDataMessage={noDataMessage}
          />
        </div>
      </main>
    </>
  );
};

export default ManageCommunity;
