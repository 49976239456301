import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Alert } from "antd";
import PropTypes from "prop-types";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";
import ChangePasswordForm from "../forms/changePassword";

export default function ChangePassword(props) {
  const { t } = useTranslation();
  const [isSpin, setIsSpin] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onFinish = async (values) => {
    setIsSpin(true);
    try {
      const payload = {
        ...ApiEndPoints.accountChangePassword,
        bodyData: {
          currentPassword: values.currentPassword,
          newPassword: values.newPassword,
          confirmNewPassword: values.confirmNewPassword,
        },
      };
      setIsAlert(false);
      const res = await APIrequest(payload);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || textMessages.passwordChangeSuccess,
      });
      setIsSpin(false);
      props.onHide();
    } catch (error) {
      setIsAlert(true);
      setIsSpin(false);
      setErrorMsg(error.message);
      logger({ "error:": error });
    }
  };
  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{t("changePassword.title")}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isAlert && (
          <Alert
            message={errorMsg || textMessages.currentPasswordError}
            className="mb-4"
            type="error"
          />
        )}
        <ChangePasswordForm
          onHide={props.onHide}
          isSpin={isSpin}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          submitButtonText={t("common.update")}
        />
      </Modal.Body>
    </Modal>
  );
}

ChangePassword.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};
