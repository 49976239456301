import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";

import { Alert } from "antd";

import { loginAction } from "../../redux/auth/authSlice";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  setSessionStorageToken,
  tagClassToggle,
  currentTimeStamp,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";
import LoginForm from "../forms/login";

const Login = () => {
  const dispatch = useDispatch();
  const [isSpin, setIsSpin] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const onFinish = async (values) => {
    setIsSpin(true);
    try {
      const payload = {
        ...ApiEndPoints.accountLogin,
        bodyData: {
          email: values.email,
          password: values.password,
          deviceType: "web",
          deviceId: `${currentTimeStamp()}`,
        },
      };
      const res = await APIrequest(payload);
      setIsSpin(false);
      setSessionStorageToken(res.data.token);
      modalNotification({
        type: "success",
        message: "Welcome back",
        description: res.message || textMessages.loginSuccessfully,
      });
      dispatch(loginAction(res.data));
    } catch (error) {
      setIsAlert(true);
      setIsSpin(false);
      setErrorMsg(error.message);
      logger({ "error:": error });
    }
  };

  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  useEffect(() => {
    tagClassToggle("body", "auth");
    return () => {
      tagClassToggle("body", "auth");
    };
  });

  return (
    <main className="mainContent pl-0 pr-0 pb-0">
      <section className="auth__section d-flex align-items-center justify-content-center">
        <div className="container-fluid">
          <div className="auth-area mx-auto ">
            <div className="auth-area__head text-center">
              <h1 className="font-bold">Login</h1>
            </div>
            <div className="auth-area__form shadow">
              {isAlert && (
                <Alert
                  message={errorMsg || textMessages.loginError}
                  className="mb-4"
                  type="error"
                />
              )}
              <LoginForm
                isSpin={isSpin}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                submitButtonText={"LOGIN"}
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default withRouter(Login);
