import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    categories: [],
    tags: [],
    audioTracks: [],
    artists: [],
    albums: [],
    collections: [],
    collectionsFeed: [],
    languages: [],
    videoCategories: [],
    userRoles: [],
    roles: [],
    userGroups: [],
    cities: [],
    states: [],
  },
  reducers: {
    addCategoriesAction: (state, action) => {
      return {
        ...state,
        categories: action.payload,
      };
    },
    addStatesAction: (state, action) => {
      return {
        ...state,
        states: action.payload,
      };
    },
    addCitiesAction: (state, action) => {
      return {
        ...state,
        cities: action.payload,
      };
    },
    addTagsAction: (state, action) => {
      return {
        ...state,
        tags: action.payload,
      };
    },

    addAudioTracksAction: (state, action) => {
      return {
        ...state,
        audioTracks: action.payload,
      };
    },
    addUserGroupsAction: (state, action) => {
      return {
        ...state,
        userGroups: action.payload,
      };
    },
    addArtistsAction: (state, action) => {
      return {
        ...state,
        artists: action.payload,
      };
    },
    addAlbumsAction: (state, action) => {
      return {
        ...state,
        albums: action.payload,
      };
    },
    addCollectionAction: (state, action) => {
      return {
        ...state,
        collections: action.payload,
      };
    },
    addCollectionFeedAction: (state, action) => {
      return {
        ...state,
        collectionsFeed: action.payload,
      };
    },
    addLanguagesAction: (state, action) => {
      return {
        ...state,
        languages: action.payload,
      };
    },
    addVideoCategoriesAction: (state, action) => {
      return {
        ...state,
        videoCategories: action.payload,
      };
    },
    addUserRolesAction: (state, action) => {
      return {
        ...state,
        userRoles: action.payload,
      };
    },
    addRolesAction: (state, action) => {
      return {
        ...state,
        roles: action.payload,
      };
    },
  },
});

export const {
  addCategoriesAction,
  addArtistsAction,
  addAlbumsAction,
  addCollectionAction,
  addCollectionFeedAction,
  addLanguagesAction,
  addVideoCategoriesAction,
  addUserRolesAction,
  addRolesAction,
  addTagsAction,
  addAudioTracksAction,
  addUserGroupsAction,
  addCitiesAction,
  addStatesAction,
} = commonSlice.actions;

export const selectCategories = (state) => state.common.categories;

export const selectCities = (state) => state.common.cities;

export const selectStates = (state) => state.common.states;

export const selectArtists = (state) => state.common.artists;

export const selectAlbums = (state) => state.common.albums;

export const selectCollections = (state) => state.common.collections;

export const selectFeedCollections = (state) => state.common.collectionsFeed;

export const selectLanguages = (state) => state.common.languages;

export const selectVideoCategories = (state) => state.common.videoCategories;

export const selectUserRoles = (state) => state.common.userRoles;

export const selectRoles = (state) => state.common.roles;

export const selectTags = (state) => state.common.tags;

export const selectAudioTracks = (state) => state.common.audioTracks;

export const selectUserGroups = (state) => state.common.userGroups;

export default commonSlice.reducer;
