import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Form } from "antd";
import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import FaqForm from "../../components/forms/faqForm";
import LoadingView from "../../components/loadingView";
import NotFoundView from "../../components/notFoundView";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

function EditFaq(props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSpin, setIsSpin] = useState(false);

  const faqData = async (id) => {
    try {
      const payload = {
        ...ApiEndPoints.getSpecificFaq(id),
      };
      const res = await APIrequest(payload);
      setError(false);
      setIsLoading(false);
      form.setFieldsValue({
        question: res.data.question,
        answer: res.data.answer,
      });
    } catch (error) {
      logger({ "error:": error });
      setError(true);
      setIsLoading(false);
    }
  };

  const goBack = () => {
    props.history.push("/faq");
  };

  const onFinish = async (values) => {
    setIsSpin(true);
    try {
      const payload = {
        ...ApiEndPoints.updateSpecificFaq(props.match.params.faqId),
        bodyData: {
          question: values.question,
          answer: values.answer,
        },
      };
      const res = await APIrequest(payload);
      setIsSpin(false);
      modalNotification({
        type: "success",
        message: "Updated",
        description: res.message || textMessages.updateFAQ,
      });
      goBack();
    } catch (error) {
      setIsSpin(false);
      logger({ "error:": error });
    }
  };

  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  useEffect(() => {
    faqData(props.match.params.faqId);
  }, [props.match.params.faqId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoadingView />;
  }

  if (error) {
    return <NotFoundView />;
  }

  return (
    <>
      <MetaTags
        title={`${config.NAME_TITLE} | ${t("faq.edit")}`}
        description={`${t("faq.edit")} of ${config.NAME_TITLE}`}
      />
      <main className="mainContent dashboard-pages">
        <div className="container-fluid">
          <div className="page-title-row filter-page-btn d-flex">
            <div className="page-title-row__left">
              <BreadCrumb
                bredcrumbs={[
                  {
                    path: "/",
                    name: t("dashboard.title"),
                  },
                  {
                    name: t("faq.edit"),
                  },
                ]}
              />
              <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                {t("faq.edit")}
              </h1>
            </div>
            <div className="page-title-row__right">
              <div className="btnBox d-block d-sm-flex w-100">
                <Link
                  to="/faq"
                  className="btn btn-sm btn-outline-secondary mobile-btn back-btn ripple-effect text-uppercase"
                >
                  <i className="icon-back-arrow"></i> {t("common.back")}
                </Link>
              </div>
            </div>
          </div>

          <div className="edit-cms__wrapper">
            <FaqForm
              isSpin={isSpin}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              goBack={goBack}
              submitButtonText={t("common.update")}
            />
          </div>
        </div>
      </main>
    </>
  );
}

EditFaq.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditFaq;
