import { createSlice } from "@reduxjs/toolkit";

export const manageVideoSlice = createSlice({
  name: "manageVideo",
  initialState: {
    filters: undefined,
  },
  reducers: {
    updateFilters: (state, action) => {
      return {
        ...state,
        filters: action.payload,
      };
    },
  },
});

export const { updateFilters } = manageVideoSlice.actions;

export default manageVideoSlice.reducer;
