import React from "react";

import PropTypes from "prop-types";

import Header from "../components/header";

const LoginLayout = (props) => {
  const { children } = props;

  return (
    <>
      <Header />
      {children}
    </>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default LoginLayout;
