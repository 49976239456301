import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Button } from "antd";
import PropTypes from "prop-types";

import logger from "../../utilities/logger";
import { LoadingSpinner } from "../common";

export default function Confirmation(props) {
  const { t } = useTranslation();
  const [isSpin, setIsSpin] = useState(false);

  const confirmation = async (val) => {
    try {
      if (val) {
        setIsSpin(true);
        await props.onConfirmation();
      }
      setIsSpin(false);
      props.onHide();
    } catch (error) {
      setIsSpin(false);
      logger({ "error:": error });
    }
  };

  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center text-center">
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="font-reg h-20 mb-0">{props.title}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="btn-row">
          <Button
            disabled={isSpin}
            className="btn btn-primary width-120 ripple-effect text-uppercase"
            onClick={() => confirmation(true)}
          >
            {isSpin ? <LoadingSpinner /> : t("common.yes")}
          </Button>
          <Button
            className="btn btn-outline-dark width-120 ripple-effect text-uppercase "
            onClick={() => confirmation(false)}
          >
            {t("common.no")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

Confirmation.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onConfirmation: PropTypes.func.isRequired,
};
