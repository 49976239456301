import {
  dateFormatter,
  goToUserDetail,
  imageFormatter,
  nameWithImageFormatter,
  readMoreText,
} from "./common";

export const getInitialState = ({
  props,
  defaultSorted,
  dataFieldColumns,
  customSerialNumberFormatter,
  customStatusFormatter,
}) => {
  const columns = dataFieldColumns
    ? dataFieldColumns
        .map((field) => {
          const column = getColumnsDataField({
            props,
            customSerialNumberFormatter,
            customStatusFormatter,
          }).find((item) => item.dataField === field);

          if (column) {
            return column;
          }

          return null;
        })
        .filter((item) => item)
    : undefined;

  return {
    isFirstTimeFetching: true,
    openFilter: false,
    replyMessageModal: false,
    openMessageReplyModal: false,
    isLoading: true,
    rowData: {},
    filterData: {},
    filterCount: 0,
    dataOfMessageReply: {},
    data: [],
    totalSize: 0,
    page: 1,
    sizePerPage: 10,
    editData: {},
    editOrderData: {},
    defaultSorted: defaultSorted ?? [
      {
        dataField: "id",
        order: "desc",
      },
    ],
    columns,
  };
};

const getColumnsDataField = ({
  props,
  customSerialNumberFormatter,
  customStatusFormatter,
}) => {
  return [
    {
      dataField: "id",
      text: props.t("common.id"),
      sort: true,
      hidden: true,
    },
    {
      dataField: "username",
      text: props.t("common.username"),
      headerAlign: "left",
      align: "left",
      sort: true,
    },
    {
      dataField: "User.profilePictureThumbUrl",
      text: props.t("common.image"),
      headerAlign: "left",
      align: "left",
      formatter: imageFormatter,
    },
    {
      dataField: "User.username",
      text: props.t("common.username"),
      headerAlign: "left",
      align: "left",
      sort: true,
      formatter: (cell, row) =>
        goToUserDetail(cell, row, row.User.id, {
          bredcrumbs: props.bredcrumbs,
        }),
    },
    {
      dataField: "User.name",
      text: props.t("common.name"),
      headerAlign: "left",
      align: "left",
      sort: true,
      style: {
        textTransform: "capitalize",
      },
    },
    {
      dataField: "email",
      text: props.t("common.email"),
      headerAlign: "left",
      align: "left",
      sort: true,
    },
    {
      dataField: "message",
      text: props.t("common.message"),
      headerAlign: "left",
      align: "left",
      classes: "w_460 text-wrap",
      formatter: (cell, row) =>
        readMoreText(cell, row, "message", this.showHideMoreText, props.t),
    },
    {
      dataField: "UserRequestResponse.response",
      text: props.t("common.reply"),
      headerAlign: "left",
      align: "left",
      classes: "w_460 text-wrap",
      formatter: (cell, row) =>
        readMoreText(cell, row, "reply", this.showHideMoreText, props.t),
    },
    {
      dataField: "createdAt",
      text: props.t("common.createdAt"),
      headerAlign: "left",
      align: "left",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "updatedAt",
      text: props.t("common.updatedAt"),
      headerAlign: "left",
      align: "left",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "profilePictureThumbUrl",
      text: props.t("common.name"),
      headerAlign: "left",
      align: "left",
      formatter: nameWithImageFormatter,
    },
    {
      dataField: "status",
      text: props.t("common.status"),
      headerAlign: "left",
      align: "left",
      formatter: customStatusFormatter,
    },
    {
      dataField: "isDummySno",
      text: props.t("common.sNo"),
      formatter: customSerialNumberFormatter,
    },
  ];
};

export const getColumnsState = ({
  dataFieldColumns,
  localColumns,
  initialColumns,
}) => {
  const columns = dataFieldColumns.map((item) => {
    const localColumn = localColumns.find(
      (itemLocal) => itemLocal.dataField === item
    );

    if (localColumn) {
      return localColumn;
    }

    const column = initialColumns.find(
      (columnDefaultState) => columnDefaultState.dataField === item
    );

    if (column) {
      return column;
    }

    return null;
  });

  return columns;
};
