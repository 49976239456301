import React, { PureComponent } from "react";
import { confirm } from "react-confirm-box";
import { CSVLink } from "react-csv";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { Form, Select, Input, Button, Badge, DatePicker } from "antd";
import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import RemoteDataTable from "../../components/dataTable";
import ArtistList from "../../components/modals/artistList";
import CollectionList from "../../components/modals/collectionList";
import CollectionManagement from "../../components/modals/collectionManagement";
import TrackAddEdit from "../../components/modals/trackAddEdit";
import config from "../../config";
import {
  addArtistsAction,
  addCollectionAction,
  addAlbumsAction,
} from "../../redux/common/commonSlice";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  statusFormatter,
  dateFormatDMY,
  serialNumberFormatter,
  getPageSizeFromURL,
  addPageSizeInURL,
  filterDataObj,
  audioFormatter,
  checkUserPermission,
} from "../../utilities/common";
import { getColumnsState, getInitialState } from "../../utilities/defaultState";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

const options = {
  render: (message, onConfirm, onCancel) => {
    return (
      <div className="confirm-box__content">
        <span> {message} </span>
        <div className="confirm-box__actions">
          <button
            className="ant-btn btn btn-primary ripple-effect"
            onClick={onConfirm}
          >
            {" "}
            Yes{" "}
          </button>
          <button
            className="ant-btn btn btn-outline-dark ripple-effect"
            onClick={onCancel}
          >
            {" "}
            No{" "}
          </button>
        </div>
      </div>
    );
  },
};

class ManageAudio extends PureComponent {
  formRef = React.createRef();

  constructor(props) {
    super(props);

    const dataFieldColumns = [
      "id",
      "isDummySno",
      "title",
      "Album.name",
      "isDummyFieldOne",
      "mediaFileUrl",
      "audioSource",
      "usedCount",
      "status",
      "isDummyField",
    ];

    const customSerialNumberFormatter = (_cell, _row, rowIndex) =>
      serialNumberFormatter(rowIndex, this.state.page, this.state.sizePerPage);

    const customStatusFormatter = (cell, row) =>
      statusFormatter(cell, row, this.onchangeStatus, "", "audio");

    const localColumns = [
      {
        dataField: "title",
        text: props.t("track.name"),
        headerAlign: "left",
        align: "left",
        sort: true,
        style: {
          textTransform: "capitalize",
        },
      },
      {
        dataField: "Album.name",
        text: props.t("album.name"),
        headerAlign: "left",
        align: "left",
        sort: true,
        style: {
          textTransform: "capitalize",
        },
      },
      {
        dataField: "isDummyFieldOne",
        text: props.t("artist.names"),
        headerAlign: "left",
        align: "left",
        style: {
          textTransform: "capitalize",
        },
        formatter: this.artistListFormatter,
        // sort: true,
      },
      {
        dataField: "mediaFileUrl",
        text: props.t("common.playPause"),
        headerAlign: "left",
        align: "left",
        formatter: audioFormatter,
      },
      {
        dataField: "audioSource",
        text: props.t("audio.audioSource"),
        headerAlign: "left",
        align: "left",
        sort: true,
        style: {
          textTransform: "capitalize",
        },
      },
      {
        dataField: "usedCount",
        text: props.t("audio.usedCount"),
        headerAlign: "left",
        align: "left",
        sort: true,
        style: {
          textTransform: "capitalize",
        },
      },
      {
        dataField: "isDummyField",
        text: props.t("common.action"),
        headerAlign: "left",
        align: "left",
        formatter: this.actionFormatter,
      },
    ];

    const initialState = getInitialState({
      props,
      dataFieldColumns,
      customSerialNumberFormatter,
      customStatusFormatter,
    });

    const { columns: initialColumns, ...defaultState } = initialState;

    const columns = getColumnsState({
      initialColumns,
      dataFieldColumns,
      localColumns,
    });

    this.state = {
      excelHeaders: [
        { label: "Master ID", key: "masterId" },
        { label: "Title", key: "title" },
        { label: "Album", key: "Album.name" },
        { label: "Audio Source", key: "audioSource" },
        { label: "Audio Uses", key: "usedCount" },
      ],
      excelData: [],
      showArtistListModal: false,
      showCollectionModal: false,
      showCollectionListModal: false,
      audioAddEditModal: false,
      ...defaultState,
      columns,
    };
  }

  componentDidMount() {
    this.fetchInfiniteArtistList();
    this.fetchInfiniteAlbumList();
    this.fetchInfiniteCollectionList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  audioAddModel = () => {
    this.setState(
      {
        editData: {},
      },
      () => {
        this.handleToggle("audioAddEditModal");
      }
    );
  };

  audioAddEdit = (row = {}) => {
    if (row.audioSource === "fytness" || row.audioSource === "custom") {
      this.setState(
        {
          editData: row,
        },
        () => {
          this.handleToggle("audioAddEditModal");
        }
      );
    } else {
      modalNotification({
        type: "info",
        message: "Alert",
        description: "You can not edit this audio.",
      });
    }
  };

  fetchInfiniteArtistList = async () => {
    try {
      const payload = {
        ...ApiEndPoints.getArtist,
        queryParams: {
          sortBy: "title",
          sortType: "asc",
          // status: 'active',
          listType: "all",
        },
      };
      const res = await APIrequest(payload);
      this.props.addArtistsRedux(res.data.rows);
    } catch (error) {
      logger({ "error:": error });
    }
  };

  fetchInfiniteAlbumList = async () => {
    try {
      const payload = {
        ...ApiEndPoints.getAlbum,
        queryParams: {
          sortBy: "title",
          sortType: "asc",
          // status: 'active',
          listType: "all",
        },
      };
      const res = await APIrequest(payload);
      this.props.addAlbumsRedux(res.data.rows);
    } catch (error) {
      logger({ "error:": error });
    }
  };

  fetchInfiniteCollectionList = async () => {
    try {
      const payload = {
        ...ApiEndPoints.getCollection,
        queryParams: {
          sortBy: "title",
          sortType: "asc",
          // status: 'active',
          listType: "all",
        },
      };
      const res = await APIrequest(payload);
      this.props.addCollectionRedux(res.data.rows);
    } catch (error) {
      logger({ "error:": error });
    }
  };

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchTrack(queryParams);
        }
      );
    }
  };

  fetchTrack = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      ...queryParams,
      ...this.state.filterData,
    };
    try {
      const payload = {
        ...ApiEndPoints.getTrack,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });

      // Get download report data
      queryParams = {
        offset: 0,
        limit: 1000,
        ...this.state.filterData,
      };
      const reportPayload = {
        ...ApiEndPoints.getTrack,
        queryParams,
      };
      const reportResult = await APIrequest(reportPayload);
      this.setState({
        excelData: reportResult.data.rows,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  /**
   * Local Formatter
   */
  actionFormatter = (cell, row) => {
    const { t } = this.props;
    if (["recordable"].includes(row.audioState)) {
      return (
        <>
          {" "}
          {checkUserPermission(this.props.userData, "audio") ? (
            <div className="dropdown">
              <a
                href="/"
                onClick={(e) => e.preventDefault()}
                className="dropdown-toggle"
                id={`dropdownMenuButton_${row.id}`}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="ni ni-more-h"></span>
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <a
                  className="dropdown-item"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    // this.manageAudioCollections(row)
                    this.audioAddEdit(row);
                  }}
                >
                  {t("common.edit")}
                </a>
              </div>
            </div>
          ) : (
            "-"
          )}
        </>
      );
    } else {
      return "-";
    }
  };

  manageAudioCollections = (trackData = {}) => {
    let showCollectionModal = false;

    if (Object.keys(trackData).length > 0) {
      showCollectionModal = true;
    }

    this.setState({
      showCollectionModal,
      trackData,
    });
  };

  handleFilter = () => {
    this.setState((state) => {
      document.body.classList.toggle("overflow-hidden", !state.openFilter);
      return {
        openFilter: !state.openFilter,
      };
    });
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        if (sortField === "Album.name") {
          sortField = "albumName";
        }
        if (sortField === "Album.Category.name") {
          sortField = "categoryName";
        }
        if (sortField === "isDummySno") {
          sortField = "id";
        }
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };

        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history);
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchTrack(queryParams);
        }
      }
    } else {
      this.fetchTrack(queryParams);
    }
  };

  artistListFormatter = (cell, row) => {
    const { t } = this.props;
    let artistsList = row.AudioArtists;
    artistsList = artistsList.slice(0, 2);
    const names = [];
    for (const element of artistsList) {
      names.push(element.Artist.name);
    }
    return (
      <>
        {names.join(", ")}{" "}
        <a
          href="/"
          className="theme-color"
          onClick={(e) => {
            e.preventDefault();
            this.artistListModal(row);
          }}
        >
          {t("common.viewAll")}
        </a>
      </>
    );
  };

  collectionListModal = (data) => {
    this.setState(
      {
        showCollectionListData: data ? data.CollectionTracks : [],
        currentTrackName: data ? data.title : "",
      },
      () => {
        this.handleToggle("showCollectionListModal");
      }
    );
  };

  artistListModal = (data) => {
    this.setState(
      {
        showArtistListData: data.AudioArtists,
        currentTrackName: data.title,
      },
      () => {
        this.handleToggle("showArtistListModal");
      }
    );
  };

  artistListModalHide = () => {
    this.setState(
      {
        showArtistListData: [],
        currentTrackName: "",
      },
      () => {
        this.handleToggle("showArtistListModal");
      }
    );
  };

  onchangeStatus = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }
      const result = await confirm(
        `Are you sure want to ${status} this audio?`,
        options
      );
      if (result) {
        const payload = {
          ...ApiEndPoints.updateStatusTrack(row.id),
          bodyData: {
            status,
          },
        };
        const res = await APIrequest(payload);
        modalNotification({
          type: "success",
          message: "Success",
          description: res.message || textMessages.statusUpdate,
        });
        const dataTemp = this.state.data;
        const indexData = dataTemp.findIndex((d) => d.id === row.id);
        if (indexData > -1) {
          dataTemp[indexData].status = status;
        }
        this.setState({
          data: dataTemp,
        });
        resHandleChange(status);
      }
      resHandleChange(row.status);
    } catch (error) {
      resHandleChange(row.status);
      logger({ "error:": error });
    }
  };

  onFinish = async (values) => {
    const { filterData, filterCount } = filterDataObj(values);
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData,
        filterCount,
      },
      () => {
        this.fetchTrack();
        this.handleFilter();
      }
    );
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  onReset = () => {
    this.setState(
      {
        isLoading: true,
        data: [],
        totalSize: 0,
        page: 1,
        filterData: {},
        filterCount: 0,
      },
      () => {
        this.formRef.current.resetFields();
        this.fetchTrack();
      }
    );
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  render() {
    const {
      showCollectionModal,
      trackData,
      openFilter,
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      filterCount,
      showArtistListData,
      showCollectionListData,
      showCollectionListModal,
      currentTrackName,
      showArtistListModal,
      audioAddEditModal,
      editData,
      excelData,
      excelHeaders,
    } = this.state;

    const { artists, t } = this.props;
    const noDataMessage = textMessages.noAudiofound;

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("audio.title")}`}
          description={`${t("audio.title")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent">
          <div className="container-fluid">
            <div className="page-title-row page-title-btn d-flex">
              <div className="page-title-row__left">
                <BreadCrumb
                  bredcrumbs={[
                    {
                      name: t("dashboard.title"),
                      path: "/",
                    },
                    {
                      name: t("audio.title"),
                    },
                  ]}
                />
                <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                  {t("audio.title")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div
                  className={`filterForm ${
                    openFilter ? "filterForm--open" : ""
                  }`}
                >
                  <div className="filterForm__head">
                    <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleFilter();
                      }}
                      id="filterClose"
                    >
                      <i className="icon-cross"></i>
                    </a>
                  </div>
                  <div className="filterForm__body">
                    <Form
                      name="audioFilter"
                      className="form-inline"
                      ref={this.formRef}
                      onFinish={this.onFinish}
                      onFinishFailed={this.onFinishFailed}
                      initialValues={{
                        artistId: "",
                        status: "",
                        audioState: "",
                      }}
                    >
                      <div className="form-group">
                        <label>{t("track.name")} </label>
                        <Form.Item name="title">
                          <Input
                            className="form-control"
                            placeholder={t("track.name")}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("album.name")} </label>
                        <Form.Item name="albumName">
                          <Input
                            className="form-control"
                            placeholder={t("album.name")}
                          />
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("artist.name")} </label>
                        <Form.Item name="artistId">
                          <Select
                            showSearch
                            className="form-control"
                            placeholder={t("artist.name")}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            <Select.Option value="">
                              {t("artist.all")}
                            </Select.Option>
                            {artists.map((data, index) => {
                              return (
                                <Select.Option
                                  key={data.id}
                                  className="text-capitalize"
                                  value={data.id}
                                  disabled={data.status === "inactive"}
                                >
                                  {data.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>

                      <div className="form-group">
                        <label>{t("common.status")} </label>
                        <Form.Item name="status">
                          <Select
                            className="form-control"
                            placeholder={t("common.status")}
                          >
                            <Select.Option value="">
                              {t("common.allStatus")}
                            </Select.Option>
                            <Select.Option value="active">
                              {t("common.active")}
                            </Select.Option>
                            <Select.Option value="inactive">
                              {t("common.inactive")}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("audio.audioSource")} </label>
                        <Form.Item name="audioSource">
                          <Select
                            className="form-control"
                            placeholder={t("audio.audioSource")}
                          >
                            <Select.Option value="">
                              {t("common.allStatus")}
                            </Select.Option>
                            <Select.Option value="fytness">
                              {t("audio.audioSourceFytness")}
                            </Select.Option>
                            <Select.Option value="custom">
                              {t("audio.audioSourceCustom")}
                            </Select.Option>
                            <Select.Option value="other">
                              {t("audio.audioSourceOther")}
                            </Select.Option>
                            <Select.Option value="songtradr">
                              {t("audio.audioSourceSongtradr")}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="form-group">
                        <label>{t("audio.usedAt")}</label>
                        <Form.Item name="createdAt">
                          <DatePicker.RangePicker
                            placeholder={[
                              t("common.startDate"),
                              t("common.endDate"),
                            ]}
                            format={dateFormatDMY}
                          />
                        </Form.Item>
                      </div>
                      <div className="btn_clumn d-flex justify-content-between">
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            className="btn btn-primary ripple-effect"
                          >
                            {t("common.search")}
                          </Button>
                        </Form.Item>
                        <Form.Item>
                          <Button
                            htmlType="button"
                            onClick={this.onReset}
                            className="btn btn-outline-dark ripple-effect"
                          >
                            {t("common.reset")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="btnBox d-block d-flex w-100">
                  <CSVLink
                    className="btn btn-sm btn-primary ripple-effect text-uppercase"
                    data={excelData}
                    headers={excelHeaders}
                    filename={"audio-track-report.csv"}
                  >
                    Download
                  </CSVLink>

                  {checkUserPermission(this.props.userData, "hashtag") ? (
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.audioAddModel();
                      }}
                      className="btn btn-sm btn-primary ripple-effect text-uppercase"
                    >
                      {" "}
                      {t("audio.add")}
                    </a>
                  ) : (
                    ""
                  )}
                  <Badge count={filterCount}>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.handleFilter();
                      }}
                      id="filter"
                      className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-3"
                    >
                      <i className="icon-filter-line"></i>
                    </a>
                  </Badge>
                </div>
              </div>
            </div>
            <RemoteDataTable
              data={data}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              onTableChange={this.handleTableChange}
              isFilter={false}
              columns={columns}
              defaultSorted={defaultSorted}
              loading={isLoading}
              noDataMessage={noDataMessage}
            />
          </div>
        </main>

        <ArtistList
          data={showArtistListData}
          title={currentTrackName}
          show={showArtistListModal}
          onHide={() => this.artistListModalHide()}
        />

        <CollectionList
          data={showCollectionListData}
          title={currentTrackName}
          show={showCollectionListModal}
          onHide={() => this.collectionListModal()}
        />

        <CollectionManagement
          show={showCollectionModal}
          data={trackData}
          onSubmitSuccess={this.fetchTrack}
          onHide={() => this.manageAudioCollections()}
        />
        <TrackAddEdit
          show={audioAddEditModal}
          data={editData}
          onSubmitSuccess={this.fetchTrack}
          onHide={() => this.audioAddModel()}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    artists: state.common.artists,
    userData: state.auth.userData || "",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addArtistsRedux: (res) => dispatch(addArtistsAction(res)),
    addAlbumsRedux: (res) => dispatch(addAlbumsAction(res)),
    addCollectionRedux: (res) => dispatch(addCollectionAction(res)),
  };
};

ManageAudio.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
  artists: PropTypes.array.isRequired,
  addArtistsRedux: PropTypes.func.isRequired,
  addCollectionRedux: PropTypes.func.isRequired,
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ManageAudio)
);
