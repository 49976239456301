import React from "react";
import { Bar } from "react-chartjs-2";

export default function BarChart(props) {
  let month = [];
  if (props.graphType === "user") {
    month = ["Total Users"];
  } else {
    month = ["Audio", "Video"];
  }

  let driver = [];
  if (props.graphType === "user") {
    driver = [props.graphUserData];
  } else {
    driver = [props.graphAudioData, props.graphVideoData];
  }

  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        left: 10,
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
  };

  const dataSet = {
    labels: month,
    datasets: [
      {
        label: props.graphType === "user" ? "Users" : "Audio/Video",
        data: driver,
        backgroundColor: props.color,
        borderColor: "rgb(64, 153, 255)",
        borderWidth: 1,
        maxBarThickness: 30,
      },
    ],
  };
  return (
    <div>
      <Bar data={dataSet} options={options} />
    </div>
  );
}
