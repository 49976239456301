import React from "react";
import { useTranslation } from "react-i18next";

import { Form, Button, InputNumber } from "antd";
import PropTypes from "prop-types";

import validation, { inputParser } from "../../utilities/validation";
import { LoadingSpinner } from "../common";

export default function SettingsForm({
  isSpin,
  onFinish,
  onFinishFailed,
  submitButtonText,
  initialValues,
  formRef,
  onReset,
  data,
}) {
  const { t } = useTranslation();
  return (
    <Form
      name="settings"
      ref={formRef}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
    >
      <div className="row">
        {data.map((d) => {
          return (
            <div className="col-md-4 col-sm-6" key={`${d.key}`}>
              <div className="form-group">
                <label>{d.name}</label>
                <Form.Item name={d.id} rules={validation.setting.settings}>
                  <InputNumber
                    className="form-control"
                    placeholder={d.name}
                    parser={inputParser.number}
                  />
                </Form.Item>
              </div>
            </div>
          );
        })}
      </div>
      <div className="btn-row text-center">
        <Form.Item>
          <Button
            disabled={isSpin}
            htmlType="submit"
            className="btn btn-primary width-120 ripple-effect text-uppercase mr-3"
          >
            {isSpin ? <LoadingSpinner /> : submitButtonText}
          </Button>
          <Button
            htmlType="button"
            onClick={onReset}
            className="btn btn-outline-dark width-120 ripple-effect text-uppercase"
          >
            {t("common.reset")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

SettingsForm.propTypes = {
  isSpin: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  formRef: PropTypes.any.isRequired,
  onReset: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};
