import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { selectIsLoggedIn } from "../../redux/auth/authSlice";
import AdminHeader from "../adminHeader";

export default function Header() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  return (
    <header className="c-header">
      <nav className="navbar" id="navbar">
        <div className="container-fluid">
          <Link className="navbar-brand navbar__brand" to="/">
            <img src="/images/logo.svg" className="img-fluid" alt="logo" />
          </Link>
          {isLoggedIn && <AdminHeader />}
        </div>
      </nav>
    </header>
  );
}
