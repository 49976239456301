import React from "react";
import { Link } from "react-router-dom";

import { Form, Input, Button } from "antd";
import PropTypes from "prop-types";

import validation from "../../utilities/validation";
import { LoadingSpinner } from "../common";

export default function LoginForm({
  isSpin,
  onFinish,
  onFinishFailed,
  submitButtonText,
}) {
  return (
    <Form name="login" onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <div className="form-group">
        <label>Email</label>
        <Form.Item name="email" rules={validation.login.email}>
          <Input className="form-control" placeholder="Email" />
        </Form.Item>
      </div>
      <div className="form-group">
        <label>Password</label>
        <Form.Item name="password" rules={validation.login.password}>
          <Input.Password className="form-control" placeholder="Password" />
        </Form.Item>
      </div>
      <div className="form-group  auth-link text-right">
        <Link to="/forgot-password" className="theme-color">
          Forgot Password?
        </Link>
      </div>
      <div className="form-group text-center mb-0">
        <Form.Item>
          <Button
            disabled={isSpin}
            type="primary"
            htmlType="submit"
            className="btn btn-primary ripple-effect w-100"
          >
            {isSpin ? <LoadingSpinner /> : submitButtonText}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

LoginForm.propTypes = {
  isSpin: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
};
