import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import { ImageComponent, GlobalLoader } from "../common";

class ReportedByList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userData: [],
      isLoading: true,
      hasMoreToLoad: true,
      userDataTotal: 0,
      page: 1,
      sizePerPage: 10,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.show !== this.props.show) {
      if (this.props.show) {
        this.fetchUserList(this.props.id, this.props.type);
      } else {
        this.resetState();
      }
    }
  }

  componentWillUnmount() {
    this.resetState();
  }

  resetState = () => {
    this.setState({
      userData: [],
      isLoading: true,
      userDataTotal: 0,
      page: 1,
      sizePerPage: 10,
    });
  };

  fetchUserList = async (id, type) => {
    const { page, sizePerPage } = this.state;
    try {
      let payload = {
        queryParams: {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
        },
      };

      if (type === "feed") {
        payload = {
          ...payload,
          ...ApiEndPoints.getUsersReportedFeed(id),
        };
      } else if (type === "user") {
        payload = {
          ...payload,
          ...ApiEndPoints.getUsersReportedUser(id),
        };
      }

      const res = await APIrequest(payload);

      this.setState((prevState) => {
        const newUserData = [...prevState.userData, ...res.data.rows];
        return {
          userData: newUserData,
          isLoading: false,
          userDataTotal: res.data.total,
          hasMoreToLoad: res.data.total !== newUserData.length,
          page,
          sizePerPage,
        };
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  detectScroll = (e) => {
    if (
      this.state.hasMoreToLoad &&
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    ) {
      this.setState(
        (prevState) => {
          return {
            isLoading: true,
            page: prevState.page + 1,
          };
        },
        () => {
          this.fetchUserList(this.props.id, this.props.type);
        }
      );
    }
  };
  render() {
    const { userData, isLoading } = this.state;
    const { show, onHide, t } = this.props;

    if (!show) {
      return <></>;
    }

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        id="reportedlistsModal"
        className="artistModal modal-dialog-scrollable"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2>{t("reported.list")}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="comment-list p-0"
          onScroll={(e) => this.detectScroll(e)}
        >
          <div className="common-table">
            <table className="table">
              <thead>
                <tr>
                  <th className="w_70">{t("reportedReasons.sNo")}</th>
                  <th>{t("reportedReasons.name")}</th>
                  <th>{t("reportedReasons.userName")}</th>
                </tr>
              </thead>
              <tbody className="reportedlists mCustomScrollbar">
                {userData.map((data, index) => {
                  return (
                    <tr
                      key={`${data.id}_${data.User.id}`}
                      className="list-inline-items"
                    >
                      <td>{index + 1}</td>
                      <td>{data.reason}</td>
                      <td>
                        <div className="media align-items-center">
                          <div className="media__left">
                            <ImageComponent
                              src={data.User.profilePictureThumbUrl}
                              imgprops={{
                                className: "img-fluid",
                              }}
                            />
                          </div>
                          <div className="media__body">
                            <h5 className="mt-0 text-capitalize">
                              {data.User.firstName} {data.User.lastName}
                            </h5>
                            <p className="text-capitalize">
                              {`@${data.User.username}`}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isLoading && <GlobalLoader />}
        </Modal.Body>
      </Modal>
    );
  }
}

ReportedByList.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ReportedByList);
