import React from "react";

import { GlobalLoader } from "../common";

export default function LoadingView() {
  return (
    <main className="mainContent userDetail">
      <div className="container-fluid">
        <div className="detailPage">
          <GlobalLoader />
        </div>
      </div>
    </main>
  );
}
