import React, { PureComponent } from "react";

import { Alert } from "antd";

import ForgotPasswordForm from "../../components/forms/forgotPassword";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import { tagClassToggle } from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

export default class ForgotPassword extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSpin: false,
      isAlert: false,
      errorMsg: "",
    };
  }

  componentDidMount() {
    tagClassToggle("body", "auth");
  }

  componentWillUnmount() {
    tagClassToggle("body", "auth");
  }

  onFinish = async (values) => {
    try {
      const payload = {
        ...ApiEndPoints.accountForgotPassword,
        bodyData: {
          email: values.email,
        },
      };
      this.setState({ isSpin: true, isAlert: false });
      const res = await APIrequest(payload);
      modalNotification({
        type: "success",
        message: "Sent",
        description: res.message || "Link successfully sent to your mail !",
      });
      this.loadingTime();
    } catch (error) {
      this.setState({ isAlert: true, isSpin: false, errorMsg: error.message });
      logger({ "error:": error });
    }
  };

  loadingTime = () => {
    setInterval(() => {
      this.setState({ isSpin: false });
    }, 1000);
  };

  onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  render() {
    const { isSpin, isAlert, errorMsg } = this.state;
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | Forgot Password`}
          description={`Forgot Password page of ${config.NAME_TITLE}`}
        />
        <main className="mainContent pl-0 pr-0 pb-0">
          <section className="auth__section d-flex align-items-center justify-content-center">
            <div className="container">
              <div className="auth-area mx-auto ">
                <div className="auth-area__head text-center">
                  <h1 className="font-bold">Forgot Password?</h1>
                </div>
                <div className="auth-area__form shadow">
                  {isAlert && (
                    <Alert
                      message={errorMsg || textMessages.enterValidEmail}
                      className="mb-4"
                      type="error"
                    />
                  )}
                  <ForgotPasswordForm
                    isSpin={isSpin}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    submitButtonText={"SEND ME A LINK"}
                  />
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}
