import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";

import { Form, Button, Badge, Select } from "antd";

import BreadCrumb from "../../components/breadCrumb";
import RemoteDataTable from "../../components/dataTable";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  dateFormatter,
  filterDataObj,
  nameWithImageFormatter,
  statusFormatter,
} from "../../utilities/common";
import { DEFAULT_PAGINATION } from "../../utilities/constants";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import ModalViewContent from "./modalViewContent";
import { useManageCommunityPosts } from "./useManageCommunityPosts";

const ManageCommunityPosts = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalPageSize, setTotalPageSize] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [queryParams, setQueryParams] = useState(DEFAULT_PAGINATION);
  const [openModalContent, setOpenModalContent] = useState(false);
  const [viewContent, setViewContent] = useState({
    postType: "",
    content: "",
    thumb: "",
  });

  const history = useHistory();
  const formRef = useRef();

  useEffect(() => {
    if (!id) {
      return;
    }
    setIsLoading(true);
    const { filterData, ...restParams } = queryParams;

    const payload = {
      ...ApiEndPoints.getPostByGroupId(id),
      queryParams: {
        ...restParams,
        ...filterData,
      },
    };

    APIrequest(payload)
      .then((res) => {
        if (res) {
          setData(res.data.rows);
          setTotalPageSize(res.data.rows.length > 0 ? res.data.total : 0);
        }
      })
      .catch((error) => {
        console.log({ error });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id, queryParams]);

  const updateDataChangeStatus = (status, id) => {
    setData((prevState) =>
      prevState.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status: status,
          };
        }
        return item;
      })
    );
  };

  const { onChangeStatus } = useManageCommunityPosts({
    callbackOnChangeStatus: updateDataChangeStatus,
  });

  const noDataMessage = useMemo(() => {
    return filterCount > 0
      ? textMessages.noDataFound
      : textMessages.noDataCommunityList;
  }, [filterCount]);

  const handleFilter = useCallback(() => {
    document.body.classList.toggle("overflow-hidden", !openFilter);
    setOpenFilter(!openFilter);
  }, [openFilter]);

  const actionFormatter = useCallback(
    (_cell, row) => {
      return (
        <div className="dropdown">
          <a
            href="/"
            onClick={(e) => e.preventDefault()}
            className="dropdown-toggle"
            id={`dropdownMenuButton_${row.id}`}
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="ni ni-more-h"></span>
          </a>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              className="dropdown-item"
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setViewContent({
                  postType: row.postType,
                  content:
                    row.postType !== "text"
                      ? row.groupPostMedia[0].mediaFileUrl
                      : row.text,
                  thumb: row.groupPostMedia?.length
                    ? row.groupPostMedia[0]?.mediaFileThumbUrl
                    : "",
                });
                setOpenModalContent(true);
              }}
            >
              {t("common.view")}
            </a>
          </div>
        </div>
      );
    },
    [t]
  );

  const columns = useMemo(() => {
    const localColumns = [
      {
        dataField: "id",
        text: t("common.sNo"),
        sort: true,
      },
      {
        dataField: "text",
        text: t("community-posts.communityName"),
        headerAlign: "left",
        align: "left",
        sort: true,
      },
      {
        dataField: "postType",
        text: t("community-posts.postType"),
        headerAlign: "left",
        align: "left",
        style: {
          textTransform: "capitalize",
        },
      },
      {
        dataField: "user.username",
        text: t("community-posts.owner"),
        headerAlign: "left",
        align: "left",
        formatter: (cell, row) => nameWithImageFormatter(cell, row.user),
      },
      {
        dataField: "date",
        text: t("common.createdDate"),
        headerAlign: "left",
        align: "left",
        sort: true,
        formatter: dateFormatter,
      },
      {
        dataField: "type",
        text: t("common.privacy"),
        headerAlign: "left",
        align: "left",
        sort: true,
        style: {
          textTransform: "capitalize",
        },
      },
      {
        dataField: "status",
        text: t("common.status"),
        headerAlign: "left",
        align: "left",
        formatter: (cell, row) =>
          statusFormatter(cell, row, onChangeStatus, ""),
      },
      {
        dataField: "isDummyField",
        text: t("common.action"),
        headerAlign: "left",
        align: "left",
        formatter: actionFormatter,
      },
    ];

    return localColumns;
  }, [actionFormatter, onChangeStatus, t]);

  const onFinish = (values) => {
    const { filterData, filterCount } = filterDataObj(values);
    setFilterCount(filterCount);
    setQueryParams((prevState) => ({
      ...prevState,
      offset: 0,
      limit: 10,
      filterData,
    }));
    handleFilter();
  };

  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  const onReset = useCallback(() => {
    setFilterCount(0);
    setQueryParams((prevState) => ({
      ...prevState,
      filterData: undefined,
    }));
    setTimeout(() => {
      formRef.current.resetFields();
    }, [1000]);
  }, [formRef]);

  const handleTableChange = (
    _type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    setQueryParams((prevState) => ({
      ...prevState,
      offset: (page - 1) * sizePerPage,
      limit: sizePerPage,
      sortBy: sortField,
      sortType: sortOrder,
    }));
  };

  return (
    <>
      <MetaTags
        title={`${config.NAME_TITLE} | ${t("community-posts.title")}`}
        description={`${t("community-posts.title")} of ${config.NAME_TITLE}`}
      />

      <main className="mainContent">
        <div className="container-fluid">
          <div className="page-title-row page-title-btn d-flex">
            <div className="page-title-row__left">
              <BreadCrumb
                bredcrumbs={[
                  {
                    name: t("dashboard.title"),
                    path: "/",
                  },
                  {
                    name: t("community-posts.title"),
                  },
                ]}
              />
              <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                {t("community-posts.communityList")}
              </h1>
            </div>
            <div className="page-title-row__right">
              <div className="btnBox d-block d-sm-flex w-100 mr-3">
                <button
                  className="btn btn-sm btn-outline-secondary mobile-btn back-btn ripple-effect text-uppercase"
                  onClick={() => history.goBack()}
                >
                  <i className="icon-back-arrow"></i> {t("common.back")}
                </button>
              </div>
              <div
                className={`filterForm ${openFilter ? "filterForm--open" : ""}`}
              >
                <div className="filterForm__head">
                  <h3 className="h-24 font-semi">{t("common.filter")}</h3>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFilter();
                    }}
                    id="filterClose"
                  >
                    <i className="icon-cross"></i>
                  </a>
                </div>
                <div className="filterForm__body">
                  <Form
                    name="communityFilter"
                    className="form-inline"
                    ref={formRef}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={queryParams.filterData}
                  >
                    <div className="form-group">
                      <label>{t("community-posts.postType")} </label>
                      <Form.Item name="postType">
                        <Select
                          className="form-control"
                          placeholder={t("community-posts.postType")}
                        >
                          <Select.Option value="">
                            {t("common.allStatus")}
                          </Select.Option>
                          <Select.Option value="text">
                            {t("common.text")}
                          </Select.Option>
                          <Select.Option value="image">
                            {t("common.image")}
                          </Select.Option>
                          <Select.Option value="video">
                            {t("common.video")}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="form-group">
                      <label>{t("common.status")} </label>
                      <Form.Item name="status">
                        <Select
                          className="form-control"
                          placeholder={t("common.status")}
                        >
                          <Select.Option value="">
                            {t("common.allStatus")}
                          </Select.Option>
                          <Select.Option value="active">
                            {t("common.active")}
                          </Select.Option>
                          <Select.Option value="inactive">
                            {t("common.inactive")}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="btn_clumn d-flex justify-content-between">
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          className="btn btn-primary ripple-effect"
                        >
                          {t("common.search")}
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          htmlType="button"
                          onClick={onReset}
                          className="btn btn-outline-dark ripple-effect"
                        >
                          {t("common.reset")}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="btnBox">
                <Badge count={filterCount}>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFilter();
                    }}
                    id="filter"
                    className="btn mobile-btn btn-sm btn-outline-secondary ml-2 ml-lg-0"
                  >
                    <i className="icon-filter-line"></i>
                  </a>
                </Badge>
              </div>
            </div>
          </div>
          <RemoteDataTable
            data={data}
            page={queryParams.page}
            sizePerPage={queryParams.limit}
            totalSize={totalPageSize}
            onTableChange={handleTableChange}
            isFilter={false}
            columns={columns}
            resetSelected={false}
            defaultSorted={[
              {
                dataField: "id",
                order: "desc",
              },
            ]}
            loading={isLoading}
            noDataMessage={noDataMessage}
          />
        </div>
      </main>

      <ModalViewContent
        setOpen={() => setOpenModalContent(false)}
        isOpen={openModalContent}
        postType={viewContent.postType}
        content={viewContent.content}
        thumb={viewContent.thumb}
      />
    </>
  );
};

export default ManageCommunityPosts;
