import { createSlice } from "@reduxjs/toolkit";

export const manageUserSlice = createSlice({
  name: "manageUser",
  initialState: {
    filters: undefined,
  },
  reducers: {
    updateFiltersUsers: (state, action) => {
      return {
        ...state,
        filters: action.payload,
      };
    },
  },
});

export const { updateFiltersUsers } = manageUserSlice.actions;

export default manageUserSlice.reducer;
