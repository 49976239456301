import React, { Component } from "react";
import { confirm } from "react-confirm-box";
import { withTranslation } from "react-i18next";

import { Alert } from "antd";
import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import {
  ImageComponent,
  StatusBadgeComponent,
  EmailComponent,
  UsernameComponent,
} from "../../components/common";
import LoadingView from "../../components/loadingView";
import NotFoundView from "../../components/notFoundView";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import { statusFormatter } from "../../utilities/common";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import MetaTags from "../../utilities/metaTags";
import modalNotification from "../../utilities/notifications";

const options = {
  render: (message, onConfirm, onCancel) => {
    return (
      <div className="confirm-box__content">
        <span> {message} </span>
        <div className="confirm-box__actions">
          <button
            className="ant-btn btn btn-primary ripple-effect"
            onClick={onConfirm}
          >
            {" "}
            Yes{" "}
          </button>
          <button
            className="ant-btn btn btn-outline-dark ripple-effect"
            onClick={onCancel}
          >
            {" "}
            No{" "}
          </button>
        </div>
      </div>
    );
  },
};

class UserDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      isLoading: true,
      error: false,
      bredcrumbs: [],
    };
  }

  componentDidMount() {
    this.loadUserData(this.props.match.params.userid);
    this.updateBredcrums();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.userid !== this.props.match.params.userid) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.loadUserData(this.props.match.params.userid);
          this.updateBredcrums();
        }
      );
    }
  }

  updateBredcrums = () => {
    const { t } = this.props;

    let bredcrumbs = [
      {
        path: "/",
        name: t("dashboard.title"),
      },
      {
        path: "/manage-user",
        name: t("user.title"),
      },
      {
        path: `/user-detail/${this.state.data.id}`,
        name: t("user.detail"),
      },
    ];

    if (
      this.props.history.location.state &&
      Object.keys(this.props.history.location.state).length > 0 &&
      "bredcrumbs" in this.props.history.location.state
    ) {
      bredcrumbs = this.props.history.location.state.bredcrumbs;
      bredcrumbs.push({
        path: `/user-detail/${this.state.data.id}`,
        name: t("user.detail"),
      });
    }
    this.setState({
      bredcrumbs,
    });
  };

  loadUserData = async (id) => {
    try {
      const payload = {
        ...ApiEndPoints.getSpecificUser(id),
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        error: false,
        data: res.data,
      });
    } catch (error) {
      logger({ "error:": error });
      this.setState({
        isLoading: false,
        error: true,
      });
    }
  };

  onchangeUserTag = async (val, row, resHandleChange, columnName) => {
    try {
      let value = "";
      let userStatus = "";
      if (val) {
        value = "true";
        userStatus = "verify";
      } else {
        value = "false";
        userStatus = "unverify";
      }
      const result = await confirm(
        `Are you sure want to ${userStatus} this user?`,
        options
      );
      if (result) {
        const payload = {
          ...ApiEndPoints.updateUserTag,
          bodyData: {
            userId: row.id,
            columnName,
            value,
          },
        };
        const res = await APIrequest(payload);
        modalNotification({
          type: "success",
          message: "Success",
          description: res.message || textMessages.statusUpdate,
        });
        const dataTemp = this.state.data;
        dataTemp[columnName] = value;
        this.setState({
          data: dataTemp,
        });
        resHandleChange(value);
      }
      resHandleChange(row[columnName]);
    } catch (error) {
      resHandleChange(row[columnName]);
      logger({ "error:": error });
    }
  };

  onchangeStatus = async (val, row, resHandleChange) => {
    try {
      let status = "";
      if (val) {
        status = "active";
      } else {
        status = "inactive";
      }
      const result = await confirm(
        `Are you sure want to ${status} this video?`,
        options
      );
      if (result) {
        const payload = {
          ...ApiEndPoints.updateStatusUser(row.id),
          bodyData: {
            status,
          },
        };
        const res = await APIrequest(payload);
        modalNotification({
          type: "success",
          message: "Success",
          description: res.message || textMessages.statusUpdate,
        });
        const dataTemp = this.state.data;
        dataTemp.status = status;
        this.setState({
          data: dataTemp,
        });
        resHandleChange(status);
      }
      resHandleChange(row.status);
    } catch (error) {
      resHandleChange(row.status);
      logger({ "error:": error });
    }
  };

  render() {
    const { isLoading, error, data, bredcrumbs } = this.state;
    const { t } = this.props;

    if (isLoading) {
      return <LoadingView />;
    }

    if (error) {
      return <NotFoundView />;
    }
    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("user.detail")}`}
          description={`${t("user.detail")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent userDetail">
          <div className="container-fluid">
            <div className="page-title-row page-title-btn d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title mobile-margin text-capitalize mb-lg-0">
                  {t("user.detail")}
                </h1>
              </div>
              <div className="page-title-row__right">
                <div className="btnBox d-block d-sm-flex w-100">
                  <button
                    className="btn btn-sm btn-outline-secondary mobile-btn back-btn ripple-effect text-uppercase"
                    onClick={() => this.props.history.goBack()}
                  >
                    <i className="icon-back-arrow"></i> {t("common.back")}
                  </button>
                </div>
              </div>
            </div>
            <div className="view-detail mb-3">
              <div className="view-detail-header">
                <div className="d-block d-sm-flex align-items-start">
                  <div className="customer-img">
                    <ImageComponent src={data.profilePictureThumbUrl} />
                  </div>
                  <div className="view-detail-content d-block d-xl-flex align-items-start justify-content-between w-100">
                    <div className="view-detail-left w-100">
                      <div className="d-sm-flex justify-content-between">
                        <h4 className="view-detail-name">
                          {`${data.firstName} ${data.lastName}`}

                          <StatusBadgeComponent
                            status={data.status}
                            data={data}
                          />
                        </h4>
                        <div className="d-flex align-items-center mt-2 mt-sm-0">
                          <span className="d-block mr-2">
                            {t("common.status")}{" "}
                          </span>
                          {statusFormatter(
                            data.status,
                            data,
                            this.onchangeStatus,
                            "",
                            "zingler"
                          )}
                        </div>
                      </div>
                      <ul className="list-unstyled customer-info-list d_block mt-2 mb-0">
                        <li className="list-inline-item">
                          <UsernameComponent username={data.username} />
                        </li>

                        <li className="list-inline-item">
                          <img alt="mail-icon" src="/images/mail.svg" />{" "}
                          <span>
                            <EmailComponent emailId={data.email} />
                          </span>
                        </li>
                      </ul>
                      <ul className="list-unstyled d_block mb-0 info-list">
                        <li className="list-inline-item">
                          <span className="d-block mb-1">
                            {t("user.gender")}
                          </span>
                          {data.gender}
                        </li>
                        <li className="list-inline-item">
                          <span className="d-block mb-1">
                            {t("user.verified")}
                          </span>
                          {statusFormatter(
                            data.isVerified.toString(),
                            data,
                            this.onchangeUserTag,
                            "isVerified"
                          )}
                        </li>
                        <li className="list-inline-item">
                          <span className="d-block mb-1">
                            {t("user.videoCount")}
                          </span>
                          {data.videoCount}
                        </li>
                        <li className="list-inline-item">
                          <span className="d-block mb-1">
                            {t("user.eventCount")}
                          </span>
                          {data.eventCount}
                        </li>
                        <li className="list-inline-item">
                          <span className="d-block mb-1">
                            {t("user.address")}
                          </span>
                          {data.address}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-detail mb-3">
              <h5 className="font-semi h-20">{t("user.bio")}</h5>
              {data.bio ? (
                <p className="h-14 mb-0">{data.bio}</p>
              ) : (
                <Alert message={t("user.notFoundBio")} type="error" />
              )}
            </div>
            <div className="custom-detail mb-3">
              <h5 className="font-semi h-20">{t("user.sports")}</h5>
              <>
                {data.UserCategories && data.UserCategories.length > 0 ? (
                  <>
                    {data.UserCategories.map((d, i) => {
                      return (
                        <span
                          key={`${d.id}_video`}
                          className="badge badge-dim badge-gray"
                        >
                          {d.Category.name}
                        </span>
                      );
                    })}
                  </>
                ) : (
                  <strong className="theme-color">-</strong>
                )}
              </>
            </div>
          </div>
        </main>
      </>
    );
  }
}

UserDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UserDetail);
