import React from "react";
import { Switch, Router } from "react-router-dom";

import NetworkDetector from "../components/networkDetector";
import ScrollToTop from "../components/scrollToTop";
import AddFaq from "../container/cms/addFaq";
import { CMSComponent } from "../container/cms/cms";
import EditFaq from "../container/cms/editFaq";
import { FaqComponent } from "../container/cms/faqList";
import ContactSupport from "../container/contactSupport";
import PendingQueries from "../container/contactSupport/PendingQueries";
import RepliedQueries from "../container/contactSupport/RepliedQueries";
import Dashboard from "../container/dashboard";
import Feedback from "../container/feedback";
import ForgotPassword from "../container/forgotPassword";
import Home from "../container/home";
import ManageAudio from "../container/manageAudio";
import ManageCommunity from "../container/manageCommunity";
import CommuntyDetail from "../container/manageCommunity/communityDetail";
import ManageCommunityPosts from "../container/manageCommunityPosts";
import EventDetail from "../container/manageEvents/eventdetail";
import PastEvent from "../container/manageEvents/pastEvent";
import TodayEvent from "../container/manageEvents/todayEvent";
import UpcomingEvent from "../container/manageEvents/upcomingEvent";
import reportedUsers from "../container/manageReportedReasons/index";
import reportedVideos from "../container/manageReportedVideos";
import manageReportVideoReason from "../container/manageReportVideoReason";
import ManageUser from "../container/manageUser";
import UserDetail from "../container/manageUser/userDetail";
import ManageVideo from "../container/manageVideo";
import ManageVideoCollectionFeeds from "../container/manageVideoCollectionFeeds";
import NotFound from "../container/notFound";
import notifications from "../container/notifications/index";
import ReserveUsername from "../container/reserverUsername";
import ResetPassword from "../container/resetPassword";
import Settings from "../container/settings";
import VideoDetail from "../container/videoDetail";
import VideoUserList from "../container/videoUserList";
import LoginLayout from "../layouts/login";
import MainLayout from "../layouts/main";
import browserHistory from "../utilities/browserHistory";
import WithAuth from "../utilities/withAuth";
import RouteWithLayout from "./routeWithLayout";

export default class Routes extends React.PureComponent {
  componentDidMount() {
    // connectSocketOn(this.socketConnectionCheck)
  }

  componentWillUnmount() {
    // disconnectSocketEmit()
  }

  render() {
    return (
      <Router history={browserHistory}>
        <NetworkDetector />
        <ScrollToTop />
        <Switch>
          <RouteWithLayout
            component={Home}
            exact
            layout={WithAuth(LoginLayout)}
            path="/"
          />

          <RouteWithLayout
            component={ForgotPassword}
            exact
            layout={WithAuth(LoginLayout)}
            path="/forgot-password"
          />

          <RouteWithLayout
            component={ResetPassword}
            exact
            layout={WithAuth(LoginLayout)}
            path="/reset-password/:token"
          />

          <RouteWithLayout
            component={Dashboard}
            exact
            layout={WithAuth(MainLayout)}
            path="/dashboard"
          />

          <RouteWithLayout
            component={ManageUser}
            exact
            layout={WithAuth(MainLayout)}
            path="/manage-user"
          />

          <RouteWithLayout
            component={UserDetail}
            exact
            layout={WithAuth(MainLayout)}
            path="/user-detail/:userid"
          />

          <RouteWithLayout
            component={ManageVideo}
            exact
            layout={WithAuth(MainLayout)}
            path="/manage-video"
          />

          <RouteWithLayout
            component={ManageVideoCollectionFeeds}
            exact
            layout={WithAuth(MainLayout)}
            path="/view-collection-video/:collectionId"
          />

          <RouteWithLayout
            component={ManageAudio}
            exact
            layout={WithAuth(MainLayout)}
            path="/manage-audio"
          />

          <RouteWithLayout
            component={Feedback}
            exact
            layout={WithAuth(MainLayout)}
            path="/feedback"
          />

          <RouteWithLayout
            // component={ManageVideoFiles}
            component={VideoUserList}
            exact
            layout={WithAuth(MainLayout)}
            path="/manage-video"
          />

          <RouteWithLayout
            component={VideoDetail}
            exact
            layout={WithAuth(MainLayout)}
            path="/video-detail/:videoid"
          />

          <RouteWithLayout
            component={FaqComponent}
            exact
            layout={WithAuth(MainLayout)}
            path="/faq"
          />

          <RouteWithLayout
            component={AddFaq}
            exact
            layout={WithAuth(MainLayout)}
            path="/add-faq"
          />

          <RouteWithLayout
            component={EditFaq}
            exact
            layout={WithAuth(MainLayout)}
            path="/edit-faq/:faqId"
          />

          <RouteWithLayout
            component={CMSComponent}
            layout={WithAuth(MainLayout)}
            path="/cms/1"
          />

          <RouteWithLayout
            component={CMSComponent}
            layout={WithAuth(MainLayout)}
            path="/cms/2"
          />

          <RouteWithLayout
            component={CMSComponent}
            layout={WithAuth(MainLayout)}
            path="/cms/3"
          />

          <RouteWithLayout
            component={Settings}
            layout={WithAuth(MainLayout)}
            path="/settings"
          />

          <RouteWithLayout
            component={ContactSupport}
            layout={WithAuth(MainLayout)}
            path="/contact-support"
          />

          <RouteWithLayout
            component={reportedUsers}
            layout={WithAuth(MainLayout)}
            path="/reported-reasons"
          />
          <RouteWithLayout
            component={manageReportVideoReason}
            layout={WithAuth(MainLayout)}
            path="/report-video-reason"
          />

          <RouteWithLayout
            component={reportedVideos}
            exact
            layout={WithAuth(MainLayout)}
            path="/reported-video"
          />

          <RouteWithLayout
            component={PendingQueries}
            exact
            layout={WithAuth(MainLayout)}
            path="/support/pending-queries"
          />
          <RouteWithLayout
            component={RepliedQueries}
            exact
            layout={WithAuth(MainLayout)}
            path="/support/replied-queries"
          />
          <RouteWithLayout
            component={PastEvent}
            exact
            layout={WithAuth(MainLayout)}
            path="/event/past-event"
          />
          <RouteWithLayout
            component={UpcomingEvent}
            exact
            layout={WithAuth(MainLayout)}
            path="/event/upcoming-event"
          />
          <RouteWithLayout
            component={TodayEvent}
            exact
            layout={WithAuth(MainLayout)}
            path="/event/today-event"
          />
          <RouteWithLayout
            component={EventDetail}
            exact
            layout={WithAuth(MainLayout)}
            path="/event/today-event/event-detail/:id"
          />
          <RouteWithLayout
            component={notifications}
            layout={WithAuth(MainLayout)}
            path="/notifications"
          />
          <RouteWithLayout
            component={ReserveUsername}
            layout={WithAuth(MainLayout)}
            path="/reserve-username"
          />
          <RouteWithLayout
            component={ManageCommunity}
            layout={WithAuth(MainLayout)}
            path="/communities"
          />
          <RouteWithLayout
            component={CommuntyDetail}
            exact
            layout={WithAuth(MainLayout)}
            path="/community/:id"
          />
          <RouteWithLayout
            component={ManageCommunityPosts}
            exact
            layout={WithAuth(MainLayout)}
            path="/community-posts/:id"
          />

          <RouteWithLayout component={NotFound} layout={LoginLayout} path="*" />
        </Switch>
      </Router>
    );
  }
}
