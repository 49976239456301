import { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import { VideoComponent } from "../../components/common";
import Image from "../../components/image";

const ModalViewContent = ({ setOpen, isOpen, postType, content, thumb }) => {
  const { t } = useTranslation();

  const renderContent = useCallback(() => {
    if (postType === "video") {
      return (
        <div className="userDetail d-flex justify-content-center">
          <div className="list-unstyled mt-2 mb-0 aVlist w-50">
            <div className="list-inline-item w-100">
              <VideoComponent
                src={content}
                imgSrc={thumb}
                videoprops={{
                  className: "img-thumbnail",
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    if (postType === "image") {
      return (
        <div className="d-flex justify-content-center">
          <Image
            src={content}
            style={{ width: "300px", height: "300px", objectFit: "contain" }}
          />
        </div>
      );
    }

    return content;
  }, [postType, content, thumb]);

  return (
    <Modal
      show={isOpen}
      onHide={setOpen}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-dialog-scrollable"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{t("community-posts.showContent")}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderContent()}</Modal.Body>
    </Modal>
  );
};

export default ModalViewContent;

ModalViewContent.propTypes = {
  setOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  postType: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  thumb: PropTypes.string,
};
