import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import modalNotification from "../../utilities/notifications";
import ReplyMessageForm from "../forms/replyMessage";

export default function ReplyMessage(props) {
  const { t } = useTranslation();
  const [isSpin, setIsSpin] = useState(false);

  const onFinish = async (values) => {
    setIsSpin(true);
    try {
      const payload = {
        ...ApiEndPoints.updateRequest(props.data.id),
        bodyData: {
          response: values.response,
        },
      };
      const res = await APIrequest(payload);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || "You replied to message successful!",
      });
      setIsSpin(false);
      props.onHide();
      props.onSubmitSuccess();
    } catch (error) {
      setIsSpin(false);
      logger({ "error:": error });
    }
  };

  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-dialog-scrollable"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{t("common.reply")}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReplyMessageForm
          data={props.data}
          isSpin={isSpin}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          submitButtonText={t("common.send")}
        />
      </Modal.Body>
    </Modal>
  );
}

ReplyMessage.propTypes = {
  data: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};
