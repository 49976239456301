import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Button } from "antd";
import PropTypes from "prop-types";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";
import { LoadingSpinner } from "../common";

export default function DeleteConfirmation(props) {
  const { t } = useTranslation();
  const [isSpin, setIsSpin] = useState(false);

  const deleteConfirmation = async () => {
    setIsSpin(true);
    try {
      let payload = {};
      let message = "";
      if (props.type === "faq") {
        payload = {
          ...ApiEndPoints.deleteSpecificFaq(props.id),
        };
        message = textMessages.deleteFAQ;
      } else if (props.type === "interval") {
        payload = {
          ...ApiEndPoints.deleteSpecificInterval(props.id),
        };
        message = textMessages.deleteInterval;
      } else if (props.type === "role") {
        payload = {
          ...ApiEndPoints.deleteRole(props.id),
        };
      } else if (props.type === "notification") {
        payload = {
          ...ApiEndPoints.deleteNotification(props.id),
        };
      } else {
        return false;
      }
      const res = await APIrequest(payload);
      setIsSpin(false);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || message,
      });
      props.onSubmitSuccess();
      props.onHide();
    } catch (error) {
      setIsSpin(false);
      logger({ "error:": error });
    }
  };

  if (!props.show) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center text-center">
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="font-reg h-20 mb-0">
            {t("common.deleteConfirmation")}
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <div className="btn-row">
          <Button
            disabled={isSpin}
            className="btn btn-primary width-120 ripple-effect text-uppercase"
            onClick={deleteConfirmation}
          >
            {isSpin ? <LoadingSpinner /> : t("common.yes")}
          </Button>
          <Button
            className="btn btn-outline-dark width-120 ripple-effect text-uppercase "
            onClick={props.onHide}
          >
            {t("common.no")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

DeleteConfirmation.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
};
