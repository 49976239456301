import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Form, Select, Button, Alert } from "antd";
import PropTypes from "prop-types";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import { LoadingSpinner } from "../common";

export default function AssignUsername({
  onHide,
  isSpin,
  onFinish,
  onFinishFailed,
  initialValues = {},
  submitButtonText,
  errorMsg,
  loading,
}) {
  const { t } = useTranslation();
  const { Option } = Select;
  const [reasons, setReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");

  useEffect(() => {
    fetchReasons();
  }, [reasons.total, selectedReason, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  function onChange(value) {
    setSelectedReason(value);
  }

  async function fetchReasons() {
    try {
      let payload = {
        ...ApiEndPoints.getAllReserveUsername,
      };
      let res = await APIrequest(payload);
      if (res.success) {
        setReasons(res.data);
      }
    } catch (error) {
      logger(error);
    }
  }

  return (
    <>
      {errorMsg && <Alert message={errorMsg} className="mb-4" type="error" />}
      <Form
        name="assignUsername"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={initialValues}
      >
        <div className="form-group">
          <label>{t("username.name")}</label>
          <Form.Item name="name">
            <Select
              onChange={onChange}
              placeholder="Select Username"
              style={{ border: "1px solid #c2c2c2" }}
            >
              {reasons.total ? (
                reasons.rows.map((reason) => {
                  return (
                    <Option key={JSON.stringify(reason)} value={reason.name}>
                      {reason.name}
                    </Option>
                  );
                })
              ) : (
                <Option>{t("common.noReasons")}</Option>
              )}
            </Select>
          </Form.Item>
        </div>
        <div className="form-group btn-row text-center mb-0">
          <Form.Item>
            <Button
              disabled={isSpin}
              htmlType="submit"
              className="btn btn-primary width-120 ripple-effect text-uppercase"
            >
              {isSpin ? <LoadingSpinner /> : submitButtonText}
            </Button>
            <Button
              htmlType="button"
              onClick={onHide}
              className="btn btn-outline-dark width-120 ripple-effect text-uppercase"
            >
              {t("common.cancel")}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

AssignUsername.propTypes = {
  onHide: PropTypes.func.isRequired,
  isSpin: PropTypes.bool.isRequired,
  onFinish: PropTypes.func.isRequired,
  onFinishFailed: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  errorMsg: PropTypes.string.isRequired,
};
