import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

import PropTypes from "prop-types";

import RemoteDataTable from "../../components/dataTable";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import { getPageSizeFromURL, addPageSizeInURL } from "../../utilities/common";
import { getInitialState } from "../../utilities/defaultState";
import logger from "../../utilities/logger";
import { getSortField } from "../../utilities/table";
import ShowMoreText from "../modals/showMoreText";

class ClosedRequest extends PureComponent {
  constructor(props) {
    super(props);
    const dataFieldColumns = [
      "id",
      "isDummySno",
      "User.profilePictureThumbUrl",
      "User.username",
      "User.name",
      "message",
      "UserRequestResponse.response",
      "createdAt",
      "updatedAt",
    ];

    this.setState = getInitialState({ props, dataFieldColumns });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      if (this.props.location && !this.state.isLoading) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { page, sizePerPage },
        queryParams,
      } = res;
      this.setState(
        {
          page,
          sizePerPage,
          isLoading: true,
          data: [],
          totalSize: 0,
        },
        () => {
          this.fetchClosedRequest(queryParams);
        }
      );
    }
  };

  fetchClosedRequest = async (
    queryParams = {
      offset: (this.state.page - 1) * this.state.sizePerPage,
      limit: this.state.sizePerPage,
    }
  ) => {
    queryParams = {
      sortBy: this.state.defaultSorted[0].dataField,
      sortType: this.state.defaultSorted[0].order,
      ...queryParams,
      status: "closed",
    };
    try {
      const payload = {
        ...ApiEndPoints.getRequest,
        queryParams,
      };
      const res = await APIrequest(payload);
      this.setState({
        isLoading: false,
        isFirstTimeFetching: false,
        data: res.data.rows,
        totalSize: res.data.rows.length > 0 ? res.data.total : 0,
      });
    } catch (error) {
      logger({ "error:": error });
    }
  };

  handleToggle = (stateName) => {
    this.setState((state) => {
      return {
        [stateName]: !state[stateName],
      };
    });
  };

  /**
   * To handle the DataTable on change
   */
  handleTableChange = (
    type,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }
  ) => {
    this.setState(
      {
        page,
        sizePerPage,
        isLoading: true,
        data: [],
        totalSize: 0,
      },
      () => {
        sortField = getSortField(sortField);
        const queryParams = {
          offset: (page - 1) * sizePerPage,
          limit: sizePerPage,
          sortBy: sortField,
          sortType: sortOrder,
        };
        if (!this.state.isFirstTimeFetching) {
          addPageSizeInURL(page, sizePerPage, this.props.history, {
            tab: "closed",
          });
        }
        this.fetchOnHandleTableChange(queryParams);
      }
    );
  };

  fetchOnHandleTableChange = (queryParams) => {
    if (this.state.isFirstTimeFetching) {
      const { location } = this.props;

      if (location) {
        const query = location.search;
        const res = getPageSizeFromURL(query, location);
        if (res) {
          this.reFetchOnUrlBasis(query);
        } else {
          this.fetchClosedRequest(queryParams);
        }
      }
    } else {
      this.fetchClosedRequest(queryParams);
    }
  };

  showHideMoreText = (dataOfMessageReply = {}) => {
    this.setState(
      {
        dataOfMessageReply,
      },
      () => {
        this.handleToggle("openMessageReplyModal");
      }
    );
  };

  render() {
    const {
      data,
      totalSize,
      page,
      sizePerPage,
      defaultSorted,
      columns,
      isLoading,
      dataOfMessageReply,
      openMessageReplyModal,
    } = this.state;

    const { t } = this.props;

    return (
      <>
        <RemoteDataTable
          data={data}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          onTableChange={this.handleTableChange}
          isFilter={false}
          columns={columns}
          defaultSorted={defaultSorted}
          loading={isLoading}
        />
        <ShowMoreText
          title={t("common.message")}
          data={
            Object.keys(dataOfMessageReply).length
              ? dataOfMessageReply.data
              : ""
          }
          show={openMessageReplyModal}
          onHide={this.showHideMoreText}
        />
      </>
    );
  }
}

ClosedRequest.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(withRouter(ClosedRequest));
