import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import PropTypes from "prop-types";

import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";
import textMessages from "../../utilities/messages";
import modalNotification from "../../utilities/notifications";
import CollectionVideoOrderForm from "../forms/collectionVideoOrder";

export default function MakeVideoAsTop(props) {
  const { t } = useTranslation();
  const [isSpin, setIsSpin] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [listOrder, setListOrder] = useState(5);
  const [collectionFeedId, setCollectionFeedId] = useState(null);

  useEffect(() => {
    setErrorMsg("");
    setIsSpin(false);
    if (props.data.CollectionFeeds) {
      let coll = props.data.CollectionFeeds.filter(
        (d) => Number(d.collectionId) === Number(props.collectionId)
      );
      if (coll.length) {
        setListOrder(coll[0].listOrder);
        setCollectionFeedId(coll[0].id);
      }
    } else {
      setListOrder(5);
      setCollectionFeedId(null);
    }
  }, [props.show, props.data]) // eslint-disable-line

  const onFinish = async (values) => {
    setIsSpin(true);
    setErrorMsg("");
    try {
      let payload = {
        ...ApiEndPoints.addFeedOnTopCollection,
        bodyData: {
          collectionFeedId: collectionFeedId,
          listOrder: values.listOrder,
        },
      };

      const res = await APIrequest(payload);
      setIsSpin(false);
      modalNotification({
        type: "success",
        message: "Success",
        description: res.message || textMessages.updateOrderCollectionMessage,
      });
      props.onSubmitSuccess();
      props.onHide();
    } catch (error) {
      setIsSpin(false);
      setErrorMsg(error.message);
      logger({ "error:": error });
    }
  };

  const onFinishFailed = (errorInfo) => {
    logger({ "Failed:": errorInfo });
  };

  if (!props.show || ["", null].includes(collectionFeedId)) {
    return <></>;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>{t("collectionVideo.makeVideoAsTop")}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CollectionVideoOrderForm
          onHide={props.onHide}
          isSpin={isSpin}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            title: props.data.title,
            listOrder: 5,
            currentListOrder: listOrder,
          }}
          submitButtonText={t("common.update")}
          errorMsg={errorMsg}
          totalRecords={props.totalRecords}
        />
      </Modal.Body>
    </Modal>
  );
}

MakeVideoAsTop.propTypes = {
  for: PropTypes.string,
  data: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  totalRecords: PropTypes.any.isRequired,
  collectionId: PropTypes.any.isRequired,
};
