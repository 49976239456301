import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";

import BreadCrumb from "../../components/breadCrumb";
import { GlobalLoader, ImageComponent } from "../../components/common";
import NotFoundView from "../../components/notFoundView";
import config from "../../config";
import APIrequest from "../../services";
import ApiEndPoints from "../../utilities/apiEndPoints";
import {
  getPageSizeFromURL,
  updateQueryInURL,
  nameWithImageFormatter,
  OnlyDateFormatter,
} from "../../utilities/common";
import logger from "../../utilities/logger";
import MetaTags from "../../utilities/metaTags";
import ManageParticipantsListing from "./manageParticipantsListing";
class EventDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabName: "interested",
      error: false,
      eventData: {},
    };
  }

  componentDidMount() {
    if (this.props.location && this.props.location.search) {
      const query = this.props.location.search;
      this.reFetchOnUrlBasis(query);
    }
    this.checkCollectionExist(this.props.match.params.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.checkCollectionExist(this.props.match.params.id);
    }

    if (prevProps.location !== this.props.location) {
      if (this.props.location) {
        const query = this.props.location.search || prevProps.location.search;
        this.reFetchOnUrlBasis(query);
      }
    }
  }

  reFetchOnUrlBasis = (query) => {
    const { location } = this.props;
    const res = getPageSizeFromURL(query, location);
    if (res) {
      const {
        data: { tab },
      } = res;
      if (tab) {
        this.handleTabChange(tab);
      }
    }
  };

  handleTabChange = (value) => {
    this.setState({
      tabName: value,
    });
  };

  checkCollectionExist = async (id) => {
    try {
      const payload = {
        ...ApiEndPoints.getSpecificEvent(id),
      };
      const res = await APIrequest(payload);
      this.setState({
        error: false,
        eventData: res.data,
      });
    } catch (error) {
      this.setState({
        error: true,
      });
      logger({ "error:": error });
    }
  };

  render() {
    const { error, eventData, tabName } = this.state;
    const { t } = this.props;

    const bredcrumbs = [
      {
        name: t("dashboard.title"),
        path: "/",
      },
      {
        name: t("event.todayEvent"),
        path: "/event/today-event",
      },
      {
        name: t("event.eventDetail"),
      },
    ];

    if (error) {
      return <NotFoundView />;
    }

    return (
      <>
        <MetaTags
          title={`${config.NAME_TITLE} | ${t("event.eventDetail")}`}
          description={`${t("event.eventDetail")} of ${config.NAME_TITLE}`}
        />
        <main className="mainContent userDetail">
          <div className="container-fluid">
            {/* <!-- page title section start --> */}
            <div className="page-title-row d-flex">
              <div className="page-title-row__left">
                <BreadCrumb bredcrumbs={bredcrumbs} />
                <h1 className="page-title-row__left__title text-capitalize mb-lg-0">
                  {t("event.eventDetail")}
                </h1>
              </div>
            </div>
            <div className="main-viewdetail">
              <div className="view-detail">
                {Object.keys(eventData).length > 0 ? (
                  <div className="view-detail-header">
                    <div className="view-detail-content">
                      <div className="text-center view-profile ">
                        <ImageComponent
                          src={eventData.eventImageUrl}
                          imgprops={{
                            className: "img-thumbnail rounded-0 img-fluid",
                          }}
                        />
                      </div>
                      <ul className="list-unstyled view-list">
                        <li>
                          <span className="d-block">{t("event.creator")}</span>
                          <strong>
                            {nameWithImageFormatter(
                              eventData.User.username,
                              eventData.User
                            )}
                          </strong>
                        </li>
                        <li>
                          <span className="d-block">{t("event.event")}</span>
                          <strong>{eventData.title}</strong>
                        </li>
                        <li>
                          <span className="d-block">
                            {t("event.eventType")}
                          </span>
                          <strong>{eventData.eventType}</strong>
                        </li>
                        <li>
                          <span className="d-block">
                            {t("event.eventDate")}
                          </span>
                          <strong>
                            {OnlyDateFormatter(eventData.eventStartDate)}
                          </strong>
                        </li>
                        <li>
                          <span className="d-block">
                            {t("event.eventTime")}
                          </span>
                          <strong>{eventData.eventStartTime}</strong>
                        </li>
                        <li>
                          <span className="d-block">{t("event.location")}</span>
                          <strong>
                            {eventData.eventType === "online"
                              ? eventData.virtualLocation
                              : eventData.location}
                          </strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <GlobalLoader />
                )}
              </div>
              {/* <!-- page title section end --> */}
              <div className="custom-tabs">
                <ul
                  className="nav nav-tabs nav-justified border-top-0"
                  role="tablist"
                >
                  <li className="nav-item w-auto">
                    <a
                      className={`nav-link ${
                        tabName === "interested" ? "active" : ""
                      }`}
                      id="collectionRequests-tab"
                      data-toggle="tab"
                      href="#collectionRequests"
                      role="tab"
                      aria-controls="collectionRequestsTab"
                      aria-selected="false"
                      onClick={(e) => {
                        e.preventDefault();
                        updateQueryInURL(this.props.history, {
                          tab: "interested",
                        });
                        setTimeout(() => {
                          this.handleTabChange("interested");
                        }, 1000 / 2);
                      }}
                    >
                      {t("event.interested")}
                    </a>
                  </li>
                  <li className="nav-item w-auto">
                    <a
                      className={`nav-link ${
                        tabName === "going" ? "active" : ""
                      }`}
                      id="allRequest-tab"
                      data-toggle="tab"
                      href="#allRequest"
                      role="tab"
                      aria-controls="allRequestTab"
                      aria-selected="false"
                      onClick={(e) => {
                        e.preventDefault();
                        updateQueryInURL(this.props.history, { tab: "going" });
                        setTimeout(() => {
                          this.handleTabChange("going");
                        }, 1000 / 2);
                      }}
                    >
                      {t("event.going")}
                    </a>
                  </li>
                </ul>
                <div className="detail-wrap">
                  <div className="custom-tabs detail-ta bs">
                    <div className="tab-content mt-0">
                      <div className="tab-pane fade show active">
                        {tabName === "interested" && (
                          <ManageParticipantsListing
                            id={this.props.match.params.id}
                            tabName={tabName}
                          />
                        )}
                        {tabName === "going" && (
                          <ManageParticipantsListing
                            id={this.props.match.params.id}
                            tabName={tabName}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

EventDetail.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(EventDetail);
